import * as React from 'react';
import {
	hasEndpointService,
	WorkspaceConfiguration,
} from '@citrite/workspace-ui-platform';
import { useTheme } from '@emotion/react';
import { trackEvent } from 'analytics';
import moment from 'moment';
import {
	AdminNotificationClient,
	ANS_SERVICE_NAME,
	logAdminNotificationError,
	Type,
	useAdminNotificationClient,
} from 'AdminNotificationService';
import { BannerPosition } from 'AdminNotificationService/createAdminNotificationClient';
import { useFormFactor } from 'App/Header/useFormFactor';
import { DaasNotificationBanner } from 'Components/AppLayout/DaasNotificationBanner';
import { MastheadInformationalBanner } from 'Components/AppLayout/MastheadInformationalBanner';
import { useConfigurationContext } from 'Configuration/useConfigurationContext';
import { useCasTelemetryAdapter } from 'Workspace/CAS/useCasTelemetryAdapter';
import {
	EventType,
	TelemetryEvent,
	TelemetryEventPublisher,
} from 'Workspace/TelemetryEvents/TelemetryEventTypes';
import { MastheadOffset } from 'Workspace/ThemeProvider';
import { UserContext, useUserContext } from 'Workspace/UserContext';
import {
	StyledBannerText,
	StyledBannerTitle,
	StyledInlinedBanner,
} from './AdminNotificationBanner.styled';
import {
	createNotificationMessage,
	getBannerPositionFromFormFactor,
	shouldDisplayBanner,
} from './AdminNotificationBannerUtils';

const AdminNotificationBannerCode = 'AdminNotificationBannerCode';
const component = 'WSUI';

interface State {
	title: string;
	sanitizedMessage: string;
	bannerPosition: BannerPosition;
}

export interface AdminBannerProps {
	intendedPosition?: BannerPosition;
}

interface Props {
	adminNotificationClient: AdminNotificationClient;
	isLargeFormFactor: boolean;
	workspaceConfiguration: WorkspaceConfiguration;
	mastheadOffset: MastheadOffset;
	telemetryEventPublisher: TelemetryEventPublisher;
	intendedPosition?: BannerPosition;
	userContext: UserContext;
}

interface AdminNotificationBannerEvent extends TelemetryEvent {
	time: string;
	code: string;
	component: string;
	bannerPosition: BannerPosition;
}

export interface ContentProps {
	isExpanded?: boolean;
}

class _AdminNotificationBanner extends React.Component<Props, State> {
	public state: State = {
		title: null,
		sanitizedMessage: null,
		bannerPosition: BannerPosition.Top,
	};

	public async componentDidMount() {
		// With this we will be restricting the admin banner to be shown only when the user is logged in
		const hasUserLoggedIn = await this.props.userContext.hasLoggedIn();
		if (
			!this.props.adminNotificationClient ||
			!hasEndpointService(this.props.workspaceConfiguration, ANS_SERVICE_NAME) ||
			!hasUserLoggedIn
		) {
			return;
		}

		try {
			const bannerNotifications = await this.props.adminNotificationClient
				.fetchNotificationsCacheFirst()
				.then(notifications =>
					notifications.filter(
						notification => notification.type.toLowerCase() === Type.Banner
					)
				);
			const adminNotification = bannerNotifications && bannerNotifications[0];
			const showAdminBanner =
				adminNotification &&
				new Date(adminNotification.expireTime) > moment().toDate() &&
				new Date(adminNotification.startTime) < moment().toDate();

			if (!showAdminBanner) {
				return;
			}
			const bannerPosition = adminNotification?.position;
			trackEvent(`AdminNotificationBanner_${bannerPosition}`);
			sendAdminNotificationBannerEvent(
				this.props.telemetryEventPublisher,
				bannerPosition
			);
			const sanitizedNotificationMessage = await createNotificationMessage(
				adminNotification,
				this.props.userContext?.isDaasVisionEnabled
			);
			this.setState({
				title: adminNotification.name,
				sanitizedMessage: sanitizedNotificationMessage,
				bannerPosition,
			});
		} catch (e) {
			logAdminNotificationError(e);
		}
	}

	private getContent = (showSingleLine: boolean) => () =>
		(
			<StyledInlinedBanner showSingleLine={showSingleLine}>
				{showSingleLine ? <b>{this.state.title}:</b> : <b>{this.state.title}</b>}

				<StyledBannerText
					dangerouslySetInnerHTML={{
						__html: this.state.sanitizedMessage,
					}}
					padding={showSingleLine ? 3 : 0}
				/>
			</StyledInlinedBanner>
		);

	private getDaasBannerContent = () => (props: ContentProps) =>
		(
			<StyledInlinedBanner showSingleLine={false}>
				<StyledBannerTitle
					data-testid="daas-admin-banner-title"
					isExpanded={props.isExpanded}
				>
					<b>{this.state.title}</b>
				</StyledBannerTitle>

				{props.isExpanded && (
					<StyledBannerText
						dangerouslySetInnerHTML={{
							__html: this.state.sanitizedMessage,
						}}
						padding={0}
					/>
				)}
			</StyledInlinedBanner>
		);

	public render() {
		if (!(this.state.title && this.state.sanitizedMessage)) {
			return null;
		}
		if (this.props.userContext?.isDaasVisionEnabled) {
			if (
				!shouldDisplayBanner(
					this.props.intendedPosition,
					this.state.bannerPosition,
					this.props.isLargeFormFactor
				)
			) {
				return null;
			}
		}
		const position = getBannerPositionFromFormFactor(
			this.state.bannerPosition,
			this.props.isLargeFormFactor
		);
		const showSingleLine =
			this.props.isLargeFormFactor && this.state.sanitizedMessage.length <= 100;

		const textAlign = showSingleLine ? 'center' : 'left';

		return this.props.userContext?.isDaasVisionEnabled ? (
			<DaasNotificationBanner
				Content={this.getDaasBannerContent()}
				bannerPosition={position}
				mastheadOffset={this.props.mastheadOffset}
				isAdminBanner
			/>
		) : (
			<MastheadInformationalBanner
				Content={this.getContent(showSingleLine)}
				textAlign={textAlign}
				mastheadOffset={this.props.mastheadOffset}
				bannerPosition={position}
			/>
		);
	}
}

function sendAdminNotificationBannerEvent(
	telemetryEventPublisher: TelemetryEventPublisher,
	bannerPosition: BannerPosition
) {
	const adminNotificationBannerEvent: AdminNotificationBannerEvent = {
		time: new Date().toISOString(),
		type: EventType.AdminNotificationBanner,
		code: AdminNotificationBannerCode,
		component,
		bannerPosition,
	};
	telemetryEventPublisher.publishEvent(adminNotificationBannerEvent);
}

export function AdminNotificationBanner(props: AdminBannerProps) {
	const contexts: Props = {
		adminNotificationClient: useAdminNotificationClient(),
		isLargeFormFactor: useFormFactor().isLargeFormFactor,
		workspaceConfiguration: useConfigurationContext().workspaceConfiguration,
		mastheadOffset: useTheme().mastheadOffset,
		telemetryEventPublisher: useCasTelemetryAdapter(),
		intendedPosition: props.intendedPosition,
		userContext: useUserContext(),
	};
	return <_AdminNotificationBanner {...contexts} />;
}
