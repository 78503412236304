export interface TelemetryEvent {
	type:
		| EventType
		| AppsEventType
		| DesktopEventType
		| GeneralEventType
		| BrowserExtensionEventType
		| ShieldHealthCheckEventType
		| BrowserExtensionInstallPromptEventType
		| string;
}

export enum EventSite {
	Tile = 'Tile',
	Recent = 'Recent',
	MoreInfo = 'MoreInfo',
}

export enum EventFilter {
	Favourite = 'favourite',
	Categories = 'categories',
}

export enum AppsEventType {
	Launch = 'Apps.launch',
	Launch_Failure = 'Apps.launch.failure',
	Favourite = 'Apps.favourite',
	MoreInfo = 'Apps.moreInfo',
	Filtering = 'Apps.filtering',
	Sorting = 'Apps.sorting',
	Install = 'Apps.install',
	Update = 'Apps.update',
	Remove = 'Apps.remove',
	DoesNotExist = 'Apps.doesnotexist',
}

export enum DesktopEventType {
	Launch = 'Desktop.launch',
	Favourite = 'Desktop.favourite',
	MoreInfo = 'Desktop.moreInfo',
	Filtering = 'Desktop.filtering',
	Sorting = 'Desktop.sorting',
	Restart = 'Desktop.restart',
	DoesNotExist = 'Desktops.doesnotexist',
}

export enum GeneralEventType {
	EditProfile = 'EditProfile.change',
	RegionalSettings = 'RegionalSettings.change',
	ScreenSwitch = 'Screen.switch',
}

export enum BrowserExtensionEventType {
	BrowserExtensionAppLaunch = 'BrowserExtensionAppLaunch',
	BrowserExtensionAppLaunchError = 'BrowserExtensionAppLaunch_Error',
	BrowserExtensionIcaLaunch = 'BrowserExtensionIcaLaunch',
	BrowserExtensionLeaseLaunch = 'BrowserExtensionLeaseLaunch',
	BrowserExtensionLeaseLaunchLeaseFallbackToIca = 'BrowserExtensionLeaseLaunch_LeaseFallbackToIca',
	BrowserExtensionLeaseLaunchIcaFallbackToLease = 'BrowserExtensionLeaseLaunch_IcaFallbackToLease',
	BrowserExtensionLeaseLaunchCloudOffline = 'BrowserExtensionLeaseLaunch_CloudOffline',
	BrowserExtensionCloudOfflineGetUserDetailsFailed = 'CloudOfflineWithExtension_GetUserDetailsFailed',
	BrowserExtensionCloudOfflineUserTriggered = 'CloudOfflineWithExtension_UserTriggered',
}

export enum BrowserExtensionInstallPromptEventType {
	InstallPromptInstalled = 'BrowserExtensionInstallPrompt.Installed',
	InstallPromptCancelled = 'BrowserExtensionInstallPrompt.Cancelled',
}

export enum ShieldHealthCheckEventType {
	Success = 'ShieldHealthCheck.success',
	Retry = 'ShieldHealthCheck.retry',
	Unauthenticated = 'ShieldHealthCheck.unauthenticated',
	ClSyncSuccess = 'Shield.clsync_pass',
	ClSyncFail = 'Shield.clsync_fail',
}

export enum CustomizeUserHomeModalEventType {
	Subscription_Success = 'CustomizeUserHomeModal.subscription.success',
	Subscription_Failure = 'CustomizeUserHomeModal.subscription.failure',
	Skipped_Personalization = 'CustomizeUserHomeModal.skipped.personalization',
	Shortcut_Success = 'CustomizeUserHomeModal.shortcut.success',
	Shortcut_Failure = 'CustomizeUserHomeModal.shortcut.failure',
	Skipped_Shortcut = 'CustomizeUserHomeModal.skipped.shortcut',
}

export enum EventType {
	NotificationFeed_SortOrderChange = 'NotificationFeed.SortOrderChange',
	NotificationFeed_PageLoad = 'NotificationFeed.PageLoad',
	NotificationFeed_LoadNewFeedCards = 'NotificationFeed.LoadNewFeedCards',
	NotificationFeed_ScrollLoad = 'NotificationFeed.ScrollLoad',
	Activity = 'ACTIVITY',
	Start = 'start',
	End = 'end',
	Error = 'error',
	Cancel = 'cancel',
	WorkspaceToStorefrontFallback = 'WorkspaceToStorefrontFallback',
	Search_Init = 'Search.init',
	Search_KeyIn = 'Search.keyin',
	Search_Select = 'Search.select',
	Search_No_Results = 'Search.noResults',
	AdminNotificationBanner = 'AdminNotificationBanner',
	DevicePostureBanner = 'NonComplaintDeviceBanner',
	CitrixEnterpriseBrowser_Launch = 'CitrixEnterpriseBrowser.Launch',
	CitrixEnterpriseBrowser_ResourceAvailable = 'CitrixEnterpriseBrowser.ResourceAvailable',
	SaasLaunch_UsingNative = 'SaasLaunch.UsingNative',
}

export enum ActivityManagerEventType {
	ActivityManager_Init = 'ActivityManager.Init',
	ActivityManager_Logout = 'ActivityManager.Logout',
	ActivityManage_Disconnect = 'ActivityManager.Disconnect',
	ActivityManager_Restart = 'ActivityManager.Restart',
	ActivityManager_Terminate = 'ActivityManger.Terminate',
	ActivityManager_Poweroff = 'ActivityManager.Poweroff',
	ActivityManager_Shutdown = 'ActivityManager.Shutdown',
	ActivityManager_Hibernate = 'ActivityManager.Hibernate',
	ActivityManager_Resume = 'ActivityManager.Resume',
	ActivityManager_Reconnect = 'ActivityManager.Reconnect',
	ActivityManager_SessionHandoff = 'ActivityManager.SessionHandoff',
}

export enum ConfiguredServiceEventType {
	ConfiguredService_HomeTabEnabled = 'ConfiguredService.HomeTabEnabled',
	ConfiguredService_HomeTabDisabled = 'ConfiguredService.HomeTabDisabled',
	ConfiguredService_SimpleViewEnabled = 'ConfiguredService.SimpleViewEnabled',
	ConfiguredService_SimpleViewDisabled = 'ConfiguredService.SimpleViewDisabled',
}

export enum AADSsoEventType {
	TokenRequested = 'AADSsoEvent.AuthTokenRequested',
	TokenError = 'AADSsoEvent.AuthTokenError',
}

export enum GACSV2EventType {
	GACSV2Enabled = 'GACSV2Event.GACSV2Enabled',
	GACSV2IsSuccess = 'GACSV2Event.GACSV2IsSuccess',
	GACSV2Latency = 'GACSV2Event.GACSV2Latency',
	GACSV2Timeout = 'GACSV2Event.GACSV2Timeout',
}

export enum PinnedLinksEventType {
	pinnedLinkDetails = 'PinnedLinks.PinnedLinkDetails',
	pinnedLinkClicked = 'PinnedLinks.PinnedLinkClicked',
}
export interface TelemetryEventPublisher {
	publishEvent: <T extends TelemetryEvent>(event: T) => Promise<void>;
}
