import React, { useEffect, useState } from 'react';
import { t } from '@citrite/translate';
import {
	Button,
	fromThemeColors,
	layout,
	LoadingPrimary,
	palette,
	RefreshActionIcon,
	typography,
} from '@citrite/web-ui-component';
import { WorkspaceConfiguration } from '@citrite/workspace-ui-platform';
import styled from '@emotion/styled';
import { trackAnalyticsEvent } from 'analytics';
import { ActivityCardItem } from 'App/Activity/ActivityCard/ActivityCard';
import {
	addSessions,
	isAddActivitiesEvent,
	isEventSupportedByActivityManager,
	isRemoveActivitiesEvent,
	parseResourcesList,
	removeSessions,
	shouldShowLoader,
	shouldShowTitle,
	subscribeToEventBus,
	unsubscribeFromEventBus,
} from 'App/Activity/ActivityManager/ActivityManagerUtils';
import { ActivityManagerProvider } from 'App/Activity/ActivityManagerContext';
import { ActivitySectionsList } from 'App/Activity/ActivitySectionsList/ActivitySectionsList';
import {
	ActivityLoadingState,
	RemoveResourceEvent,
} from 'App/Activity/CommonResourceUtils';
import { SessionSectionList } from 'App/Activity/components/SessionSectionList';
import { fetchAllResources } from 'App/Activity/Local/FetchResourceAction';
import { useResourceManagerContext } from 'App/Activity/ResourceManagerContext';
import { bottomNavBarHeight } from 'App/BottomNav/dimensions';
import { useFormFactor } from 'App/Header/useFormFactor';
import { useClientHeight } from 'Components/AppLayout/useClientHeight';
import { useHTMLElementProperties } from 'Components/AppLayout/useHTMLElementProperties';
import { useEventBus } from 'Components/EventBus';
import { useConfigurationContext } from 'Configuration/useConfigurationContext';
import { FeatureFlag } from 'Environment/FeatureFlag';
import { isNativeWidget } from 'Environment/launchResource/device';
import { useFeatureCanary } from 'utils/useFeatureCanary';
import { Event } from 'Workspace/EventBus';
import { activityMangerCASReporter } from 'Workspace/TelemetryEvents/activityManager/createActivityManagerCASReporter';

interface ActivityManagerProps {
	setMobileMastheadTitle?: (mastheadTitle: React.ReactNode) => void;
}

export const ActivityManager = (props: ActivityManagerProps) => {
	const canUseResourceManagerContext = useFeatureCanary(
		FeatureFlag.ActivityManagerRefactoring
	);
	return canUseResourceManagerContext ? (
		<_ActivityManager {...props} />
	) : (
		<OldActivityManager {...props} />
	);
};

const StyledHorizontalLine = styled.hr`
	margin: ${layout.smallSpace} -4px ${layout.smallSpace} -${layout.mediumSpace};
	border-top: 1px solid ${palette.grey400};
	border-bottom: 0px;
`;

const StyledContainerDiv = styled.div<{
	isSmallFormFactor: boolean;
	offsetTop: number;
	clientHeight: string;
}>`
	display: flex;
	height: 446px;
	width: 336px;
	border-radius: ${layout.tinySpace};
	flex-direction: column;
	background-color: ${fromThemeColors('primaryContainerBackground')};
	line-height: 1;
	padding: ${layout.mediumSpace} ${layout.tinySpace} ${layout.tinySpace}
		${layout.mediumSpace};
	${({ isSmallFormFactor, offsetTop, clientHeight }) =>
		isSmallFormFactor &&
		`
		height: calc(${clientHeight} - ${offsetTop}px - ${layout.mediumSpace} - ${
			layout.tinySpace
		} - ${isNativeWidget() ? '0px' : bottomNavBarHeight});
		width: auto;
	`}
`;

const StyledLoadingPrimary = styled(LoadingPrimary)`
	margin-left: -6px;
`;

const StyledTitle = styled.div`
	font-size: ${typography.sizes.lg};
	font-weight: ${typography.weights.semiBold};
	color: ${fromThemeColors('primaryText')};
	padding-bottom: ${layout.smallSpace};
`;

const StyledRefreshButtonContainer = styled.div<{
	isSmallFormFactor: boolean;
}>`
	position: absolute;
	right: ${layout.mediumSpace};
	${({ isSmallFormFactor }) =>
		isSmallFormFactor &&
		`
		margin-top: ${layout.mediumSpace};
	`}
`;
// allow-unused-export
export const _ActivityManager = (props: ActivityManagerProps) => {
	const resourceManagerContext = useResourceManagerContext();

	useEffect(() => {
		props.setMobileMastheadTitle &&
			props.setMobileMastheadTitle(t('Workspace:activity_manager.activity'));
	}, [props]);

	const refresh = () => resourceManagerContext.refresh(false);

	const newLoader =
		resourceManagerContext.localSessions.loading ||
		resourceManagerContext.remoteSessions.loading ||
		resourceManagerContext.hibernatedSessions.loading;

	const { elementRef, offsetTop } = useHTMLElementProperties();
	const clientHeight = useClientHeight();
	const { isSmallFormFactor } = useFormFactor();

	useEffect(() => {
		resourceManagerContext.refresh(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<StyledContainerDiv
			data-analytics-id="activity-manager"
			ref={elementRef}
			offsetTop={offsetTop}
			clientHeight={clientHeight}
			isSmallFormFactor={isSmallFormFactor}
		>
			<StyledRefreshButtonContainer isSmallFormFactor={isSmallFormFactor}>
				<Button.Wrapper onClick={refresh} disabled={newLoader}>
					<RefreshActionIcon size={16} />
				</Button.Wrapper>
			</StyledRefreshButtonContainer>

			{shouldShowTitle(isSmallFormFactor) && (
				<>
					<StyledTitle>{t('Workspace:activity_manager.activity')}</StyledTitle>
					<StyledHorizontalLine />
				</>
			)}
			{newLoader ? <StyledLoadingPrimary size={50} /> : <SessionSectionList />}
		</StyledContainerDiv>
	);
};

// allow-unused-export
export const OldActivityManager = (props: ActivityManagerProps) => {
	const eventBus = useEventBus();
	const [localRunningSessions, setLocalRunningSessions] = useState<
		Map<ActivityCardItem, ActivityCardItem[]>
	>(new Map<ActivityCardItem, ActivityCardItem[]>());
	const [remoteRunningSessions, setRemoteRunningSessions] = useState<
		Map<ActivityCardItem, ActivityCardItem[]>
	>(new Map<ActivityCardItem, ActivityCardItem[]>());
	const [hibernatedSessions, setHibernatedSessions] = useState<
		Map<ActivityCardItem, ActivityCardItem[]>
	>(new Map<ActivityCardItem, ActivityCardItem[]>());
	const workspaceConfiguration: WorkspaceConfiguration =
		useConfigurationContext().workspaceConfiguration;

	const [activityResourceLoading, setActivityResourceLoading] =
		useState<ActivityLoadingState>({
			isLocalResourceLoaded: false,
			isRemoteResourceLoaded: false,
		});

	useEffect(() => {
		props.setMobileMastheadTitle &&
			props.setMobileMastheadTitle(t('Workspace:activity_manager.activity'));
	}, [props]);

	const resetAllSessions = () => {
		setActivityResourceLoading({
			isLocalResourceLoaded: false,
			isRemoteResourceLoaded: false,
		});
		setLocalRunningSessions(new Map<ActivityCardItem, ActivityCardItem[]>());
		setRemoteRunningSessions(new Map<ActivityCardItem, ActivityCardItem[]>());
		setHibernatedSessions(new Map<ActivityCardItem, ActivityCardItem[]>());
	};

	const refresh = () => {
		resetAllSessions();
		fetchAllResources(
			workspaceConfiguration,
			setLocalRunningSessions,
			setRemoteRunningSessions,
			setHibernatedSessions,
			setActivityResourceLoading
		);
	};

	useEffect(() => {
		trackAnalyticsEvent(activityMangerCASReporter.getActivityManagerInitEvent());
		fetchAllResources(
			workspaceConfiguration,
			setLocalRunningSessions,
			setRemoteRunningSessions,
			setHibernatedSessions,
			setActivityResourceLoading
		);
	}, [workspaceConfiguration]);

	useEffect(() => {
		const handleAddEvent = (activityCardItems: ActivityCardItem[]) => {
			setLocalRunningSessions(localSessionsMap => {
				return addSessions(activityCardItems, localSessionsMap);
			});
		};

		const handleRemoveEvent = (removeSessionsEvent: RemoveResourceEvent) => {
			removeSessionsEvent &&
				setLocalRunningSessions(localSessionsMap => {
					return removeSessions(removeSessionsEvent.sessionid, localSessionsMap);
				});
		};

		const unsubscribe = eventBus.subscribe((event: Event<any>) => {
			if (event.payload && isEventSupportedByActivityManager(event.type)) {
				if (isAddActivitiesEvent(event.type)) {
					handleAddEvent(parseResourcesList(event.payload));
				} else if (isRemoveActivitiesEvent(event.type)) {
					handleRemoveEvent(event.payload);
				}
			}
		});
		subscribeToEventBus();

		return () => {
			unsubscribeFromEventBus();
			unsubscribe && unsubscribe();
		};
	}, [eventBus]);

	const loading = shouldShowLoader(
		activityResourceLoading,
		localRunningSessions.size,
		remoteRunningSessions.size,
		hibernatedSessions.size
	);

	const { elementRef, offsetTop } = useHTMLElementProperties();
	const clientHeight = useClientHeight();
	const { isSmallFormFactor } = useFormFactor();

	return (
		<ActivityManagerProvider
			value={{
				localRunningSessions,
				setLocalRunningSessions,
				remoteRunningSessions,
				setRemoteRunningSessions,
				hibernatedSessions,
				setHibernatedSessions,
				refresh,
			}}
		>
			<StyledContainerDiv
				data-analytics-id="activity-manager"
				ref={elementRef}
				offsetTop={offsetTop}
				clientHeight={clientHeight}
				isSmallFormFactor={isSmallFormFactor}
			>
				<StyledRefreshButtonContainer isSmallFormFactor={isSmallFormFactor}>
					<Button.Wrapper onClick={refresh} disabled={loading}>
						<RefreshActionIcon size={16} />
					</Button.Wrapper>
				</StyledRefreshButtonContainer>

				{shouldShowTitle(isSmallFormFactor) && (
					<>
						<StyledTitle>{t('Workspace:activity_manager.activity')}</StyledTitle>
						<StyledHorizontalLine />
					</>
				)}
				{loading ? <StyledLoadingPrimary size={50} /> : <ActivitySectionsList />}
			</StyledContainerDiv>
		</ActivityManagerProvider>
	);
};
