import * as React from 'react';
import { ConnectionError } from 'Components/ConnectionError/ConnectionError';
import { FeatureFlag } from 'Environment/FeatureFlag';
import { useFeatureCanary } from 'utils/useFeatureCanary';
import { Routes } from './Routes';
import { ScrollToTop } from './ScrollToTop';

export function Router() {
	const showConenctionErrorScreen = useFeatureCanary(
		FeatureFlag.EnableConnectionErrorScreen
	);
	const routes = (
		<ScrollToTop>
			<Routes />
		</ScrollToTop>
	);
	return showConenctionErrorScreen ? <ConnectionError>{routes}</ConnectionError> : routes;
}
