import { t } from '@citrite/translate';
import { DropDownActionItem } from 'App/Activity/DropDownUtils';
import { disconnect } from 'App/Activity/Local/DisconnectAction';
import { logout } from 'App/Activity/Local/LogoutAction';
import { powerManagementOperation } from 'App/Activity/Local/PowerManagementAction';
import { restart } from 'App/Activity/Local/RestartAction';
import { resume } from 'App/Activity/Local/ResumeAction';
import { terminate } from 'App/Activity/Local/TerminateAction';
import { ResponseData } from 'App/Activity/Network/ActionsUtil';

export enum LocalOperationType {
	LOGOFF = 'logoff',
	DISCONNECT = 'disconnect',
	TERMINATE = 'terminate',
	RESTART = 'restart',
	POWEROFF = 'poweroff',
	SHUTDOWN = 'shutdown',
	HIBERNATE = 'hibernate',
	RESUME = 'resume',
	NOT_SUPPORTED = 'not supported',
}

const createLocalOperationObject = (
	operationName: () => string,
	operation: (dropDownActionItem: DropDownActionItem) => Promise<ResponseData>
) => {
	return {
		name: operationName,
		op: operation,
	};
};

const localOperationName: Record<
	LocalOperationType,
	{
		name: () => string;
		op: (dropDownActionItem: DropDownActionItem) => Promise<ResponseData>;
	}
> = {
	[LocalOperationType.LOGOFF]: createLocalOperationObject(
		() => t('Workspace:activity_manager.log_out'),
		logout
	),
	[LocalOperationType.DISCONNECT]: createLocalOperationObject(
		() => t('Workspace:activity_manager.disconnect'),
		disconnect
	),
	[LocalOperationType.TERMINATE]: createLocalOperationObject(
		() => t('Workspace:activity_manager.terminate'),
		terminate
	),
	[LocalOperationType.RESTART]: createLocalOperationObject(
		() => t('Workspace:activity_manager.restart'),
		restart
	),
	[LocalOperationType.POWEROFF]: createLocalOperationObject(
		() => t('Workspace:activity_manager.poweroff'),
		powerManagementOperation
	),
	[LocalOperationType.SHUTDOWN]: createLocalOperationObject(
		() => t('Workspace:activity_manager.shutdown'),
		powerManagementOperation
	),
	[LocalOperationType.HIBERNATE]: createLocalOperationObject(
		() => t('Workspace:activity_manager.hibernate'),
		powerManagementOperation
	),
	[LocalOperationType.RESUME]: createLocalOperationObject(
		() => t('Workspace:activity_manager.resume'),
		resume
	),
	[LocalOperationType.NOT_SUPPORTED]: createLocalOperationObject(() => null, null),
};

export const getLocalOperationName = (operation: string): string => {
	return localOperationName[operation]?.name() || null;
};

export const getLocalOperation = (
	operation: string
): ((dropDownActionItem: DropDownActionItem) => Promise<ResponseData>) => {
	return localOperationName[operation]?.op || null;
};
