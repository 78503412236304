import * as React from 'react';
import { t } from '@citrite/translate';
import { Button, palette, Tooltip } from '@citrite/web-ui-component';
import {
	BackoffStrategy,
	resilientPromise,
	WorkspaceConfiguration,
} from '@citrite/workspace-ui-platform';
import { AdaptiveDropdown } from '@citrite/workspace-ui-platform-react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { ActivityCardItem } from 'App/Activity/ActivityCard/ActivityCard';
import {
	ActivityManagerContext,
	useActivityManagerContext,
} from 'App/Activity/ActivityManagerContext';
import { ActivityStatus } from 'App/Activity/ActivityStatus/ActivityStatus';
import {
	filterAnyResourceWithResourceId,
	filterSessionsWithResourceId,
	getResourceFromResourceContext,
	getResourceStateDisplayName,
	getResourceStateFromOperation,
	resetLists,
	ResourceState,
	ResourceType,
	shouldShowResourceState,
	updateResourceState,
} from 'App/Activity/CommonResourceUtils';
import { DropDownActionItem } from 'App/Activity/DropDownUtils';
import {
	checkOperationStatus,
	isRequestSuccessful,
	ResponseData,
} from 'App/Activity/Network/ActionsUtil';
import { useFormFactor } from 'App/Header/useFormFactor';
import { launchGenericResource } from 'App/Tile/utils';
import { restartDesktop } from 'Components/ResourceTile/restartDesktop';
import { useConfigurationContext } from 'Configuration/useConfigurationContext';
import { isNativeMobileClient, isNativeWidget } from 'Environment/launchResource/device';
import { useLoadableResourceContext } from 'Workspace/ResourceProvider';
import { Resource } from 'Workspace/ResourceProvider/resourceTypes';
import { useUserContext } from 'Workspace/UserContext';
import {
	checkActivityStatus,
	checkDisconnectedState,
	createContextMenuBuilder,
	findToolTipString,
	getMenuIconForResource,
	isOperationDisconnect,
	isOperationHibernate,
	isOperationResume,
	reportError,
	showFailureReason,
} from '../Utility';

const MAX_ATTEMPTS = 8;
const DELAY = 15000;
const MAX_DELAY = 151000;

const smallFormMoreOptionsIconSize = 16;
const largeFormMoreOptionsIconSize = 16;

const smallFormActionIconSize = 16;
const largeFormActionIconSize = 10;

export interface ActivityMenuIconProps {
	resourceItem: ActivityCardItem;
	dropDownActionItem?: DropDownActionItem[];
}

const buttonStyle: React.CSSProperties = {
	lineHeight: 0,
	display: 'inline-block',
};

const StyledDiv = styled.div<{
	disconnected: boolean;
}>`
	${props =>
		props.disconnected &&
		css`
			color: ${palette.grey600};
		`};
`;

export const createToolTipFromString = (
	dropDownActionItem: DropDownActionItem,
	state = ResourceState.CONNECTED
): React.ReactNode => {
	return (
		<Tooltip
			hoverTrigger={createOperationItem(state, dropDownActionItem)}
			isDisabled={
				checkDisconnectedState(state, dropDownActionItem.item) ||
				isOperationResume(dropDownActionItem.item)
			}
		>
			{findToolTipString(dropDownActionItem.item)}
		</Tooltip>
	);
};

const createOperationItem = (
	state: ResourceState,
	dropDownActionItem: DropDownActionItem
): React.ReactElement => {
	return (
		<StyledDiv disconnected={checkDisconnectedState(state, dropDownActionItem.item)}>
			{dropDownActionItem.displayName}
		</StyledDiv>
	);
};

export const ActivityMenuIcon = (props: ActivityMenuIconProps) => {
	const { value: resourceContext } = useLoadableResourceContext();
	const resource: Resource = getResourceFromResourceContext(
		props.resourceItem?.activityResource.resourceId,
		resourceContext.resources
	);
	const activityManagerContext = useActivityManagerContext();
	const activityResourceState: ResourceState = checkActivityStatus(
		props.resourceItem?.activityResource?.sessionId,
		props.resourceItem?.activityResource?.resourceId,
		activityManagerContext,
		resourceContext
	);
	const userContext = useUserContext();
	const { isSmallFormFactor } = useFormFactor();
	const moreOptionsIconSize = isSmallFormFactor
		? smallFormMoreOptionsIconSize
		: largeFormMoreOptionsIconSize;
	const actionIconSize = isSmallFormFactor
		? smallFormActionIconSize
		: largeFormActionIconSize;
	const showResourceState = shouldShowResourceState(activityResourceState);
	const resourceType: ResourceType = props.resourceItem?.activityResource?.type;
	const showDropdown =
		props.dropDownActionItem.length > 0 && resourceType === ResourceType.VIRTUAL_DESKTOP;
	const buttonItem: DropDownActionItem =
		props.dropDownActionItem.length === 1 && resourceType === ResourceType.VIRTUAL_APP
			? props.dropDownActionItem[0]
			: null;
	const { workspaceConfiguration } = useConfigurationContext();
	const workspaceConfig: WorkspaceConfiguration = { workspaceConfiguration }
		.workspaceConfiguration;

	const updateConnectionState = (id: string, connectionState: ResourceState) => {
		updateItemState(
			id,
			connectionState,
			activityManagerContext.localRunningSessions,
			true
		);
		updateItemState(
			id,
			connectionState,
			activityManagerContext.remoteRunningSessions,
			false
		);
	};

	const updateItemState = (
		id: string,
		connectionState: ResourceState,
		sessionList: Map<ActivityCardItem, ActivityCardItem[]>,
		isLocalList: boolean
	) => {
		const resourcefound = filterAnyResourceWithResourceId(id, sessionList);
		if (resourcefound) {
			const list = updateResourceState(id, connectionState, sessionList);
			if (isLocalList) {
				activityManagerContext.setLocalRunningSessions(list);
			} else {
				activityManagerContext.setRemoteRunningSessions(list);
			}
		}
	};

	const addHibernateSection = (
		context: ActivityManagerContext,
		dropDownItem: DropDownActionItem
	) => {
		let hibernatedSessions = filterSessionsWithResourceId(
			context.localRunningSessions,
			dropDownItem.resourceId
		);
		if (hibernatedSessions.size === 0) {
			hibernatedSessions = filterSessionsWithResourceId(
				context.remoteRunningSessions,
				dropDownItem.resourceId
			);
		}
		context.setHibernatedSessions(hibernatedSessions);
		resetLists(context, dropDownItem.sessionId);
	};

	const notifyErrorAndUpdateState = (dropDownItem: DropDownActionItem) => {
		showFailureReason(dropDownItem.item, props.resourceItem?.activityResource.name);
		updateConnectionState(dropDownItem.resourceId, ResourceState.CONNECTED);
	};

	const onHandleItemClicked = async (dropDownItem: DropDownActionItem) => {
		updateConnectionState(
			dropDownItem.resourceId,
			getResourceStateFromOperation(dropDownItem.item)
		);

		if (
			dropDownItem.displayName === t('Workspace:activity_manager.restart') &&
			resource
		) {
			restartDesktop(resource, { resourceContext }, workspaceConfig);
		} else if (isOperationResume(dropDownItem.item)) {
			launchGenericResource({ resource, resourceContext, workspaceConfiguration });
		}

		let response: ResponseData = null;
		try {
			response = await dropDownItem.action(dropDownItem);
		} catch (error) {
			reportError(error.toString(), 'on ' + resource.name);
		}

		if (dropDownItem.endpoint) {
			if (!isRequestSuccessful(response, dropDownItem)) {
				notifyErrorAndUpdateState(dropDownItem);
			} else {
				await resilientPromise(
					async () => checkOperationStatus(workspaceConfig, dropDownItem, response),
					{
						delayMS: DELAY,
						maxDelayMS: MAX_DELAY,
						maxAttempts: MAX_ATTEMPTS,
						backoffStrategy: BackoffStrategy.LINEAR,
					}
				)
					.then(actionSuccessful => {
						if (actionSuccessful) {
							if (isOperationDisconnect(dropDownItem.item)) {
								updateConnectionState(
									dropDownItem.resourceId,
									ResourceState.DISCONNECTED
								);
							} else if (isOperationHibernate(dropDownItem.item)) {
								addHibernateSection(activityManagerContext, dropDownItem);
								activityManagerContext.refresh();
							} else {
								resetLists(activityManagerContext, dropDownItem.sessionId);
							}
						}
					})
					.catch(() => {
						notifyErrorAndUpdateState(dropDownItem);
					});
			}
		}
	};

	function shouldShowActionSheet(): boolean {
		return userContext.isDaasVisionEnabled
			? isSmallFormFactor && !isNativeWidget()
			: isNativeMobileClient();
	}

	return (
		<>
			{showDropdown && (
				<AdaptiveDropdown
					data={props.dropDownActionItem}
					showMobileActionSheet={shouldShowActionSheet()}
					name={props.resourceItem?.activityResource?.name}
					contextMenuBuilder={(items: DropDownActionItem[]) =>
						createContextMenuBuilder(
							items,
							onHandleItemClicked,
							props.resourceItem.activityResource.state
						)
					}
					size={moreOptionsIconSize}
					buttonStyle={buttonStyle}
				/>
			)}

			{buttonItem && (
				<Button.IconOnly
					size={actionIconSize}
					icon={getMenuIconForResource(resourceType)}
					onClick={() => {
						onHandleItemClicked(buttonItem);
					}}
					title={t('Workspace:activity_manager.terminate')}
					style={buttonStyle}
				/>
			)}
			{showResourceState && (
				<ActivityStatus status={getResourceStateDisplayName(activityResourceState)} />
			)}
		</>
	);
};
