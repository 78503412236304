import React, { useEffect } from 'react';
import { t } from '@citrite/translate';
import { fromThemeColors, layout, palette } from '@citrite/web-ui-component';
import { CallbackPlacement, EmptyState } from '@citrite/workspace-ui-platform-react';
import styled from '@emotion/styled';
import { SessionSection } from 'App/Activity/components/SessionSection';
import { Session, useResourceManagerContext } from 'App/Activity/ResourceManagerContext';
import { useFormFactor } from 'App/Header/useFormFactor';
import { FeatureFlag } from 'Environment/FeatureFlag';
import { useFeatureCanary } from 'utils/useFeatureCanary';

const StyledHorizontalLine = styled.hr`
	margin: ${layout.mediumSpace} ${layout.tinySpace} ${layout.smallSpace} 0;
	border-top: 1px solid ${palette.grey400};
	border-bottom: 0;
`;

const StyledDivEmpty = styled.div<{ isSmallFormFactor: boolean }>`
	display: flex;
	flex-direction: column;
	margin: auto ${layout.mediumSpace};
	${({ isSmallFormFactor }) =>
		isSmallFormFactor &&
		`
		margin: ${layout.largeSpace} ${layout.mediumSpace};
	`}
`;

const StyledDivContent = styled.div<{ isLargeFormFactor: boolean }>`
	display: flex;
	width: auto;
	flex-direction: column;
	background-color: ${fromThemeColors('primaryContainerBackground')};
	overflow-y: auto;
	padding-right: ${layout.smallSpace};
	${({ isLargeFormFactor }) =>
		isLargeFormFactor &&
		`
		&::-webkit-scrollbar {
			width: ${layout.tinySpace};
		}
		&::-webkit-scrollbar-thumb {
			width: ${layout.tinySpace};
			background: darkgray;
			border-radius: ${layout.tinySpace};
		}
	`}
`;
const imageUrl = require('images/empty-line-art.svg');

export const SessionSectionList = () => {
	const { localSessions, remoteSessions, hibernatedSessions, refresh } =
		useResourceManagerContext();
	const [hasSessions, setHasSessions] = React.useState(false);

	const hasLocalSessions = localSessions?.sessions?.some(
		session => session.applications.length > 0
	);
	const hasRemoteSession = remoteSessions?.sessions?.some(
		session => session.applications.length > 0
	);

	const hasHibernateSession = hibernatedSessions?.sessions?.some(
		session => session.applications.length > 0
	);

	useEffect(() => {
		setHasSessions(hasLocalSessions || hasRemoteSession || hasHibernateSession);
	}, [hasLocalSessions, hasRemoteSession, hasHibernateSession]);

	const { isSmallFormFactor, isLargeFormFactor } = useFormFactor();

	const isHibernationEnabled = useFeatureCanary(FeatureFlag.HibernateAndResume);
	const shouldShowHibernatedSection =
		isHibernationEnabled &&
		hibernatedSessions?.sessions?.some(session => session.applications.length > 0);

	const emptyStateProps = {
		source: t('Workspace:activity_manager.activity'),
		title: '',
		description: t('Workspace:activity_manager.empty_view'),
		imageUrl,
		callback: () => refresh(false),
		callbackPlacement: CallbackPlacement.bottomBabySecondaryButton,
		callbackLabel: t('Workspace:activity_manager.refresh'),
	};

	if (!hasSessions) {
		return (
			<StyledDivEmpty
				data-analytics-id="activity-manager-empty-state"
				isSmallFormFactor={isSmallFormFactor}
			>
				<EmptyState {...emptyStateProps} />
			</StyledDivEmpty>
		);
	}

	const sections: JSX.Element[] = [];
	if (hasLocalSessions) {
		addSessionSection(
			sections,
			'local',
			t('Workspace:activity_manager.on_this_device'),
			localSessions.sessions
		);
	}
	if (hasRemoteSession) {
		addSessionSection(
			sections,
			'remote',
			t('Workspace:activity_manager.running_remotely'),
			remoteSessions.sessions
		);
	}
	if (shouldShowHibernatedSection) {
		addSessionSection(
			sections,
			'hibernated',
			t('Workspace:activity_manager.in_hibernation'),
			hibernatedSessions.sessions
		);
	}

	const sectionsWithDividers = sections.flatMap((section, index) => [
		section,
		index !== sections.length - 1 && <StyledHorizontalLine key={`line-${index}`} />,
	]);

	return (
		<>
			{
				<StyledDivContent
					data-analytics-id="ActivityManager.NonEmptyState"
					isLargeFormFactor={isLargeFormFactor}
				>
					{sectionsWithDividers}
				</StyledDivContent>
			}
		</>
	);
};

function addSessionSection(
	sections: JSX.Element[],
	key: string,
	name: string,
	sessions: Session[]
) {
	sections.push(<SessionSection key={key} name={name} sessions={sessions} />);
}
