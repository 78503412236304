import * as React from 'react';
import { FeatureFlag } from 'Environment/FeatureFlag';
import { useFeatureCanary } from 'utils/useFeatureCanary';
import { context } from './Context';

interface Props {
	children: JSX.Element | JSX.Element[];
}

export function ConnectionDetailsProvider(props: Props) {
	const [isOnline, setIsOnline] = React.useState<boolean>(navigator.onLine);
	const enableConnectionDetection = useFeatureCanary(
		FeatureFlag.EnableConnectionErrorScreen
	);

	const contextValue = React.useMemo(() => {
		return { isOnline };
	}, [isOnline]);

	React.useEffect(() => {
		if (!enableConnectionDetection) {
			return () => {};
		}
		const connectionHandler = () => {
			if (navigator.onLine) {
				setIsOnline(true);
			} else {
				setIsOnline(false);
			}
		};
		window.addEventListener('online', connectionHandler);
		window.addEventListener('offline', connectionHandler);
		return () => {
			window.removeEventListener('online', connectionHandler);
			window.removeEventListener('offline', connectionHandler);
		};
	}, [enableConnectionDetection]);

	return <context.Provider value={contextValue}>{props.children}</context.Provider>;
}
