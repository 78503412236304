import { trackAnalyticsEvent } from 'analytics';
import { checkAnalyticsEvent } from 'App/Activity/CommonResourceUtils';
import { DropDownActionItem } from 'App/Activity/DropDownUtils';
import { ResponseData } from 'App/Activity/Network/ActionsUtil';
import { environment } from 'Environment';

export const powerManagementOperation = (
	dropDownActionItem: DropDownActionItem
): Promise<ResponseData> => {
	const operationType = dropDownActionItem.item;
	const eventName = {
		id: 'activities',
		type: `action/miniui/running/resource/${operationType}`,
		payload: { machineId: dropDownActionItem.machineData?.machineId },
	};
	environment.sendEventToNative(eventName);
	const event = checkAnalyticsEvent(dropDownActionItem.item);
	trackAnalyticsEvent(event);
	return Promise.resolve({});
};
