import { t } from '@citrite/translate';
import {
	ActivityDesktopSolidIcon,
	AppsIcon,
	CloseIcon,
	IconProps,
	MoreActionIcon,
	notifyError,
} from '@citrite/web-ui-component';
import { ContextMenuItem } from '@citrite/workspace-ui-platform-react';
import { logError } from 'remoteLogging';
import { ActivityCardItem } from 'App/Activity/ActivityCard/ActivityCard';
import { ActivityManagerContext } from 'App/Activity/ActivityManagerContext';
import { createToolTipFromString } from 'App/Activity/ActivityMenuIcon/ActivityMenuIcon';
import {
	filterResourcesFromContext,
	MachineData,
	ResourceState,
	ResourceType,
} from 'App/Activity/CommonResourceUtils';
import { DropDownActionItem } from 'App/Activity/DropDownUtils';
import { LocalOperationType } from 'App/Activity/Local/OperationUtils';
import {
	PowerState,
	RemoteOperationType,
	SessionSupport,
} from 'App/Activity/Network/ActionsUtil';
import { ResourceType as ResourceType2 } from 'App/Tile/ResourceDetails';
import { ResourceContext } from 'Workspace/ResourceProvider';
import { Resource } from 'Workspace/ResourceProvider/resourceTypes';

type OperationDetail = {
	toolTipString: string;
	notifyErrorString: string;
};

function initOpMap() {
	const localData: { [key: string]: OperationDetail } = {};
	const addEntry = (
		op1: RemoteOperationType,
		op2: LocalOperationType,
		detail: OperationDetail
	) => {
		localData[op1] = detail;
		localData[op2] = detail;
	};

	addEntry(RemoteOperationType.DISCONNECT, LocalOperationType.DISCONNECT, {
		toolTipString: 'Workspace:activity_manager.tooltip_disconnect',
		notifyErrorString: 'Workspace:activity_manager.failure_operation_name.disconnecting',
	});
	addEntry(RemoteOperationType.LOGOFF, LocalOperationType.LOGOFF, {
		toolTipString: 'Workspace:activity_manager.tooltip_logout',
		notifyErrorString: 'Workspace:activity_manager.failure_operation_name.logging_out',
	});
	addEntry(RemoteOperationType.POWEROFF, LocalOperationType.POWEROFF, {
		toolTipString: 'Workspace:activity_manager.tooltip_poweroff',
		notifyErrorString: 'Workspace:activity_manager.failure_operation_name.powering_off',
	});
	addEntry(RemoteOperationType.SHUTDOWN, LocalOperationType.SHUTDOWN, {
		toolTipString: 'Workspace:activity_manager.tooltip_shutdown',
		notifyErrorString: 'Workspace:activity_manager.failure_operation_name.shutting_down',
	});
	addEntry(RemoteOperationType.RESTART, LocalOperationType.RESTART, {
		toolTipString: 'Workspace:activity_manager.tooltip_restart',
		notifyErrorString: 'Workspace:activity_manager.failure_operation_name.restarting',
	});
	addEntry(RemoteOperationType.HIBERNATE, LocalOperationType.HIBERNATE, {
		toolTipString: 'Workspace:activity_manager.tooltip_hibernate',
		notifyErrorString: 'Workspace:activity_manager.failure_operation_name.hibernating',
	});
	addEntry(RemoteOperationType.RESUME, LocalOperationType.RESUME, {
		toolTipString: '',
		notifyErrorString: 'Workspace:activity_manager.failure_operation_name.resuming',
	});

	return localData;
}

const OpMap: { [key: string]: OperationDetail } = initOpMap();

export const sortByTimestamp = (
	firstElement: ActivityCardItem,
	secondElement: ActivityCardItem
) => {
	const firstDate: Date = new Date(firstElement.activityResource.startTimeStamp);
	const secondDate: Date = new Date(secondElement.activityResource.startTimeStamp);
	return firstDate.getDate() - secondDate.getDate();
};

export const isOperationPowerManagment = (opName: string): boolean => {
	let operationPowerManagement = false;
	if (
		opName === RemoteOperationType.POWEROFF ||
		opName === RemoteOperationType.SHUTDOWN ||
		opName === RemoteOperationType.RESTART ||
		opName === RemoteOperationType.HIBERNATE ||
		opName === RemoteOperationType.RESUME
	) {
		operationPowerManagement = true;
	}
	return operationPowerManagement;
};

export const isPowerOffRestartShutDownSupported = (
	opName: string,
	machineData: MachineData,
	isDesktopSession: boolean
): boolean => {
	let powerManagementSupported = false;
	if (opName === RemoteOperationType.RESTART && isDesktopSession) {
		powerManagementSupported = true;
	} else if (machineData) {
		powerManagementSupported =
			machineData.sessionSupport === SessionSupport.SINGLE_SESSION &&
			((opName === RemoteOperationType.SHUTDOWN && machineData.shutDownSupported) ||
				(opName === RemoteOperationType.POWEROFF && machineData.turnOffSupported));
	}
	return powerManagementSupported;
};

export const isHibernationAndResumeSupported = (
	opName: string,
	machineData: MachineData
) => {
	let hibernationAndResumeSupported = false;
	if (machineData) {
		hibernationAndResumeSupported =
			(machineData.sessionSupport === SessionSupport.SINGLE_SESSION &&
				isOperationHibernate(opName) &&
				machineData.suspendSupported) ||
			(machineData.powerState === PowerState.SUSPENDED && isOperationResume(opName));
	}

	return hibernationAndResumeSupported;
};

export const reportError = (error: string, errorDescription: string) => {
	logError(new Error(error), {
		tags: { feature: 'activity-manager' },
		additionalContext: {
			error,
			description: errorDescription,
		},
	});
};

export const getMenuIconForResource = (
	resourceType: ResourceType
): React.FC<IconProps> => {
	let menuIcon: React.FC<IconProps>;
	if (resourceType === ResourceType.VIRTUAL_DESKTOP) {
		menuIcon = MoreActionIcon;
	} else if (resourceType === ResourceType.VIRTUAL_APP) {
		menuIcon = CloseIcon;
	}
	return menuIcon;
};

export const checkActivityStatus = (
	sessionId: string,
	resourceId: string,
	activityManagerContext: ActivityManagerContext,
	resourceContext: ResourceContext
): ResourceState => {
	const resource: ActivityCardItem = filterResourcesFromContext(
		sessionId,
		activityManagerContext
	);
	if (resourceContext.desktopRestartInProgressIds?.includes(sessionId)) {
		return ResourceState.RESTARTING;
	} else if (resourceContext.launchInProgressIds?.includes(resourceId)) {
		return ResourceState.RESUMING;
	} else {
		return resource ? resource.activityResource.state : ResourceState.UNKNOWN;
	}
};

export const createContextMenuBuilder = (
	items: DropDownActionItem[],
	callback: (dropDownActionItem: DropDownActionItem) => void,
	state = ResourceState.CONNECTED
): ContextMenuItem[] => {
	return items
		.filter(item => item !== null)
		.map((dropDownActionItem: DropDownActionItem): ContextMenuItem => {
			return {
				children: createToolTipFromString(dropDownActionItem, state),
				onClick: checkDisconnectedState(state, dropDownActionItem.item)
					? () => {}
					: () => callback(dropDownActionItem),
				disabled: checkDisconnectedState(state, dropDownActionItem.item),
			};
		});
};

export const findToolTipString = (operation: string): string => {
	const operationDetail = OpMap[operation];
	return operationDetail ? t(operationDetail.toolTipString) : null;
};

export const showFailureReason = (operation: string, currentResourceName: string) => {
	const operationDetail = OpMap[operation];
	return operationDetail
		? notifyError(
				t(operationDetail.notifyErrorString, {
					resourceName: currentResourceName,
				})
		  )
		: null;
};

export const isOperationDisconnect = (operation: string) => {
	return (
		operation === RemoteOperationType.DISCONNECT ||
		operation === LocalOperationType.DISCONNECT
	);
};

export const isOperationHibernate = (operation: string) => {
	return (
		operation === RemoteOperationType.HIBERNATE ||
		operation === LocalOperationType.HIBERNATE
	);
};

export const isOperationResume = (operation: string) => {
	return (
		operation === RemoteOperationType.RESUME || operation === LocalOperationType.RESUME
	);
};

export const checkDisconnectedState = (state: ResourceState, operation: string) => {
	return state === ResourceState.DISCONNECTED && isOperationDisconnect(operation);
};

export const getResoureIconData = (activityCardItem: ActivityCardItem) => {
	let resourceIconUrl = '';
	let shouldForceSkipNativeFetch = false;
	if (activityCardItem?.activityResource?.isFetchIconAvailable) {
		resourceIconUrl = 'activityManager:' + activityCardItem.activityResource.resourceId;
	} else if (activityCardItem?.activityResource?.resourceIcon) {
		resourceIconUrl = activityCardItem?.activityResource?.resourceIcon;
		shouldForceSkipNativeFetch = true;
	}
	return { resourceIconUrl, shouldForceSkipNativeFetch };
};

export const getDefaultResourceIcon = (activityCardItem: ActivityCardItem) => {
	return activityCardItem?.activityResource?.type === ResourceType.VIRTUAL_DESKTOP
		? ActivityDesktopSolidIcon
		: AppsIcon;
};

export const getDefaultSessionIcon = (resource: Resource) => {
	return resource?.type === ResourceType2.Desktop ? ActivityDesktopSolidIcon : AppsIcon;
};

export const isDisconnectedButNotSuspended = (activityCardItem: ActivityCardItem) => {
	return (
		activityCardItem.activityResource?.state === ResourceState.DISCONNECTED &&
		activityCardItem.activityResource?.machineData?.powerState !== PowerState.SUSPENDED
	);
};
