import { PlatformDependencies } from '@citrite/workspace-ui-platform';
import { MachineData } from 'App/Activity/CommonResourceUtils';
import { PowerState, ResponseData } from 'App/Activity/Network/ActionsUtil';
import {
	isHibernationAndResumeSupported,
	isOperationPowerManagment,
	isOperationResume,
	isPowerOffRestartShutDownSupported,
	reportError,
} from 'App/Activity/Utility';
import { FeatureFlag } from 'Environment/FeatureFlag';
import { container } from 'Workspace/DependencyManagement';
export interface DropDownActionItem {
	item: string;
	displayName: string;
	action: (dropDownActionItem: DropDownActionItem) => Promise<ResponseData>;
	sessionId: string;
	resourceId: string;
	endpoint?: string;
	machineData?: MachineData;
	response?: ResponseData;
}

export const createSupportedOperations = (
	sessionId: string,
	resourceId: string,
	machineData: MachineData,
	supportedOperationsList: string[],
	getOperationDisplayName: (opName: string) => string,
	getSupportedOperation: (
		name: string
	) => (dropDownActionItem: DropDownActionItem) => Promise<ResponseData>,
	isDesktopSession = false
): DropDownActionItem[] => {
	const supportedOperations: DropDownActionItem[] = [];
	const isHibernationEnabled = container
		.resolve(PlatformDependencies.WorkspaceConfiguration)
		.hasFeatureCanary(FeatureFlag.HibernateAndResume);
	supportedOperationsList.forEach((opName: string) => {
		const isPowerManagement = isOperationPowerManagment(opName);
		const isPowerOffRestartShutDownOperationSupported =
			isPowerManagement &&
			isPowerOffRestartShutDownSupported(opName, machineData, isDesktopSession);
		const isHibernationSupported =
			isHibernationEnabled && isHibernationAndResumeSupported(opName, machineData);
		let operation = null;
		if (operationCheckRequired(machineData, opName)) {
			operation =
				!isPowerManagement ||
				isPowerOffRestartShutDownOperationSupported ||
				isHibernationSupported
					? getSupportedOperation(opName)
					: null;
		}
		operation
			? supportedOperations.push({
					item: opName,
					displayName: getOperationDisplayName(opName),
					action: getSupportedOperation(opName),
					sessionId,
					resourceId,
					machineData,
			  })
			: reportError(
					'Activity Resource Sanitizaton Failed',
					'Operation Not Supported : ' + opName
			  );
	});
	return supportedOperations;
};

const operationCheckRequired = (machineData: MachineData, opName: string) => {
	return (
		(machineData?.powerState === PowerState.SUSPENDED && isOperationResume(opName)) ||
		machineData?.powerState !== PowerState.SUSPENDED
	);
};
