import { post } from '@citrite/http';
import { WorkspaceConfiguration } from '@citrite/workspace-ui-platform';
import { trackAnalyticsEvent } from 'analytics';
import { ResourceState } from 'App/Activity/CommonResourceUtils';
import { DropDownActionItem } from 'App/Activity/DropDownUtils';
import {
	checkConnectionState,
	contentTypeJson,
	SessionOperationRequestData,
	SessionOperationResponse,
} from 'App/Activity/Network/ActionsUtil';
import { activityMangerCASReporter } from 'Workspace/TelemetryEvents/activityManager/createActivityManagerCASReporter';

export const disconnect = (
	dropDownActionItem: DropDownActionItem
): Promise<SessionOperationResponse> => {
	trackAnalyticsEvent(activityMangerCASReporter.getActivityManagerDisconnectEvent(true));
	const body: string = JSON.stringify(
		new SessionOperationRequestData([dropDownActionItem.sessionId])
	);
	return post<SessionOperationResponse>(
		dropDownActionItem.endpoint,
		body,
		contentTypeJson
	);
};

export const checkDisconnectStatus = async (
	workspaceConfig: WorkspaceConfiguration,
	sessionIdToCheck: string
) => {
	return checkConnectionState(
		workspaceConfig,
		sessionIdToCheck,
		ResourceState.DISCONNECTED
	);
};
