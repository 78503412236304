import * as React from 'react';
import { t } from '@citrite/translate';
import { fromThemeColors, layout, typography } from '@citrite/web-ui-component';
import styled from '@emotion/styled';
import { ActivityCardItem } from 'App/Activity/ActivityCard/ActivityCard';
import { ActivityDisconnectedIcon } from 'App/Activity/ActivityDisconnectedIcon/ActivityDisconnectedIcon';
import { ActivityTitle } from 'App/Activity/ActivityTitle/ActivityTitle';
import { ResourceState } from 'App/Activity/CommonResourceUtils';
import { useFormFactor } from 'App/Header/useFormFactor';
import { ActivityMenuIcon } from '../ActivityMenuIcon/ActivityMenuIcon';

const StyledActivityCardHeaderContainer = styled.div`
	display: flex;
	justify-content: space-between;
	flex-direction: row;
	flex-wrap: nowrap;
	align-items: center;
	position: relative;
`;

const StyledTitle = styled(ActivityTitle)<{ isSmallFormFactor: boolean }>`
	font-size: ${typography.sizes.sml};
	font-weight: ${typography.weights.semiBold};
	width: 90%;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: pre;
	color: ${fromThemeColors('primaryText')};
	padding: ${layout.smallSpace} 0px;
	${({ isSmallFormFactor }) =>
		isSmallFormFactor &&
		`
		width: 80%;
		padding: ${layout.mediumSpace} 0px;
	`}
`;

const StyledActivityBodySubContent = styled.div`
	display: flex;
	align-items: center;
`;
export interface ActivityCardHeaderProps {
	resourceItem?: ActivityCardItem;
}

export const ActivityCardHeader = (props: ActivityCardHeaderProps) => {
	const { isSmallFormFactor } = useFormFactor();

	const getTitle = () =>
		props.resourceItem
			? `${t('Workspace:applications')} (${props.resourceItem.activityResource.name})`
			: `${t('Workspace:desktops')}`;
	return (
		<StyledActivityCardHeaderContainer
			aria-label={
				t('Workspace:activity_manager.activity') +
				' ' +
				props.resourceItem?.activityResource.name
			}
		>
			<StyledTitle title={getTitle()} isSmallFormFactor={isSmallFormFactor} />
			<StyledActivityBodySubContent>
				{props.resourceItem?.activityResource.state === ResourceState.DISCONNECTED && (
					<ActivityDisconnectedIcon />
				)}
				{props.resourceItem?.dropDownActionItems?.length > 0 && (
					<ActivityMenuIcon
						resourceItem={props.resourceItem}
						dropDownActionItem={props.resourceItem.dropDownActionItems}
					/>
				)}
			</StyledActivityBodySubContent>
		</StyledActivityCardHeaderContainer>
	);
};
