import { ActivityFeedIcon } from '@citrite/web-ui-component';
import {
	hasFeatureCanary,
	LeftNavCapability,
	LeftNavPositions,
	/* eslint-disable @citrite/workspace-ui/no-platform-dependency-access-under-src */
	t,
	workspaceConfiguration,
	workspaceUser,
} from '@citrite/workspace-ui-platform';
import { makeAsync } from '@citrite/workspace-ui-platform-react';
import { isNativeMobileClient } from 'Environment/launchResource/device';
import { FeatureFlag } from 'userNotifications/FeatureFlag';

export const feedRoutePath = '/feed';

const BadgeIcon = makeAsync(
	() =>
		import(
			/* webpackChunkName: "FeedPage" */ 'App/NotificationHome/UserNotificationBadge'
		).then(({ UserNotificationBadge }) => UserNotificationBadge),
	null
);
export function createFeedPageLink(): LeftNavCapability {
	return {
		isVisible: () =>
			!isNativeMobileClient() &&
			workspaceUser.get().isIWSEnabled &&
			hasFeatureCanary(workspaceConfiguration.get(), FeatureFlag.FeedInLeftNav),
		weight: LeftNavPositions.feed,
		icon: ActivityFeedIcon,
		mobileNativeActiveIcon: ActivityFeedIcon,
		title: () => t('Workspace:feed_page_title'),
		path: feedRoutePath,
		performanceMark: 'FeedLink_Click',
		secondaryIcon: BadgeIcon as React.FC<React.AllHTMLAttributes<HTMLSpanElement>>,
	};
}
