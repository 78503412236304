import { t } from '@citrite/translate';
import { media } from '@citrite/web-ui-component';
import { maximumResourceCountForSimpleView } from 'App/Navigation/utils';
import { auto, maximumResourcesForShortcut, recommended } from 'Components/FTU/constants';
import { environment } from 'Environment';
import { addResourceAndUpdateStore } from 'Environment/addResource';
import { isFavorite } from 'Environment/favorites';
import { isMobileOrTabletDevice } from 'Environment/launchResource/device';
import { BucketManifest, useCacheContext } from 'Workspace/Cache';
import { ResourceContext } from 'Workspace/ResourceProvider';
import {
	isCVADApp,
	isDesktop,
	isSaasApp,
	Resource,
	subscriptionStatus,
} from 'Workspace/ResourceProvider/resourceTypes';

export function shouldDisplayFTUBasedOnResourceAttributes(
	resources: Resource[],
	subscriptionsEnabled: boolean,
	screenSize: media
) {
	return (
		shouldShowPersonalizationScreen(resources, subscriptionsEnabled) ||
		shouldShowShortcutScreen(resources, subscriptionsEnabled, screenSize)
	);
}
// this export is required for unit tests
// allow-unused-export
export function shouldShowPersonalizationScreen(
	resources: Resource[],
	subscriptionsEnabled: boolean
): boolean {
	return (
		resources?.length > maximumResourceCountForSimpleView &&
		subscriptionsEnabled &&
		!isAnyResourceManuallySubscribed(resources)
	);
}
/* 
if resources <= 5 then shortcut ftu screen is displayed only in native with supportsShortcuts capability
Also, screensize check is required to avoid showing shortcut screen when user resizes CWA app
*/

// this export is required for unit tests
// allow-unused-export

export function shouldShowShortcutScreen(
	resources: Resource[],
	subscriptionsEnabled: boolean,
	screenSize: media
) {
	return (
		environment.supportsShortcuts &&
		resources?.length <= maximumResourcesForShortcut &&
		subscriptionsEnabled &&
		environment.isNative &&
		!isMobileOrTabletDevice() &&
		screenSize > media.medium
	);
}

/* resource is considered as manually subscribed
 a) if it is not mandatory
 b) if it is not auto saas app
 c) if is marked by user as favorite
 */

export function isAnyResourceManuallySubscribed(resources: Resource[]) {
	return resources?.some(
		resource =>
			!isMandatoryResource(resource) &&
			!isAutoSaaSResource(resource) &&
			isFavorite(resource)
	);
}

export function isMandatoryResource(resource: Resource) {
	return resource?.mandatory;
}

export function isAutoSaaSResource(resource: Resource) {
	return resource?.keywords?.some(keyword => keyword.toLowerCase() === auto);
}

export function isRecommendedCVADResource(resource: Resource) {
	return (
		resource?.recommended ||
		resource?.keywords?.some(keyword => keyword.toLowerCase() === recommended)
	);
}

/* resource is displayed in recommended
 a) if it is mandatory resource or
 b) auto SaaS resource or
 c) recommended CVAD resource
 */

export function isRecommendedFTUResource(resource: Resource) {
	return (
		isMandatoryResource(resource) ||
		isAutoSaaSResource(resource) ||
		isRecommendedCVADResource(resource)
	);
}

export function countRecommendedResources(resourceList: Resource[]) {
	return resourceList?.filter(resource => isRecommendedFTUResource(resource)).length;
}

export function useFTUShownCache() {
	return useCacheContext().cacheFactory.createUnencryptedCacheBucket(BucketManifest.ftu, {
		perUser: true,
	});
}

export const getResourceType = (resource: Resource) => {
	if (isSaasApp(resource)) {
		return t('Workspace:ftu_ui.web_app');
	} else if (isCVADApp(resource)) {
		return t('Workspace:ftu_ui.desktop_app');
	} else if (isDesktop(resource)) {
		return t('Workspace:ftu_ui.desktop');
	}
	return '';
};

export const subscribeSelectedItems = async (
	resourceContext: ResourceContext,
	items: Resource[]
) => {
	return await Promise.all(
		items
			.filter(item => item.subscriptionstatus !== subscriptionStatus.subscribed)
			.map(item =>
				addResourceAndUpdateStore({
					...item,
					resourceContext,
				})
			)
	);
};
