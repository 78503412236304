import * as React from 'react';
import { isVanityDomain } from '@citrite/workspace-ui-platform';
import { environment } from 'Environment/Environment';
import { isNativeWidget } from 'Environment/launchResource/device';
import { MetadataFixInitOptions } from 'javascript/pendo';
import { getLanguageStringForPendo } from 'javascript/sf/detectLanguage';
import { useUserContext } from 'Workspace/UserContext/Consumer';

export function useAnalyticsInitialization() {
	const { userDetails, isIWSEnabled, error, isDaasVisionEnabled } = useUserContext();
	const [, setPendoOptions] = React.useState<MetadataFixInitOptions>();
	const isInitialized = React.useRef<boolean>(false);
	const isOnVanityDomain = isVanityDomain();

	React.useEffect(() => {
		if (!window.pendo || isInitialized.current || error) {
			return;
		}

		const options = {
			visitor: {
				id: userDetails.userId,
				isNative: environment.isNative,
				nativePlatform: environment.nativePlatform,
				ws_iwsEnabled: isIWSEnabled,
				ws_isNativeWidget: isNativeWidget(),
				ws_storeFrontToWorkspaceMigrationGuideEnabled:
					environment.showStoreFrontToWorkspaceMigrationGuideInfo,
				ws_cwaBuildVersion: environment.cwaBuildVersion,
				ws_onVanityDomain: isOnVanityDomain,

				ws_appName: environment.nativeCapabilities?.platform?.appname,
				ws_appVersion: environment.nativeCapabilities?.platform?.appversion,
				ws_deviceType: environment.nativeCapabilities?.platform?.devicetype,
				ws_packageBundleId: environment.nativeCapabilities?.platform?.packagebundleid,
				ws_screenSize: environment.nativeCapabilities?.platform?.screensize,
				ws_osVersion: environment.nativeCapabilities?.platform?.osversion,
				ws_appReleaseType: environment.nativeCapabilities?.platform?.appreleasetype,
				ws_language: getLanguageStringForPendo(),
				ws_daasUIEnabled: isDaasVisionEnabled,
			},
			account: {
				id: userDetails.orgId,
				cc_paidEntitlementCount: userDetails.productionCloudServicesCount,
				cc_paidEntitlements: userDetails.productionCloudServicesList,
				cc_trialEntitlementCount: userDetails.productionTrialCloudServicesCount,
				cc_trialEntitlements: userDetails.productionTrialCloudServicesList,
				cc_accountType: userDetails.accountType,
				cc_isPartner: userDetails.isPartner,
				cc_cloudServices: userDetails.cloudServices,
			},
		};

		window.pendo.initialize(options);
		setPendoOptions(options);
		isInitialized.current = true;
	}, [
		userDetails,
		isIWSEnabled,
		error,
		isInitialized,
		isOnVanityDomain,
		isDaasVisionEnabled,
	]);

	React.useLayoutEffect(() => {
		if (window.pendo && isDaasVisionEnabled && isInitialized.current) {
			setPendoOptions(pendoOptions => {
				const options = {
					...pendoOptions,
					visitor: { ...pendoOptions.visitor, ws_daasUIEnabled: isDaasVisionEnabled },
				};
				window.pendo.updateOptions(options);
				return options;
			});
		}
	}, [isDaasVisionEnabled, isInitialized]);
}
