import { post } from '@citrite/http';
import { WorkspaceConfiguration } from '@citrite/workspace-ui-platform';
import { trackAnalyticsEvent } from 'analytics';
import { DropDownActionItem } from 'App/Activity/DropDownUtils';
import {
	contentTypeJson,
	SessionOperationRequestData,
	SessionOperationResponse,
} from 'App/Activity/Network/ActionsUtil';
import { getRemoteSessionsList } from 'App/Activity/Network/SessionList';
import { activityMangerCASReporter } from 'Workspace/TelemetryEvents/activityManager/createActivityManagerCASReporter';

export const logout = (
	dropDownActionItem: DropDownActionItem
): Promise<SessionOperationResponse> => {
	trackAnalyticsEvent(activityMangerCASReporter.getActivityManagerLogoutEvent(true));
	const body: string = JSON.stringify(
		new SessionOperationRequestData([dropDownActionItem.sessionId])
	);
	return post<SessionOperationResponse>(
		dropDownActionItem.endpoint,
		body,
		contentTypeJson
	);
};

export const checkLogOffStatus = async (
	workspaceConfig: WorkspaceConfiguration,
	loggedOffSessionId: string
) => {
	let sessionLoggedOff = false;
	const resources = await getRemoteSessionsList(workspaceConfig);
	sessionLoggedOff =
		resources.sessions?.filter(session => session?.sessionId === loggedOffSessionId)
			?.length === 0;
	return sessionLoggedOff;
};
