import React, { useState } from 'react';
import { t } from '@citrite/translate';
import {
	breakpoints,
	LoadingOverlaySecondary,
	notifyError,
	palette,
} from '@citrite/web-ui-component';
import { UnencryptedCacheBucket } from '@citrite/workspace-ui-platform';
import styled from '@emotion/styled';
import { trackAnalyticsEvent } from 'analytics';
import { logError } from 'remoteLogging';
import { Action } from 'Components/FTU/Action';
import { cacheKey } from 'Components/FTU/constants';
import { Header } from 'Components/FTU/Header';
import { PersonalizationContent } from 'Components/FTU/PersonalizationContent';
import {
	isAutoSaaSResource,
	isRecommendedCVADResource,
	isRecommendedFTUResource,
	subscribeSelectedItems,
	useFTUShownCache,
} from 'Components/FTU/Utils';
import { refreshAllResources } from 'Environment/callbacks';
import { removeResourceAndUpdateStore } from 'Environment/removeResource';
import { useLoadableResourceContext } from 'Workspace/ResourceProvider';
import { Resource, subscriptionStatus } from 'Workspace/ResourceProvider/resourceTypes';
import { CustomizeUserHomeModalEventType } from 'Workspace/TelemetryEvents/TelemetryEventTypes';

const StyledPersonalizationModalBody = styled.div`
	background: ${palette.white};
	width: 640px;
	padding: 10px 0px 10px 0px;
	border-radius: 16px;
	${breakpoints.onMediumScreen} {
		margin: 0px;
		width: 100%;
		display: flex;
		height: 100%;
		gap: 2px;
		justify-content: space-between;
		flex-direction: column;
		border-radius: 0px;
	}
`;
const StyledPersonalizationContainer = styled.div`
	flex-direction: column;
	display: flex;
	width: 624px;
	gap: 8px;
	background: ${palette.white};
	border-radius: 16px;
	align-content: center;
	align-items: center;
	padding: 15px 8px 15px 8px;
	${breakpoints.onMediumScreen} {
		width: 100%;
		justify-content: space-evenly;
		padding: 15px 0px 0px 0px;
		border-radius: 0px;
		gap: 0px;
	}
`;

interface Props {
	onCloseModal: () => void;
}
export const PersonalizationContainer = (props: Props) => {
	const resourceContext = useLoadableResourceContext();
	const { resources } = resourceContext.value;
	const ftuCache = useFTUShownCache();
	const recommendedResources = resources.filter(resource =>
		isRecommendedFTUResource(resource)
	);
	const [selectedItems, setSelectedItems] = useState<Resource[]>(recommendedResources);
	const [displayProgressSpinner, setDisplayProgressSpinner] = useState(false);

	const handleFailure = () => {
		trackAnalyticsEvent({
			type: CustomizeUserHomeModalEventType.Subscription_Failure,
		});
		notifyError(t('Workspace:favorite_error_message'));
	};
	const onAddToFavorites = async () => {
		try {
			setDisplayProgressSpinner(true);
			const unselectedItems = resources.filter(
				item =>
					(isAutoSaaSResource(item) || isRecommendedCVADResource(item)) &&
					selectedItems.findIndex(obj => obj.id === item.id) === -1
			);
			await unsubscribeItems(unselectedItems);
			await subscribeSelectedItems(resourceContext.value, selectedItems);
			refreshAllResources();
			trackAnalyticsEvent({
				type: CustomizeUserHomeModalEventType.Subscription_Success,
			});
		} catch (error) {
			handleFailure();
			logError(error, { tags: { feature: 'ftu-personalization' } });
		} finally {
			setFTUState(ftuCache);
			setDisplayProgressSpinner(false);
		}
	};

	const setFTUState = (cache: UnencryptedCacheBucket) => {
		props.onCloseModal();
		cache.setUnencrypted(cacheKey, true);
	};

	const unsubscribeItems = async (items: Resource[]) => {
		return await Promise.all(
			items
				.filter(item => item.subscriptionstatus === subscriptionStatus.subscribed)
				.map(item =>
					removeResourceAndUpdateStore({
						...item,
						resourceContext: resourceContext.value,
					})
				)
		);
	};

	const navigateBack = () => {
		trackAnalyticsEvent({
			type: CustomizeUserHomeModalEventType.Skipped_Personalization,
		});
		setFTUState(ftuCache);
	};
	return (
		<StyledPersonalizationModalBody>
			<StyledPersonalizationContainer data-testid="ftu-personalization-screen">
				<Header
					primaryText={t('Workspace:ftu_ui.primary_header_text_personalization_screen')}
					secondaryText={t(
						'Workspace:ftu_ui.secondary_header_text_personalization_screen'
					)}
				/>
				<PersonalizationContent
					selectedItems={selectedItems}
					setSelectedItems={setSelectedItems}
				/>
				<Action
					primaryButtonText={t('Workspace:ftu_ui.button_text_personalization_screen')}
					onPrimaryActionClick={onAddToFavorites}
					primaryActionId="add-favourites"
					secondaryActionId="skip-favourites"
					onSecondaryActionClick={navigateBack}
				/>
				{displayProgressSpinner && <LoadingOverlaySecondary size={60} />}
			</StyledPersonalizationContainer>
		</StyledPersonalizationModalBody>
	);
};
