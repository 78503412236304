import * as React from 'react';
import { ActivityCardItem } from 'App/Activity/ActivityCard/ActivityCard';
import { getResourceFromResourceContext } from 'App/Activity/CommonResourceUtils';
import { getDefaultResourceIcon, getResoureIconData } from 'App/Activity/Utility';
import { useFormFactor } from 'App/Header/useFormFactor';
import { ResourceIcon } from 'Components/ResourceIcon';
import { useLoadableResourceContext } from 'Workspace/ResourceProvider';
import { Resource } from 'Workspace/ResourceProvider/resourceTypes';

const smallFormIconSize = 24;
const largeFormIconSize = 16;

export interface ActivityResourceIconProps {
	activityCardItem: ActivityCardItem;
}

export const ActivityResourceIcon = (props: ActivityResourceIconProps) => {
	const activityCardItem = props.activityCardItem;
	const { value: resourceContext } = useLoadableResourceContext();
	const resource: Resource = getResourceFromResourceContext(
		activityCardItem?.activityResource.resourceId,
		resourceContext.resources
	);
	const { isSmallFormFactor } = useFormFactor();
	const iconSize = isSmallFormFactor ? smallFormIconSize : largeFormIconSize;
	return resource ? (
		<ResourceIcon
			resource={resource}
			DefaultIcon={getDefaultResourceIcon(activityCardItem)}
			size={iconSize}
		/>
	) : (
		<ResourceIcon
			resourceIconUrl={getResoureIconData(activityCardItem).resourceIconUrl}
			shouldForceSkipNativeFetch={
				getResoureIconData(activityCardItem).shouldForceSkipNativeFetch
			}
			DefaultIcon={getDefaultResourceIcon(activityCardItem)}
			size={iconSize}
		/>
	);
};
