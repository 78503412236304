import { trackAnalyticsEvent } from 'analytics';
import { ActionStatus, MachineOperationResponse } from 'App/Activity/Network/ActionsUtil';
import { activityMangerCASReporter } from 'Workspace/TelemetryEvents/activityManager/createActivityManagerCASReporter';

export const restart = (): Promise<MachineOperationResponse> => {
	trackAnalyticsEvent(activityMangerCASReporter.getActivityManagerRestartEvent(true));
	const machineResponse: MachineOperationResponse = {
		continuationId: '',
		actionStatus: ActionStatus.PENDING,
		isSuccess: null,
	};
	return Promise.resolve(machineResponse);
};
