import * as React from 'react';
import { fromThemeColors, layout, typography } from '@citrite/web-ui-component';
import styled from '@emotion/styled';
import { ActivityCardItem } from 'App/Activity/ActivityCard/ActivityCard';
import { ActivityDisconnectedIcon } from 'App/Activity/ActivityDisconnectedIcon/ActivityDisconnectedIcon';
import { ActivityMenuIcon } from 'App/Activity/ActivityMenuIcon/ActivityMenuIcon';
import { ActivityResourceIcon } from 'App/Activity/ActivityResourceIcon/ActivityResourceIcon';
import { ActivityTitle } from 'App/Activity/ActivityTitle/ActivityTitle';
import { isDisconnectedButNotSuspended } from 'App/Activity/Utility';

const StyledActivityBodyContent = styled.div`
	display: flex;
	flex-direction: row;
	padding: ${layout.smallSpace} 0 ${layout.smallSpace} ${layout.smallSpace};
	justify-content: space-between;
	position: relative;
	align-items: center;
`;

const StyledTitle = styled(ActivityTitle)`
	flex-wrap: wrap;
	font-size: ${typography.sizes.sml};
	font-weight: ${typography.weights.regular};
	color: ${fromThemeColors('primaryText')};
	margin-left: ${layout.smallSpace};
	align-content: center;
	white-space: nowrap;
	display: inline;
	overflow: hidden;
	text-overflow: ellipsis;
`;

const StyledActivityBodySubContent = styled.div`
	display: flex;
	align-items: center;
`;

export interface ActivityCardBodyProps {
	resourceItem: ActivityCardItem;
}

export const ActivityCardBody = (props: ActivityCardBodyProps) => {
	const activityCardItem = props.resourceItem;
	return (
		activityCardItem && (
			<StyledActivityBodyContent>
				<StyledActivityBodySubContent>
					<ActivityResourceIcon activityCardItem={activityCardItem} />
					<StyledTitle {...props} title={activityCardItem.activityResource.name} />
				</StyledActivityBodySubContent>
				<StyledActivityBodySubContent>
					{isDisconnectedButNotSuspended(activityCardItem) && (
						<ActivityDisconnectedIcon />
					)}
					{activityCardItem.dropDownActionItems?.length > 0 && (
						<ActivityMenuIcon
							resourceItem={props.resourceItem}
							dropDownActionItem={props.resourceItem.dropDownActionItems}
						></ActivityMenuIcon>
					)}
				</StyledActivityBodySubContent>
			</StyledActivityBodyContent>
		)
	);
};
