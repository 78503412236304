import React from 'react';
import { fromThemeColors, layout, typography } from '@citrite/web-ui-component';
import styled from '@emotion/styled';
import { ActivityCard, ActivityCardItem } from 'App/Activity/ActivityCard/ActivityCard';
import { ActivityCardHeader } from 'App/Activity/ActivityCardHeader/ActivityCardHeader';
import { ActivityTitle } from 'App/Activity/ActivityTitle/ActivityTitle';
import {
	filterApps,
	filterDesktops,
	filterSessions,
} from 'App/Activity/CommonResourceUtils';

export interface ActivitySectionProps {
	activitySectionItem: ActivitySectionItem;
}

class ActivitySectionItem {
	public sectionItemName: string;
	public sessions: Map<ActivityCardItem, ActivityCardItem[]>;
}

const StyledTitle = styled(ActivityTitle)`
	display: flex;
	font-size: ${typography.sizes.xsml};
	font-weight: ${typography.weights.semiBold};
	color: ${fromThemeColors('metadataText')};
	margin-top: ${layout.mediumSpace};
	margin-bottom: ${layout.smallSpace};
`;

export const ActivitySection = (props: ActivitySectionProps) => {
	const activityCardDesktopItems = filterDesktops(props?.activitySectionItem?.sessions);
	const activityCardSessionItems = filterSessions(props?.activitySectionItem?.sessions);
	const validSection =
		activityCardDesktopItems?.length > 0 || activityCardSessionItems?.length > 0;
	return (
		validSection && (
			<>
				<StyledTitle title={props.activitySectionItem.sectionItemName} />
				{activityCardDesktopItems?.length > 0 && [
					<ActivityCardHeader key="desktops-header" />,
					...activityCardDesktopItems.map((desktopItem: ActivityCardItem) => {
						return (
							<ActivityCard
								key={desktopItem.activityResource.sessionId}
								bodyItems={[desktopItem]}
							></ActivityCard>
						);
					}),
				]}
				{activityCardSessionItems?.length > 0 &&
					activityCardSessionItems.map((sessionItem: ActivityCardItem) => {
						return (
							<ActivityCard
								key={sessionItem.activityResource.sessionId}
								headerItem={sessionItem}
								bodyItems={filterApps(
									sessionItem.activityResource.sessionId,
									props.activitySectionItem.sessions
								)}
							></ActivityCard>
						);
					})}
			</>
		)
	);
};
