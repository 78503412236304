import { t } from '@citrite/translate';
import { WorkspaceConfiguration } from '@citrite/workspace-ui-platform';
import {
	getResourceStateFromOperation,
	ResourceState,
} from 'App/Activity/CommonResourceUtils';
import { DropDownActionItem } from 'App/Activity/DropDownUtils';
import { checkDisconnectStatus, disconnect } from 'App/Activity/Network/DisconnectAction';
import { checkLogOffStatus, logout } from 'App/Activity/Network/LogOutAction';
import {
	checkPowerManagementStatus,
	powerManagementOperation,
} from 'App/Activity/Network/PowerManagementAction';
import { restart } from 'App/Activity/Network/RestartAction';
import { resume } from 'App/Activity/Network/ResumeAction';
import { getRemoteSessionsList, Session } from 'App/Activity/Network/SessionList';
import { activityMangerCASReporter } from 'Workspace/TelemetryEvents/activityManager/createActivityManagerCASReporter';

export interface SessionOperationResponse {
	results: SessionOperationResult[];
}

export interface SessionOperationResult {
	sessionId: string;
	isSuccess: boolean;
	failureReason?: string;
}

export interface MachineOperationResponse {
	continuationId: string;
	retryDelayHint?: string;
	actionStatus: ActionStatus;
	isSuccess: boolean;
	errorId?: string;
	errorValue?: string;
}
export interface LaunchOperationResponse {
	isSuccess: boolean;
}
export interface ResponseData {
	continuationId?: string;
	retryDelayHint?: string;
	actionStatus?: ActionStatus;
	isSuccess?: boolean;
	errorId?: string;
	errorValue?: string;
	results?: SessionOperationResult[];
}
export class SessionOperationRequestData {
	public SessionIds: string[];
	public constructor(resourceIds: string[]) {
		this.SessionIds = resourceIds;
	}
}

export enum ActionStatus {
	PENDING = 'Pending',
	STARTED = 'Started',
	COMPLETED = 'Completed',
	FAILED = 'Failed',
}

export enum RemoteOperationType {
	LOGOFF = 'logoff',
	DISCONNECT = 'disconnect',
	RESTART = 'restart',
	POWEROFF = 'poweroff',
	SHUTDOWN = 'shutdown',
	HIBERNATE = 'hibernate',
	RESUME = 'resume',
	NOT_SUPPORTED = 'not supported',
}

export enum SessionSupport {
	SINGLE_SESSION = 'SingleSession',
	MULTI_SESSION = 'MultiSession',
	UNKNOWN = 'Unknown',
}

export enum PowerState {
	UNMANAGED = 'Unmanaged',
	UNKNOWN = 'Unknown',
	UNAVAILABLE = 'Unavailable',
	OFF = 'Off',
	ON = 'On',
	SUSPENDED = 'Suspended',
	TURNING_ON = 'TurningOn',
	TURNING_OFF = 'TurningOff',
	SUSPENDING = 'Suspending',
	RESUMING = 'Resuming',
	NOT_SUPPORTED = 'NotSupported',
	VIRTUAL_MACHINE_NOT_FOUND = 'VirtualMachineNotFound',
}

export const contentTypeJson = {
	headers: {
		'Content-Type': 'application/json',
	},
};

export const supportedOperations = [
	RemoteOperationType.DISCONNECT,
	RemoteOperationType.LOGOFF,
	RemoteOperationType.HIBERNATE,
	RemoteOperationType.SHUTDOWN,
	RemoteOperationType.RESTART,
	RemoteOperationType.POWEROFF,
	RemoteOperationType.RESUME,
];

const actionFailedError = new Error('Error while performing action');

export const checkConnectionState = async (
	workspaceConfig: WorkspaceConfiguration,
	sessionIdToCheck: string,
	stateToCheck: string
) => {
	let status = false;
	const resources = await getRemoteSessionsList(workspaceConfig);
	const targetSession: Session = resources.sessions?.find(
		session =>
			session?.sessionId === sessionIdToCheck && session?.connectionState === stateToCheck
	);
	status = targetSession !== undefined && targetSession !== null;
	return status;
};

export const checkOperationStatus = async (
	workspaceConfig: WorkspaceConfiguration,
	dropDownItem: DropDownActionItem,
	response: ResponseData
) => {
	let result = false;
	if (dropDownItem.item === RemoteOperationType.LOGOFF) {
		result = await checkLogOffStatus(workspaceConfig, dropDownItem.sessionId);
	} else if (dropDownItem.item === RemoteOperationType.DISCONNECT) {
		result = await checkDisconnectStatus(workspaceConfig, dropDownItem.sessionId);
	} else if (
		dropDownItem.item === RemoteOperationType.POWEROFF ||
		dropDownItem.item === RemoteOperationType.SHUTDOWN ||
		dropDownItem.item === RemoteOperationType.HIBERNATE
	) {
		result = await checkPowerManagementStatus(dropDownItem.endpoint, response);
	}
	if (!result) {
		throw actionFailedError;
	}
	return result;
};

const createRemoteOperationObject = (
	operationName: () => string,
	endpointUrl: (workspaceConfiguration: WorkspaceConfiguration) => string
) => {
	return {
		name: operationName,
		endpoint: endpointUrl,
	};
};

const remoteOperationDetails: Record<
	RemoteOperationType,
	{
		name: () => string;
		endpoint: (workspaceConfiguration: WorkspaceConfiguration) => string;
	}
> = {
	[RemoteOperationType.DISCONNECT]: createRemoteOperationObject(
		() => t('Workspace:activity_manager.disconnect'),
		workspaceConfiguration =>
			workspaceConfiguration.storeProxy.sessionControlProxy?.disconnectSessionsURL
	),
	[RemoteOperationType.LOGOFF]: createRemoteOperationObject(
		() => t('Workspace:activity_manager.log_out'),
		workspaceConfiguration =>
			workspaceConfiguration.storeProxy.sessionControlProxy?.logoffSessionsURL
	),
	[RemoteOperationType.POWEROFF]: createRemoteOperationObject(
		() => t('Workspace:activity_manager.poweroff'),
		workspaceConfiguration =>
			workspaceConfiguration.storeProxy.machinesProxy?.powerOffMachineURL
	),
	[RemoteOperationType.SHUTDOWN]: createRemoteOperationObject(
		() => t('Workspace:activity_manager.shutdown'),
		workspaceConfiguration =>
			workspaceConfiguration.storeProxy.machinesProxy?.shutDownMachineURL
	),
	[RemoteOperationType.HIBERNATE]: createRemoteOperationObject(
		() => t('Workspace:activity_manager.hibernate'),
		workspaceConfiguration =>
			workspaceConfiguration.storeProxy.machinesProxy?.suspendMachineURL
	),
	[RemoteOperationType.RESTART]: createRemoteOperationObject(
		() => t('Workspace:activity_manager.restart'),
		_workspaceConfiguration => ''
	),
	[RemoteOperationType.RESUME]: createRemoteOperationObject(
		() => t('Workspace:activity_manager.resume'),
		_workspaceConfiguration => ''
	),
	[RemoteOperationType.NOT_SUPPORTED]: createRemoteOperationObject(
		() => null,
		_workspaceConfiguration => ''
	),
};

export const getActionEndpoint = (
	operationType: string,
	workspaceConfiguration: WorkspaceConfiguration
): string => {
	return remoteOperationDetails[operationType]?.endpoint(workspaceConfiguration) || '';
};

export const getRemoteOperationName = (operation: string): string => {
	return remoteOperationDetails[operation]?.name() || null;
};

export const getRemoteOperation = (
	operation: string
): ((dropDownActionItem: DropDownActionItem) => Promise<ResponseData>) => {
	if (operation === RemoteOperationType.LOGOFF) {
		return logout;
	} else if (operation === RemoteOperationType.DISCONNECT) {
		return disconnect;
	} else if (operation === RemoteOperationType.RESTART) {
		return restart;
	} else if (
		operation === RemoteOperationType.POWEROFF ||
		operation === RemoteOperationType.SHUTDOWN ||
		operation === RemoteOperationType.HIBERNATE
	) {
		return powerManagementOperation;
	} else if (operation === RemoteOperationType.RESUME) {
		return resume;
	} else {
		return null;
	}
};

export const checkAnalyticsEvent = (operationType: string) => {
	let event = null;
	if (operationType === RemoteOperationType.POWEROFF) {
		event = activityMangerCASReporter.getActivityManagerPoweroffEvent(true);
	} else if (operationType === RemoteOperationType.SHUTDOWN) {
		event = activityMangerCASReporter.getActivityManagerShutdownEvent(true);
	} else {
		event = activityMangerCASReporter.getActivityManagerHibernateEvent(true);
	}
	return event;
};

export const isRequestSuccessful = (
	response: ResponseData,
	dropDownItem: DropDownActionItem
): boolean => {
	const resourceState = getResourceStateFromOperation(dropDownItem.item);

	switch (resourceState) {
		case ResourceState.LOGGING_OUT:
		case ResourceState.DISCONNECTING:
			return response?.results[0].isSuccess !== false;
		case ResourceState.POWERING_OFF:
		case ResourceState.SHUTTING_DOWN:
		case ResourceState.RESTARTING:
		case ResourceState.HIBERNATING:
		case ResourceState.RESUMING:
			return response?.isSuccess !== false;
	}

	return false;
};
