import { WorkspaceConfiguration } from '@citrite/workspace-ui-platform';
import { ActivityCardItem } from 'App/Activity/ActivityCard/ActivityCard';
import { parseResourcesList } from 'App/Activity/ActivityManager/ActivityManagerUtils';
import { ActivityLoadingState } from 'App/Activity/CommonResourceUtils';
import {
	getRemoteSessionsList,
	SessionsListResponse,
} from 'App/Activity/Network/SessionList';
import {
	filterSections,
	getRemoteOptionsUpdatedLocalSessionList,
	setResourcesToSession,
} from 'App/Activity/Network/SessionNetworkToLocalMapper';
import { reportError } from 'App/Activity/Utility';
import { environment } from 'Environment';
import { isNativeClient } from 'Environment/launchResource/device';

export enum IntegrationType {
	EXTENDED = 'extended',
	SHORT = 'short',
	NO_INTEGRATION = 'no integration',
}

const isIntegrationTypeShort = (deviceId: string) => {
	return deviceId && deviceId.length > 0 && isNativeClient();
};

const isIntegrationTypeExtended = (deviceId: string) => {
	return environment.supportsActivityManager && isIntegrationTypeShort(deviceId);
};

const getIntegrationType = (deviceId: string) => {
	if (isIntegrationTypeExtended(deviceId)) {
		return IntegrationType.EXTENDED;
	} else if (isIntegrationTypeShort(deviceId)) {
		return IntegrationType.SHORT;
	} else {
		return IntegrationType.NO_INTEGRATION;
	}
};

const handleExtendedIntegration = (
	clientDeviceId: string,
	workspaceConfig: WorkspaceConfiguration,
	setLocalSession: React.Dispatch<
		React.SetStateAction<Map<ActivityCardItem, ActivityCardItem[]>>
	>,
	setRemoteSession: React.Dispatch<
		React.SetStateAction<Map<ActivityCardItem, ActivityCardItem[]>>
	>,
	setHibernateSession: React.Dispatch<
		React.SetStateAction<Map<ActivityCardItem, ActivityCardItem[]>>
	>,
	setActivityResourceLoading: React.Dispatch<React.SetStateAction<ActivityLoadingState>>
) => {
	environment
		.fetchActivityList()
		.then(allResources => {
			const localSessionsList = parseResourcesList(allResources?.resources);
			setResourcesToSession(localSessionsList, setLocalSession);
			fetchRemoteSessionsAndUpdateLocalSessionOptions(
				clientDeviceId,
				workspaceConfig,
				setLocalSession,
				setRemoteSession,
				setHibernateSession,
				setActivityResourceLoading,
				localSessionsList
			);
		})
		.catch(error => {
			reportError(
				error,
				'Local error occurred while handling extended integration case.'
			);
			fetchRemoteSessionsAndUpdateLocalSessionOptions(
				clientDeviceId,
				workspaceConfig,
				setLocalSession,
				setRemoteSession,
				setHibernateSession,
				setActivityResourceLoading,
				[]
			);
		})
		.finally(() =>
			setActivityResourceLoading(oldState => {
				return {
					isLocalResourceLoaded: true,
					isRemoteResourceLoaded: oldState.isRemoteResourceLoaded,
				};
			})
		);
};

const fetchRemoteSessionsAndUpdateLocalSessionOptions = (
	clientDeviceId: string,
	workspaceConfig: WorkspaceConfiguration,
	setLocalSession: React.Dispatch<
		React.SetStateAction<Map<ActivityCardItem, ActivityCardItem[]>>
	>,
	setRemoteSession: React.Dispatch<
		React.SetStateAction<Map<ActivityCardItem, ActivityCardItem[]>>
	>,
	setHibernateSession: React.Dispatch<
		React.SetStateAction<Map<ActivityCardItem, ActivityCardItem[]>>
	>,
	setActivityResourceLoading: React.Dispatch<React.SetStateAction<ActivityLoadingState>>,
	localSessionsList: ActivityCardItem[]
) => {
	getRemoteSessionsList(workspaceConfig)
		.then(resources => {
			const { localSession, remoteSession, hibernateSession } = filterSections(
				resources,
				workspaceConfig,
				clientDeviceId
			);
			setResourcesToSession(remoteSession, setRemoteSession);
			setResourcesToSession(hibernateSession, setHibernateSession);
			if (localSessionsList != null && localSessionsList.length > 0) {
				setResourcesToSession(
					getRemoteOptionsUpdatedLocalSessionList(localSessionsList, localSession),
					setLocalSession
				);
			}
		})
		.catch(error =>
			reportError(
				error,
				'Remote error occurred while handling extended integration case.'
			)
		)
		.finally(() =>
			setActivityResourceLoading(oldState => {
				return {
					isLocalResourceLoaded: oldState.isLocalResourceLoaded,
					isRemoteResourceLoaded: true,
				};
			})
		);
};

const getFilteredSessions = (
	resources: SessionsListResponse,
	workspaceConfig: WorkspaceConfiguration,
	clientDeviceId: string,
	setLocalSession: React.Dispatch<
		React.SetStateAction<Map<ActivityCardItem, ActivityCardItem[]>>
	>,
	setRemoteSession: React.Dispatch<
		React.SetStateAction<Map<ActivityCardItem, ActivityCardItem[]>>
	>,
	setHibernateSession: React.Dispatch<
		React.SetStateAction<Map<ActivityCardItem, ActivityCardItem[]>>
	>
) => {
	const { localSession, remoteSession, hibernateSession } = filterSections(
		resources,
		workspaceConfig,
		clientDeviceId
	);
	setResourcesToSession(localSession, setLocalSession);
	setResourcesToSession(remoteSession, setRemoteSession);
	setResourcesToSession(hibernateSession, setHibernateSession);
};

const handleShortIntegration = (
	clientDeviceId: string,
	workspaceConfig: WorkspaceConfiguration,
	setLocalSession: React.Dispatch<
		React.SetStateAction<Map<ActivityCardItem, ActivityCardItem[]>>
	>,
	setRemoteSession: React.Dispatch<
		React.SetStateAction<Map<ActivityCardItem, ActivityCardItem[]>>
	>,
	setHibernateSession: React.Dispatch<
		React.SetStateAction<Map<ActivityCardItem, ActivityCardItem[]>>
	>,
	setActivityResourceLoading: React.Dispatch<React.SetStateAction<ActivityLoadingState>>
) => {
	getRemoteSessionsList(workspaceConfig)
		.then(resources => {
			getFilteredSessions(
				resources,
				workspaceConfig,
				clientDeviceId,
				setLocalSession,
				setRemoteSession,
				setHibernateSession
			);
		})
		.catch(error =>
			reportError(error, 'Remote Error occurred while handling short integration case.')
		)
		.finally(() =>
			setActivityResourceLoading({
				isLocalResourceLoaded: true,
				isRemoteResourceLoaded: true,
			})
		);
};

const handleNoIntegration = (
	workspaceConfig: WorkspaceConfiguration,
	setLocalSession: React.Dispatch<
		React.SetStateAction<Map<ActivityCardItem, ActivityCardItem[]>>
	>,
	setRemoteSession: React.Dispatch<
		React.SetStateAction<Map<ActivityCardItem, ActivityCardItem[]>>
	>,
	setHibernateSession: React.Dispatch<
		React.SetStateAction<Map<ActivityCardItem, ActivityCardItem[]>>
	>,
	setActivityResourceLoading: React.Dispatch<React.SetStateAction<ActivityLoadingState>>
) => {
	getRemoteSessionsList(workspaceConfig)
		.then(resources => {
			const webDeviceId = resources.deviceId;
			getFilteredSessions(
				resources,
				workspaceConfig,
				webDeviceId,
				setLocalSession,
				setRemoteSession,
				setHibernateSession
			);
		})
		.catch(error =>
			reportError(error, 'Remote error occurred while handling no integration case.')
		)
		.finally(() =>
			setActivityResourceLoading({
				isLocalResourceLoaded: true,
				isRemoteResourceLoaded: true,
			})
		);
};

export const handleIntegrations = (
	clientDeviceId: string,
	workspaceConfig: WorkspaceConfiguration,
	setLocalSession: React.Dispatch<
		React.SetStateAction<Map<ActivityCardItem, ActivityCardItem[]>>
	>,
	setRemoteSession: React.Dispatch<
		React.SetStateAction<Map<ActivityCardItem, ActivityCardItem[]>>
	>,
	setHibernateSession: React.Dispatch<
		React.SetStateAction<Map<ActivityCardItem, ActivityCardItem[]>>
	>,
	setActivityResourceLoading: React.Dispatch<React.SetStateAction<ActivityLoadingState>>
) => {
	const integrationType: IntegrationType = getIntegrationType(clientDeviceId);
	if (integrationType === IntegrationType.EXTENDED) {
		handleExtendedIntegration(
			clientDeviceId,
			workspaceConfig,
			setLocalSession,
			setRemoteSession,
			setHibernateSession,
			setActivityResourceLoading
		);
	} else if (integrationType === IntegrationType.SHORT) {
		handleShortIntegration(
			clientDeviceId,
			workspaceConfig,
			setLocalSession,
			setRemoteSession,
			setHibernateSession,
			setActivityResourceLoading
		);
	} else {
		handleNoIntegration(
			workspaceConfig,
			setLocalSession,
			setRemoteSession,
			setHibernateSession,
			setActivityResourceLoading
		);
	}
};
