import * as React from 'react';
import { t } from '@citrite/translate';
import { fromThemeColors, layout, palette } from '@citrite/web-ui-component';
import { CallbackPlacement, EmptyState } from '@citrite/workspace-ui-platform-react';
import styled from '@emotion/styled';
import { useActivityManagerContext } from 'App/Activity/ActivityManagerContext';
import { ActivitySection } from 'App/Activity/ActivitySection/ActivitySection';
import { useFormFactor } from 'App/Header/useFormFactor';
import { FeatureFlag } from 'Environment/FeatureFlag';
import { useFeatureCanary } from 'utils/useFeatureCanary';

const StyledHorizontalLine = styled.hr`
	margin: ${layout.mediumSpace} 4px ${layout.smallSpace} 0;
	border-top: 1px solid ${palette.grey400};
	border-bottom: 0px;
`;

const StyledDivEmpty = styled.div<{ isSmallFormFactor: boolean }>`
	display: flex;
	flex-direction: column;
	margin: auto ${layout.mediumSpace};
	${({ isSmallFormFactor }) =>
		isSmallFormFactor &&
		`
		margin: ${layout.largeSpace} ${layout.mediumSpace};
	`}
`;

const StyledDivContent = styled.div<{ isLargeFormFactor: boolean }>`
	display: flex;
	width: auto;
	flex-direction: column;
	background-color: ${fromThemeColors('primaryContainerBackground')};
	overflow-y: auto;
	padding-right: ${layout.smallSpace};
	${({ isLargeFormFactor }) =>
		isLargeFormFactor &&
		`
		&::-webkit-scrollbar {
			width: ${layout.tinySpace};
		}
		&::-webkit-scrollbar-thumb {
			width: ${layout.tinySpace};
			background: darkgray;
			border-radius: ${layout.tinySpace};
		}
	`}
`;
const imageUrl = require('images/empty-line-art.svg');

export const ActivitySectionsList = () => {
	const { localRunningSessions, remoteRunningSessions, hibernatedSessions, refresh } =
		useActivityManagerContext();
	const { isSmallFormFactor, isLargeFormFactor } = useFormFactor();

	let shouldShowAppsAndDesktop = false;
	if (
		localRunningSessions?.size > 0 ||
		remoteRunningSessions?.size > 0 ||
		hibernatedSessions?.size > 0
	) {
		shouldShowAppsAndDesktop = true;
	}

	const activitySections = {
		localSection: {
			sectionItemName: t('Workspace:activity_manager.on_this_device'),
			sessions: localRunningSessions,
		},
		remoteSection: {
			sectionItemName: t('Workspace:activity_manager.running_remotely'),
			sessions: remoteRunningSessions,
		},
		hibernationSection: {
			sectionItemName: t('Workspace:activity_manager.in_hibernation'),
			sessions: hibernatedSessions,
		},
	};

	const hasLocalSessions = () => activitySections.localSection?.sessions?.size > 0;
	const hasRemoteSession = () => activitySections.remoteSection?.sessions?.size > 0;
	const isHibernationEnabled = useFeatureCanary(FeatureFlag.HibernateAndResume);
	const shouldShowHibernatedSection = () =>
		isHibernationEnabled && activitySections.hibernationSection?.sessions?.size > 0;

	const emptyStateProps = {
		source: t('Workspace:activity_manager.activity'),
		title: '',
		description: t('Workspace:activity_manager.empty_view'),
		imageUrl,
		callback: refresh,
		callbackPlacement: CallbackPlacement.bottomBabySecondaryButton,
		callbackLabel: 'Refresh',
	};
	return (
		<>
			{!shouldShowAppsAndDesktop && (
				<StyledDivEmpty
					data-analytics-id="activity-manager-empty-state"
					isSmallFormFactor={isSmallFormFactor}
				>
					<EmptyState {...emptyStateProps} />
				</StyledDivEmpty>
			)}

			{shouldShowAppsAndDesktop && (
				<StyledDivContent
					data-analytics-id="ActivityManager.NonEmptyState"
					isLargeFormFactor={isLargeFormFactor}
				>
					{hasLocalSessions() && (
						<ActivitySection
							activitySectionItem={activitySections.localSection}
						></ActivitySection>
					)}
					{hasLocalSessions() && hasRemoteSession() && <StyledHorizontalLine />}
					{hasRemoteSession() && (
						<ActivitySection
							activitySectionItem={activitySections.remoteSection}
						></ActivitySection>
					)}
					{shouldShowHibernatedSection() && (
						<>
							{hasLocalSessions() || hasRemoteSession() ? <StyledHorizontalLine /> : null}
							<ActivitySection
								activitySectionItem={activitySections.hibernationSection}
							/>
						</>
					)}
				</StyledDivContent>
			)}
		</>
	);
};
