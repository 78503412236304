import { get } from '@citrite/http';
// eslint-disable-next-line @citrite/workspace-ui/no-platform-dependency-access-under-src
import { hasFeatureCanary, workspaceConfiguration } from '@citrite/workspace-ui-platform';
import { trackAnalyticsEvent } from 'analytics';
import { Buffer } from 'buffer';
import { logError } from 'remoteLogging';
import {
	getAADSSOInfo,
	icaLaunch,
	leaseLaunch,
} from 'Environment/BrowserExtension/browserExtensionBridge';
import { BrowserExtensionLaunchResourceOptions } from 'Environment/BrowserExtension/types';
import { environment } from 'Environment/Environment';
import { FeatureFlag } from 'Environment/FeatureFlag';
import { launchFailed } from 'Environment/launchResource';
import { LaunchStatus } from 'Environment/launchResource/launchResource';
import { parseIcaData } from 'Environment/launchResource/resourceLaunch';
import {
	appProtectionHeaders,
	getLaunchStatus,
} from 'Environment/launchResource/resourcesClient';
import { startHybridAADTokenFlow } from 'Environment/msal/hybridMsalAuth';
import { X_CLOUD_OFFLINE_REQUEST } from 'javascript/interceptors/Headers';
import { getBlobAsDataUri } from 'userPersonalizationService/UserPreferences';
import {
	AADSSOICAContents,
	doesResourceLaunchSupportAADSSO,
	parseResourceId,
} from 'Workspace/BrowserExtension/aadSSOSpecificIcaFileParser';
import { isHybridAADSSOSupported } from 'Workspace/BrowserExtension/browserExtensionHelperMethods';
import { LeasingProperties } from 'Workspace/BrowserExtension/customerConfiguration';
import { LaunchPreference, Resource } from 'Workspace/ResourceProvider/resourceTypes';
import { browserExtensionAnalyticsReporter } from 'Workspace/TelemetryEvents/browserExtension/createBrowserExtensionAnalyticsReporter';

interface LaunchIcaError {
	status: string;
	errorId?: string;
	pollTimeout?: string;
}

function getInvalidLaunchUrlError() {
	return new Error('Invalid response from Launch Url');
}

export function isLeaseFallbackAvailable(resource: Resource) {
	return (
		resource.clmetadata?.leasesupported &&
		resource.clmetadata.launchpreference &&
		resource.clmetadata.launchpreference === LaunchPreference.IcaFallbackToLease
	);
}

export function telemetryLaunchWrapper(
	resource: Resource,
	{
		telemetryHeadlessLaunchEnabled,
		telemetryLaunchShadowDelayMins,
		telemetryLaunchMinTimeIntervalMins,
	}: LeasingProperties
) {
	return leaseLaunch({
		resourceId: resource.id,
		resourceKey: resource.clmetadata.resourcekey,
		resourceName: resource.name,
		resourceType: resource.type,
		telemetryLaunch: {
			telemetryData: {
				telemetryHeadlessLaunchEnabled,
				telemetryLaunchShadowDelayMins,
				telemetryLaunchMinTimeIntervalMins,
			},
		},
		launchPreference: resource.clmetadata.launchpreference,
	});
}

async function leaseLaunchWrapper(resource: Resource) {
	const iconData: string = await getCVADAppIconData(resource.iconurl);
	return leaseLaunch({
		resourceId: resource.id,
		resourceKey: resource.clmetadata.resourcekey,
		resourceName: resource.name,
		resourceType: resource.type,
		launchPreference: resource.clmetadata.launchpreference,
		iconData,
	});
}

async function handleLaunchIcaFailure(
	errorResponse: LaunchIcaError,
	resource: Resource,
	onIcaDownload: (resource: Resource) => void,
	isAppProtectionEnabled: boolean
) {
	if (errorResponse?.status) {
		if (errorResponse.status === 'failure' && errorResponse.errorId) {
			if (isLeaseFallbackAvailable(resource)) {
				trackAnalyticsEvent(
					browserExtensionAnalyticsReporter.getLeaseLaunchEventWhenIcaFallbackToLease()
				);
				return leaseLaunchWrapper(resource);
			}
			throw launchFailed(resource, errorResponse.errorId);
		} else if (errorResponse.status === 'retry') {
			// errorResponse.pollTimeout is in seconds. Multiplying by 1000 to convert into ms
			const timeout =
				(errorResponse.pollTimeout ? parseInt(errorResponse.pollTimeout, 10) : 10) * 1000;
			return new Promise<any>(resolve => {
				setTimeout(() => {
					resolve(icaLaunchWrapper(resource, onIcaDownload, isAppProtectionEnabled));
				}, timeout);
			});
		}
	}

	throw getInvalidLaunchUrlError();
}

async function downloadIcaFile(
	resource: Resource,
	onIcaDownload: (resource: Resource) => void,
	isAppProtectionEnabled: boolean
) {
	const { launchurl } = resource;
	const icaFile = await get<any>(launchurl, {
		headers: {
			...appProtectionHeaders(true, isAppProtectionEnabled), //sending isLaunch=true in case of launch call.
		},
	}).catch(() => null);
	const downloadSuccessful = typeof icaFile === 'string';
	if (icaFile) {
		if (!downloadSuccessful) {
			return handleLaunchIcaFailure(
				icaFile,
				resource,
				onIcaDownload,
				isAppProtectionEnabled
			);
		} else {
			onIcaDownload(resource);
			const iconData: string = await getCVADAppIconData(resource.iconurl);
			try {
				startAADSSOTokenFlowIfRequired(icaFile);
			} catch (error) {
				logError(error, {
					additionalContext: {
						error,
					},
					tags: {
						feature: 'browserExtension',
					},
				});
			}

			return icaLaunch({
				resourceId: resource.id,
				icaFile: Buffer.from(icaFile).toString('base64'),
				iconData,
				resourceName: resource.name,
			});
		}
	}

	if (isLeaseFallbackAvailable(resource)) {
		trackAnalyticsEvent(
			browserExtensionAnalyticsReporter.getLeaseLaunchEventWhenIcaFallbackToLease()
		);
		return leaseLaunchWrapper(resource);
	}

	throw getInvalidLaunchUrlError();
}

const clientId = 'a85cf173-4192-42f8-81fa-777a763e6e2c'; //gitleaks:allow
const redirectUri = 'https://client.wvd.microsoft.com/arm/webclient/aadj.html';

/** 
  This function does not have a return value since ICA launch should happen 
  irrespective of whether we get the token or not. (Same as on Windows Native. 
  If authtoken is not there User will be prompted by the VDA).

  This function simply triggers the AAD SSO flow from MSAL
  which independently fetches the auth tokens via either user interaction  or from cache
  and sends them to Browser Extension. Browser Extension will forward this token to the native
  messaging host only after it receives the "C2B_AADSSOnNotif" message.   
*/
function startAADSSOTokenFlowIfRequired(icaFileContentString: string): void {
	if (isHybridAADSSOSupported(workspaceConfiguration.get())) {
		const aadSSOFieldsInICA: AADSSOICAContents = parseIcaData(icaFileContentString);
		aadSSOFieldsInICA.resourceId = parseResourceId(aadSSOFieldsInICA.InitialProgram);
		if (doesResourceLaunchSupportAADSSO(aadSSOFieldsInICA)) {
			requestCnfFromNativeAndStartAADTokenFLow(aadSSOFieldsInICA);
		}
	}
}

export type AADSSOInfo = {
	bindingPublicKeyJwk: {
		kty: string;
		n: string;
		e: string;
		alg: string;
	};
	bindingPublicKeyJwkThumbprint: string;
	additionalInfo: string;
};

function requestCnfFromNativeAndStartAADTokenFLow(aadSSOFieldsInICA: AADSSOICAContents) {
	getAADSSOInfo()
		.then(result => {
			const aadSSOInfo: AADSSOInfo = result.result;
			startHybridAADTokenFlow({
				deviceId: aadSSOFieldsInICA.AzureDeviceId,
				clientId,
				redirectUri,
				cnf: aadSSOInfo?.bindingPublicKeyJwkThumbprint || '',
				resourceId: aadSSOFieldsInICA.resourceId,
			});
		})
		.catch(error => {
			logError(error, {
				additionalContext: {
					error,
				},
				tags: {
					feature: 'browserExtension',
				},
			});
		});
}

function icaLaunchWrapper(
	resource: Resource,
	onIcaDownload: (resource: Resource) => void,
	isAppProtectionEnabled: boolean,
	validateLaunchNextStep?: (status: LaunchStatus) => boolean
) {
	if (
		validateLaunchNextStep !== undefined &&
		!validateLaunchNextStep(LaunchStatus.CheckingAvailability)
	) {
		return Promise.resolve();
	}
	return getLaunchStatus(resource, false, {}, isAppProtectionEnabled)
		.then(async data => {
			switch (data.status) {
				case 'success':
					if (
						validateLaunchNextStep !== undefined &&
						!validateLaunchNextStep(LaunchStatus.PerformingLaunch)
					) {
						return Promise.resolve();
					}
					return downloadIcaFile(resource, onIcaDownload, isAppProtectionEnabled);
				case 'retry':
					if (
						validateLaunchNextStep !== undefined &&
						!validateLaunchNextStep(LaunchStatus.PoweringOn)
					) {
						return Promise.resolve();
					}
					// data.pollTimeout is in seconds. Multiplying by 1000 to convert into ms
					const timeout = (data.pollTimeout ?? 10) * 1000;
					return new Promise<any>(resolve => {
						setTimeout(() => {
							resolve(
								icaLaunchWrapper(
									resource,
									onIcaDownload,
									isAppProtectionEnabled,
									validateLaunchNextStep
								)
							);
						}, timeout);
					});
				case 'failure':
				default:
					throw launchFailed(resource, data.errorId, null);
			}
		})
		.catch(error => {
			if (isLeaseFallbackAvailable(resource)) {
				trackAnalyticsEvent(
					browserExtensionAnalyticsReporter.getLeaseLaunchEventWhenIcaFallbackToLease()
				);
				return leaseLaunchWrapper(resource);
			}
			throw error;
		});
}

export function launchViaBrowserExtension(
	launchResourceOptions: BrowserExtensionLaunchResourceOptions
) {
	const { resource, onIcaDownload, validateLaunchNextStep } = launchResourceOptions;
	const preference =
		resource.clmetadata?.leasesupported && resource.clmetadata.launchpreference
			? resource.clmetadata.launchpreference
			: LaunchPreference.IcaOnly;
	switch (preference) {
		case LaunchPreference.IcaOnly:
			trackAnalyticsEvent(browserExtensionAnalyticsReporter.getIcaLaunchEvent());
			return icaLaunchWrapper(
				resource,
				onIcaDownload,
				hasFeatureCanary(workspaceConfiguration.get(), FeatureFlag.BrowserAppProtection),
				validateLaunchNextStep
			);
		case LaunchPreference.IcaFallbackToLease:
			return environment.citrixCloudConnected
				? icaLaunchWrapper(
						resource,
						onIcaDownload,
						hasFeatureCanary(
							workspaceConfiguration.get(),
							FeatureFlag.BrowserAppProtection
						),
						validateLaunchNextStep
				  )
				: offlineLeaseLaunch(resource);
		case LaunchPreference.LeaseOnly:
		case LaunchPreference.LeaseFallbackToIca:
			trackAnalyticsEvent(
				browserExtensionAnalyticsReporter.getLeaseLaunchEventWhenLeaseFallbackToIca()
			);
			return handleLeaseLaunch(resource, preference);
		default:
			throw new Error('Invalid/Unsupported launch preference');
	}
}

function offlineLeaseLaunch(resource: Resource) {
	trackAnalyticsEvent(
		browserExtensionAnalyticsReporter.getLeaseLaunchEventWhenCloudOffline()
	);
	return leaseLaunchWrapper(resource);
}

// This is the maximum permitted data URL length of
// icon which can be sent via the browser extension.
const MAX_DATA_TRANSFER_SIZE_IN_BYTES = 200000;

async function getCVADAppIconData(resourcePath: string) {
	if (resourcePath == null || resourcePath.length === 0) {
		return null;
	}
	try {
		const imageBlob: Blob = await get<Blob>(resourcePath, {
			responseType: 'blob',
			headers: {
				[X_CLOUD_OFFLINE_REQUEST]: true,
			},
		});
		const dataUri = await getBlobAsDataUri(imageBlob);
		return dataUri.length > MAX_DATA_TRANSFER_SIZE_IN_BYTES ? null : dataUri;
	} catch (error) {
		logError(error, { tags: { feature: 'sendAppIconToExtension' } });
		return null;
	}
}

function handleLeaseLaunch(resource: Resource, launchpreference: LaunchPreference) {
	const event =
		launchpreference === LaunchPreference.LeaseOnly
			? browserExtensionAnalyticsReporter.getLeaseLaunchEvent()
			: browserExtensionAnalyticsReporter.getLeaseLaunchEventWhenLeaseFallbackToIca();
	trackAnalyticsEvent(event);
	return leaseLaunchWrapper(resource);
}
