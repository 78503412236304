import * as React from 'react';
import { hasFeatureCanary, WorkspaceConfiguration } from '@citrite/workspace-ui-platform';
import { History } from 'history';
import { useHistory } from 'react-router-dom';
import {
	EventSubscriber,
	EventType,
	isValidResourceURN,
	parseResourceURN,
	ViewResourceActionEvent,
} from 'Components/EventBus';
import { useConfigurationContext } from 'Configuration/useConfigurationContext';
import { FeatureFlag } from 'Environment/FeatureFlag';
import { homePagePath } from 'Router/homePageRoute';
import { feedRoutePath } from 'userNotifications/createFeedPageLink';
import { Event } from 'Workspace/EventBus';

type FeedCardViewAction = ViewResourceActionEvent;

const FEED_CARD_RESOURCE_NAME = 'feedCard';

function isFeedCardURN(urn: string) {
	return (
		isValidResourceURN(urn) &&
		parseResourceURN(urn).resourceName === FEED_CARD_RESOURCE_NAME
	);
}
function isFeedCardViewEvent(event: Event<any>): event is FeedCardViewAction {
	return (
		event.type === EventType.VIEW_ACTION &&
		event.payload &&
		event.payload.urn &&
		isFeedCardURN(event.payload.urn)
	);
}

function handleFeedCardViewAction(
	history: History,
	event: FeedCardViewAction,
	workspaceConfiguration: WorkspaceConfiguration
) {
	const { id: feedCardId } = parseResourceURN(event.payload.urn);
	const feedUrl = hasFeatureCanary(workspaceConfiguration, FeatureFlag.FeedInLeftNav)
		? `${feedRoutePath}?feedCardId=${feedCardId}`
		: `${homePagePath}?feedCardId=${feedCardId}`;
	history.replace(feedUrl);
}

function handleEvent(
	history: History,
	event: Event<any>,
	workspaceConfiguration: WorkspaceConfiguration
) {
	if (isFeedCardViewEvent(event)) {
		handleFeedCardViewAction(history, event, workspaceConfiguration);
	}
}

export function FeedCardViewActionSubscriber() {
	const history = useHistory();
	const { workspaceConfiguration } = useConfigurationContext();
	return (
		<EventSubscriber
			listener={(event: Event<any>) =>
				handleEvent(history, event, workspaceConfiguration)
			}
		/>
	);
}
