import { postWithFullResponse } from '@citrite/http';
import { PlatformDependencies } from '@citrite/workspace-ui-platform';
import { LocationDescriptorObject } from 'history';
import { authChallengeHeader } from 'Environment';
import { FeatureFlag } from 'Environment/FeatureFlag';
import { appProtectionHeaders } from 'Environment/launchResource/resourcesClient';
import { clearSessionStorage } from 'javascript/sf/Storage';
import { workspaceLoginRoute } from 'Login';
import { PendingLogin, setPendingLogin } from 'Login/PendingLogin';
import { container } from 'Workspace/DependencyManagement';
import { ListApiResult, Resource } from 'Workspace/ResourceProvider/resourceTypes';

export const loginRedirectionErrorName = 'LoginRedirectionInProgress';

export interface FetchResourceParams {
	allowUnauthenticated?: boolean;
	listUrl: string;
	location: LocationDescriptorObject;
	acceptsCachedResults?: boolean;
	isAppProtectionEnabled?: boolean;
	cachedResources?: Resource[];
}

const updateNewResourceStatus = (
	listData: ListApiResult,
	cachedResources: Resource[]
) => {
	const cachedResourceSet = new Set(cachedResources.map(resource => resource.id));

	const resources = listData.resources.map(resource => ({
		...resource,
		isnewresource: !cachedResourceSet.has(resource.id),
	}));
	listData = {
		...listData,
		resources,
	};
	return listData;
};

export async function fetchResources(params: FetchResourceParams) {
	const isNewResourceHighlightEnabled = container
		.resolve(PlatformDependencies.WorkspaceConfiguration)
		.hasFeatureCanary(FeatureFlag.HighlightNewResource);
	const { acceptsCachedResults, isAppProtectionEnabled, cachedResources } = params;
	const response = await postWithFullResponse<ListApiResult>(
		params.listUrl,
		{
			resourceDetails: 'Full',
			acceptsCachedResults,
		},
		{
			headers: {
				...appProtectionHeaders(false, isAppProtectionEnabled),
			},
			passthroughDSAuthChallenge: true,
		}
	);

	let listData: ListApiResult = response.data;
	if (isNewResourceHighlightEnabled && !!cachedResources) {
		listData = updateNewResourceStatus(listData, cachedResources);
	}

	if (listData.unauthorized) {
		if (params.allowUnauthenticated) {
			return {
				resources: [],
			};
		}
		const pendingLogin: PendingLogin = {
			challenge: response.headers[authChallengeHeader],
			locationAfterLogin: params.location,
		};
		clearSessionStorage();
		setPendingLogin(pendingLogin);
		window.location.replace('#' + workspaceLoginRoute.getUrl());
		const error = new Error('Redirection to login in progress.');
		error.name = loginRedirectionErrorName;
		throw error;
	}
	return listData;
}
