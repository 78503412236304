import { EventType } from 'Workspace/TelemetryEvents/TelemetryEventTypes';

const code = 'SAAS_APP_LAUNCH';
const preparePayload = (type: EventType, resourceId?: string) => ({
	type,
	code,
	...(resourceId && { resourceId }),
});
const createSaasAppLaunchAnalyticsReporter = () => {
	return {
		getNativeLaunchEvent: (resourceId?: string) =>
			preparePayload(EventType.SaasLaunch_UsingNative, resourceId),
	};
};
export const saasAppLaunchAnalyticsReporter = createSaasAppLaunchAnalyticsReporter();
