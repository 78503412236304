import { useEffect, useState } from 'react';
import { first } from 'lodash';
import { isRemoteAndNotDisconnected } from 'App/Activity/components/Utility';
import { PowerState, SessionSupport } from 'App/Activity/Network/ActionsUtil';
import { isDesktopSession } from 'App/Activity/ResourceActionHandler';
import {
	DesktopAction,
	LaunchAction,
	MachineAction,
	MachineData,
	Session,
	SessionAction,
	SessionConnectionState,
	UNKNOWN_STATE,
	useResourceManagerContext,
} from 'App/Activity/ResourceManagerContext';
import { useTransitioningStatus } from 'App/Activity/useTransitioningStatus';
import { environment } from 'Environment';

interface SessionActionsProps {
	session?: Session;
	machine?: MachineData;
}
export const useDesktopActions = ({ session, machine }: SessionActionsProps) => {
	const [actions, setActions] = useState<DesktopAction[]>([]);
	const { deviceId } = useResourceManagerContext();
	const combinedDeviceId =
		environment?.nativeCapabilities?.platform?.deviceId || deviceId;
	const transitioningStatus = useTransitioningStatus(session);

	useEffect(() => {
		if (transitioningStatus === UNKNOWN_STATE) {
			const desktopActions: DesktopAction[] = [];
			if (canResume(machine)) {
				desktopActions.push(LaunchAction.RESUME);
			} else {
				canDisconnect(session) && desktopActions.push(SessionAction.DISCONNECT);
				canReconnect(session) && desktopActions.push(LaunchAction.RECONNECT);
				canLogout(session) && desktopActions.push(SessionAction.LOGOUT);
				canShutDown(machine) && desktopActions.push(MachineAction.SHUTDOWN);
				canRestart(session) && desktopActions.push(LaunchAction.RESTART);
				canPowerOff(machine) && desktopActions.push(MachineAction.POWEROFF);
				canHibernate(machine) && desktopActions.push(MachineAction.HIBERNATE);
				canSessionHandoff(session, combinedDeviceId) &&
					desktopActions.push(LaunchAction.MOVE_RESOURCE);
			}
			setActions(desktopActions);
		} else {
			setActions([]);
		}
	}, [transitioningStatus, session, machine, deviceId, combinedDeviceId]);

	return actions;
};

//allow-unused-export
export function canDisconnect(session: Session) {
	return session?.connectionState === SessionConnectionState.CONNECTED;
}

export function canReconnect(session: Session) {
	return (
		session?.connectionState === SessionConnectionState.DISCONNECTED &&
		session?.machineData?.powerState !== PowerState.SUSPENDED
	);
}

//allow-unused-export
export function canLogout(session: Session) {
	return (
		session?.connectionState === SessionConnectionState.CONNECTED ||
		(session.logoffDisconnectedSupported &&
			session?.connectionState === SessionConnectionState.DISCONNECTED)
	);
}

const canShutDown = (machine: MachineData) =>
	canPerformSingleSessionMachineAction(machine, 'shutDown');

const canPowerOff = (machine: MachineData) =>
	canPerformSingleSessionMachineAction(machine, 'turnOff');

const canHibernate = (machine: MachineData) =>
	canPerformSingleSessionMachineAction(machine, 'suspend');

function canPerformSingleSessionMachineAction(
	machine: MachineData,
	action: 'shutDown' | 'turnOff' | 'suspend'
) {
	const actionSupportMap = {
		shutDown: machine?.shutDownSupported,
		turnOff: machine?.turnOffSupported,
		suspend: machine?.suspendSupported,
	};
	return (
		machine?.sessionSupport === SessionSupport.SINGLE_SESSION && actionSupportMap[action]
	);
}
function canRestart(session: Session) {
	return (
		isDesktopSession(session) && !!first(session?.applications)?.resource?.poweroffurl
	);
}

function canResume(machine: MachineData) {
	return machine?.powerState === PowerState.SUSPENDED;
}

function canSessionHandoff(session: Session, deviceId: string) {
	return isRemoteAndNotDisconnected(session, deviceId);
}
