import * as React from 'react';
import styled from '@emotion/styled';
import { ActivityResourceSanitized } from 'App/Activity/CommonResourceUtils';
import { DropDownActionItem } from 'App/Activity/DropDownUtils';
import { ActivityCardBody } from '../ActivityCardBody/ActivityCardBody';
import { ActivityCardHeader } from '../ActivityCardHeader/ActivityCardHeader';

const StyledDiv = styled.div`
	display: flex;
	flex-direction: column;
`;

export interface ActivityCardProps {
	headerItem?: ActivityCardItem;
	bodyItems: ActivityCardItem[];
}

export interface ActivityCardItem {
	activityResource: ActivityResourceSanitized;
	dropDownActionItems: DropDownActionItem[];
}

export const ActivityCard = (props: ActivityCardProps) => {
	return (
		props.bodyItems?.length > 0 && (
			<StyledDiv>
				{props.headerItem && (
					<ActivityCardHeader resourceItem={props.headerItem}></ActivityCardHeader>
				)}
				{props.bodyItems.map(activityCardItem => (
					<ActivityCardBody
						key={activityCardItem.activityResource.sessionId}
						resourceItem={activityCardItem}
					></ActivityCardBody>
				))}
			</StyledDiv>
		)
	);
};
