import { t } from '@citrite/translate';
import { WorkspaceConfiguration } from '@citrite/workspace-ui-platform';
import { trackAnalyticsEvent } from 'analytics';
import { isInEnum, NOT_AVAILABLE } from 'App/Activity/components/Utility';
import { disconnectLocally } from 'App/Activity/LocalOperations/DisconnectAction';
import { logoffLocally } from 'App/Activity/LocalOperations/LogoutAction';
import { terminateApplication } from 'App/Activity/LocalOperations/TerminateAction';
import {
	LaunchOperationResponse,
	MachineOperationResponse,
	SessionOperationResponse,
} from 'App/Activity/Network/ActionsUtil';
import { disconnectRemotely } from 'App/Activity/NetworkOperations/DisconnectAction';
import { logoffRemotely } from 'App/Activity/NetworkOperations/LogoutAction';
import { powerManagementOperationRemotely } from 'App/Activity/NetworkOperations/PowerManagementAction';
import {
	ActiveSessionAction,
	ApplicationAction,
	DesktopAction,
	IResourceManagerContext,
	LaunchAction,
	MachineAction,
	MachineData,
	ResourceDetails,
	Session,
	SessionAction,
	SessionConnectionState,
	SessionType,
} from 'App/Activity/ResourceManagerContext';
import { launchGenericResource } from 'App/Tile/utils';
import { restartDesktop } from 'Components/ResourceTile/restartDesktop';
import { environment } from 'Environment';
import { ResourceContext } from 'Workspace/ResourceProvider';
import { Resource } from 'Workspace/ResourceProvider/resourceTypes';
import { activityMangerCASReporter } from 'Workspace/TelemetryEvents/activityManager/createActivityManagerCASReporter';

interface ActionHandler {
	workspaceConfiguration: WorkspaceConfiguration;
	resourceContext?: ResourceContext;
}
interface DesktopActionHandler extends ActionHandler {
	action: DesktopAction;
	session?: Session;
	machine?: MachineData;
}

interface ApplicationActionHandler extends ActionHandler {
	action: ApplicationAction;
	application: ResourceDetails;
	session: Session;
}

export const isDesktopSession = (session: Session): boolean =>
	session.userSessionType === SessionType.DESKTOP;

const canPerformDesktopActionLocally = (
	action: DesktopAction,
	session: Session
): boolean => {
	return isInEnum(action, SessionAction) && isLocalSession(session);
};

const isLocalSession = (session: Session): boolean => !!session.localSession;

const isLocalApplication = (application: ResourceDetails): boolean =>
	!!application.localSession;

const transitioningSessionStatusMap = {
	[SessionAction.DISCONNECT]: 'Workspace:activity_manager.disconnecting',
	[SessionAction.LOGOUT]: 'Workspace:activity_manager.logging_out',
	[MachineAction.POWEROFF]: 'Workspace:activity_manager.powering_off',
	[MachineAction.SHUTDOWN]: 'Workspace:activity_manager.shutting_down',
	[MachineAction.HIBERNATE]: 'Workspace:activity_manager.hibernating',
	[LaunchAction.RESTART]: 'Workspace:activity_manager.restarting',
	[LaunchAction.RESUME]: 'Workspace:activity_manager.resuming',
	[LaunchAction.RECONNECT]: 'Workspace:activity_manager.reconnecting',
	[LaunchAction.MOVE_RESOURCE]: 'Workspace:activity_manager.moving',
	[ApplicationAction.TERMINATE]: 'Workspace:activity_manager.terminating',
};

export function getTransitioningSessionStatus(
	action: ApplicationAction | MachineAction | SessionAction | LaunchAction
): string {
	return t(transitioningSessionStatusMap[action]);
}

// allow-unused-export
export const getSessionResource = (
	resourceId: string,
	resources: Resource[]
): Resource => {
	return resourceId && resources.find(resource => resource.id === resourceId);
};

// allow-unused-export
export const performDesktopActionLocally = async ({
	action,
	session,
}: Partial<DesktopActionHandler>) => {
	switch (action) {
		case SessionAction.DISCONNECT:
			return disconnectLocally(session.localSession?.id);
		case SessionAction.LOGOUT:
			return logoffLocally(session.localSession?.id);
		default:
			return; // TODO: handle unknown action by throwing error specific to local action
	}
};

const performApplicationActionLocally = async ({
	action,
	application,
}: Partial<ApplicationActionHandler>) => {
	if (action === ApplicationAction.TERMINATE) {
		return terminateApplication(application?.localSession?.id);
	}
};
// allow-unused-export
export const getRemoteEndpoint = (
	action: DesktopAction,
	workspaceConfiguration: WorkspaceConfiguration
): string => {
	switch (action) {
		case SessionAction.DISCONNECT:
			return workspaceConfiguration.storeProxy.sessionControlProxy?.disconnectSessionsURL;
		case SessionAction.LOGOUT:
			return workspaceConfiguration.storeProxy.sessionControlProxy?.logoffSessionsURL;
		case MachineAction.POWEROFF:
			return workspaceConfiguration.storeProxy.machinesProxy?.powerOffMachineURL;
		case MachineAction.SHUTDOWN:
			return workspaceConfiguration.storeProxy.machinesProxy?.shutDownMachineURL;
		case MachineAction.HIBERNATE:
			return workspaceConfiguration.storeProxy.machinesProxy?.suspendMachineURL;
		case LaunchAction.RESTART:
		case LaunchAction.RESUME:
		case LaunchAction.RECONNECT:
		case LaunchAction.MOVE_RESOURCE:
			return NOT_AVAILABLE;
		default:
			throw new Error(); // TODO: handle unknown action by throwing error specific to remote action
	}
};

// allow-unused-export
export const trackActionAnalytics = (action: LaunchAction | MachineAction) => {
	switch (action) {
		case MachineAction.SHUTDOWN:
			trackAnalyticsEvent(
				activityMangerCASReporter.getActivityManagerShutdownEvent(true)
			);
			break;
		case MachineAction.POWEROFF:
			trackAnalyticsEvent(
				activityMangerCASReporter.getActivityManagerPoweroffEvent(true)
			);
			break;
		case MachineAction.HIBERNATE:
			trackAnalyticsEvent(
				activityMangerCASReporter.getActivityManagerHibernateEvent(true)
			);
			break;
		case LaunchAction.RESTART:
			trackAnalyticsEvent(activityMangerCASReporter.getActivityManagerRestartEvent(true));
			break;
		case LaunchAction.RESUME:
			trackAnalyticsEvent(activityMangerCASReporter.getActivityManagerResumeEvent(true));
			break;
		case LaunchAction.RECONNECT:
			trackAnalyticsEvent(
				activityMangerCASReporter.getActivityManagerReconnectEvent(true)
			);
			break;
		case LaunchAction.MOVE_RESOURCE:
			trackAnalyticsEvent(
				activityMangerCASReporter.getActivityManagerSessionHandoffEvent(true)
			);
			break;
	}
};

// allow-unused-export
export const performDesktopActionRemotely = async ({
	action,
	session,
	workspaceConfiguration,
	resourceContext,
}: Partial<DesktopActionHandler>) => {
	const remoteEndpoint = getRemoteEndpoint(action, workspaceConfiguration);
	switch (action) {
		case SessionAction.DISCONNECT:
			return disconnectRemotely(remoteEndpoint, session.sessionId);
		case SessionAction.LOGOUT:
			return logoffRemotely(remoteEndpoint, session.sessionId);
		case MachineAction.SHUTDOWN:
		case MachineAction.POWEROFF:
		case MachineAction.HIBERNATE:
			trackActionAnalytics(action);
			return powerManagementOperationRemotely(
				remoteEndpoint,
				session.machineData.machineId
			);
		case LaunchAction.RESTART:
			trackActionAnalytics(action);
			restartDesktop(
				session.applications[0].resource,
				{ resourceContext },
				workspaceConfiguration
			);
			return { isSuccess: true };
		case LaunchAction.RESUME:
		case LaunchAction.RECONNECT:
		case LaunchAction.MOVE_RESOURCE:
			trackActionAnalytics(action);
			launchGenericResource({
				resource: session.applications[0].resource,
				resourceContext,
				workspaceConfiguration,
			});
			return { isSuccess: true };
		default:
			throw new Error(); // TODO: handle unknown action by throwing error specific to local action
	}
};

export const performDesktopAction = async ({
	action,
	session,
	workspaceConfiguration,
	resourceContext,
}: DesktopActionHandler): Promise<
	SessionOperationResponse | MachineOperationResponse | LaunchOperationResponse | void
> => {
	if (canPerformDesktopActionLocally(action, session)) {
		return performDesktopActionLocally({ action, session });
	} else {
		return performDesktopActionRemotely({
			action,
			session,
			workspaceConfiguration,
			resourceContext,
		});
	}
};
export const makeApplicationAction = async ({
	action,
	session,
	application,
}: ApplicationActionHandler) => {
	if (
		action === ApplicationAction.TERMINATE &&
		isLocalSession(session) &&
		isLocalApplication(application)
	) {
		return performApplicationActionLocally({ action, application });
	}
};

export const captureSessionResource = async (session: Session, resources: Resource[]) => {
	session.applications?.forEach(application => {
		application.resource = getSessionResource(application.id, resources);
	});
};

export enum IntegrationType {
	EXTENDED = 'EXTENDED',
	SHORT = 'SHORT',
	NONE = 'NONE',
}

export const getIntegrationType = (): IntegrationType => {
	if (environment.nativeCapabilities?.platform?.deviceId) {
		return environment.supportsActivityManager
			? IntegrationType.EXTENDED
			: IntegrationType.SHORT;
	} else {
		return IntegrationType.NONE;
	}
};

// allow-unused-export
export const hasActionCompleted = (action: DesktopAction, session: Session) => {
	switch (action) {
		case SessionAction.DISCONNECT:
			return session.connectionState === SessionConnectionState.DISCONNECTED;
		case SessionAction.LOGOUT:
		case MachineAction.POWEROFF:
		case MachineAction.SHUTDOWN:
		case MachineAction.HIBERNATE:
		case LaunchAction.RESTART:
		case LaunchAction.RESUME:
		case LaunchAction.RECONNECT:
		case LaunchAction.MOVE_RESOURCE:
		default:
			return false;
	}
};

const areSessionsLoaded = (groupedSessions: {
	loading: boolean;
	sessions: Session[];
}) => {
	return groupedSessions && !groupedSessions.loading;
};

const shouldUpdateActiveSessionActions = (
	activeSessionActions: ActiveSessionAction[],
	localSessions: { loading: boolean; sessions: Session[] },
	remoteSessions: { loading: boolean; sessions: Session[] },
	hibernatedSessions: { loading: boolean; sessions: Session[] }
) => {
	return (
		areSessionsLoaded(localSessions) &&
		areSessionsLoaded(remoteSessions) &&
		areSessionsLoaded(hibernatedSessions) &&
		activeSessionActions.length > 0
	);
};

export const getValidatedActions = (
	activeSessionActions: ActiveSessionAction[],
	{ localSessions, remoteSessions, hibernatedSessions }: Partial<IResourceManagerContext>
) => {
	if (
		shouldUpdateActiveSessionActions(
			activeSessionActions,
			localSessions,
			remoteSessions,
			hibernatedSessions
		)
	) {
		const allSessions = [
			...localSessions.sessions,
			...remoteSessions.sessions,
			...hibernatedSessions.sessions,
		];
		return activeSessionActions.reduce((actions, { action, session, response }) => {
			const newSession = allSessions.find(
				loadedSession => session.sessionId === loadedSession.sessionId
			);
			if (newSession && !hasActionCompleted(action, newSession)) {
				actions.push({
					action,
					session: newSession,
					response,
				});
			}
			return actions;
		}, [] as ActiveSessionAction[]);
	}

	return activeSessionActions;
};

export const needPeriodicRefresh = (activeActions: ActiveSessionAction[]) => {
	return activeActions.some(
		({ action, response }) =>
			response && (action === SessionAction.DISCONNECT || action === SessionAction.LOGOUT)
	);
};

export const canTerminate = (session: Session, application: ResourceDetails) => {
	return isLocalSession(session) && isLocalApplication(application);
};
