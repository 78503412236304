import React from 'react';
import { fromThemeColors, layout, typography } from '@citrite/web-ui-component';
import styled from '@emotion/styled';

const StyledStatus = styled.div`
	font-style: normal;
	font-size: ${typography.sizes.sml};
	font-weight: ${typography.weights.semiBold};
	align-items: center;
	color: ${fromThemeColors('primary')};
	margin-left: ${layout.mediumSpace};
	background-color: ${fromThemeColors('infoBackground')};
	border-radius: ${layout.tinySpace};
	padding: ${layout.tinySpace} ${layout.smallSpace};
	position: absolute;
	right: 0;
`;

interface Props {
	status: string;
}

export const ActivityStatus = (props: Props) => {
	return <StyledStatus>{props.status}</StyledStatus>;
};
