import { WorkspaceConfiguration } from '@citrite/workspace-ui-platform';
import { ActivityCardItem } from 'App/Activity/ActivityCard/ActivityCard';
import { ActivityLoadingState } from 'App/Activity/CommonResourceUtils';
import { handleIntegrations } from 'App/Activity/HandleIntegrations';
import { environment } from 'Environment';

export const fetchAllResources = (
	workspaceConfig: WorkspaceConfiguration,
	setLocalSession: React.Dispatch<
		React.SetStateAction<Map<ActivityCardItem, ActivityCardItem[]>>
	>,
	setRemoteSession: React.Dispatch<
		React.SetStateAction<Map<ActivityCardItem, ActivityCardItem[]>>
	>,
	setHibernateSession: React.Dispatch<
		React.SetStateAction<Map<ActivityCardItem, ActivityCardItem[]>>
	>,
	setActivityResourceLoading: React.Dispatch<React.SetStateAction<ActivityLoadingState>>
) => {
	const clientDeviceId = environment.nativeCapabilities?.platform?.deviceId;
	handleIntegrations(
		clientDeviceId,
		workspaceConfig,
		setLocalSession,
		setRemoteSession,
		setHibernateSession,
		setActivityResourceLoading
	);
};
