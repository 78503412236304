import React, { Dispatch, SetStateAction } from 'react';
import { ActivityCardItem } from 'App/Activity/ActivityCard/ActivityCard';

export interface ActivityManagerContext {
	localRunningSessions: Map<ActivityCardItem, ActivityCardItem[]>;
	remoteRunningSessions: Map<ActivityCardItem, ActivityCardItem[]>;
	hibernatedSessions: Map<ActivityCardItem, ActivityCardItem[]>;
	setLocalRunningSessions: Dispatch<
		SetStateAction<Map<ActivityCardItem, ActivityCardItem[]>>
	>;
	setRemoteRunningSessions: Dispatch<
		SetStateAction<Map<ActivityCardItem, ActivityCardItem[]>>
	>;
	setHibernatedSessions: Dispatch<
		SetStateAction<Map<ActivityCardItem, ActivityCardItem[]>>
	>;
	refresh: () => void;
}

const defaultContext: ActivityManagerContext = {
	localRunningSessions: new Map(),
	remoteRunningSessions: new Map(),
	hibernatedSessions: new Map(),
	setLocalRunningSessions: () => new Map(),
	setRemoteRunningSessions: () => new Map(),
	setHibernatedSessions: () => new Map(),
	refresh: () => {},
};

const ActivityManagerCreatedContext =
	React.createContext<ActivityManagerContext>(defaultContext);
export const ActivityManagerProvider = ActivityManagerCreatedContext.Provider;
export const useActivityManagerContext = () =>
	React.useContext(ActivityManagerCreatedContext);
