export enum FeatureFlag {
	WsuiEnablePushNotifications = 'WsuiEnablePushNotifications',
	ServiceWorkeriOS = 'WSUI3923ServiceWorkeriOS',
	ServiceWorkerCacheValidation = 'WsuiServiceWorkerCacheValidation',
	WsuiBulkDismissRecordId = 'WsuiBulkDismissRecordId',
	UseStoreFrontFallbackUrl = 'Wxp822WorkspaceResiliencyFallback',
	BrowserExtensionHybridLaunchLinux = 'WsuiEnableBrowserExtensionHybridLaunchLinux',
	ChromeAppShield = 'WsuiEnableChromeAppShield',
	RfHtml5Cdn = 'Wsp3353RfHtml5Cdn',
	WsuiFilesUIModernization = 'WSUI4527FilesUIModernization',
	LoadingMessages = 'WSUI3567FTULoadingMessaging',
	LineArtIllustrations = 'WSUI3486LineArtIllustrations',
	BrowserAppProtection = 'WSUI4148IsAppProtectionEnabled',
	FeedInLeftNav = 'WSUI4754FeedInLeftNav',
	FeedDefaultOn = 'Wsp12246FeedDefaultOnCC',
	SimplifiedNav = 'WSUI4905SimplifiedNavigation',
	SimplifiedNavNoSubNav = 'WSUI5244SimplifiedNavNoSubNav',
	NoAppFilterInVanityDomain = 'WSUINoAppFilterInVanityDomain',
	EnableFilesInVanityDomain = 'WSUIEnableFilesInVanityDomain',
	EnableIwsInVanityDomain = 'WSUIEnableIwsInVanityDomain',
	WspUserActionsRecent = 'WSPUserActionsRecent',
	FilesIntegrationDisabled = 'WSUI7054FilesIntegrationDisabled', //disable all Files related functionality in Workspace
	ShowSharefileApp = 'WSUI7054ShowSharefileApp', //Show ShowSharefileApp
	NoEmptyAppsOrEmptyDesktopSection = 'WSUI6395NoEmptyAppsOrEmptyDesktopSection',
	FuzzySearch = 'WSUI6282FuzzySearch', //Fuzzy Search
	PreLaunchChanges = 'WSUI6462PreLaunchChanges',
	ShareCustomerIdInSentry = 'WSUI7441ShareCustomerIdInSentry',
	PersonalIntegrations = 'WSUI7803EnablePersonalIntegrations',
	ShowFirstAndLastNameInitials = 'WSUI7293ShowFirstAndLastNameInitials',
	DisableResourceButtonWrap = 'WSUI7644DisableResourceButtonWrap', // removes button wrap(needed for accessibility) on resources so that iOS/mac(Swift) automation can identify elements within resource(ex. Favourite, More option)
	EnableUserDisplayName = 'WSHELP301EnableUserDisplayName',
	EnableUserDisplayNameOnPrem = 'Citrix.StoreFront.WorkspaceUI.EnableUsername',
	EnableInfoLogging = 'WSUI8024EnableInfoLogging',
	FirefoxExtensionEnabled = 'BXTN385FirefoxExtensionEnabled', // Firefox Extension
	ActivityManagerForceEnabled = 'WSUI8415ActivityManagerForceEnabled',
	WsuiEnableHTML5Shield = 'WSUI7563WsuiEnableHTML5Shield',
	DetectClientBeforeAuthentication = 'WSUI8335DetectClientBeforeAuthentication',
	HighlightNewResource = 'WSUI7812HighlightNewResource',
	SaasAppLaunchUsingNative = 'WSUI8802SaasAppLaunchUsingNative',
	HibernateAndResume = 'WSUI7430HibernateAndResume',
	EnableHybridAADSSO = 'WSUI7083EnableHybridAADSSO',
	EnableStaleWhileRevalidate = 'WSUI8882EnableStaleWhileRevalidate',
	EnableStaleWhileRevalidateForMac = 'WSUI8882EnableStaleWhileRevalidateForMac',
	EnableStaleWhileRevalidateForWindows = 'WSUI8882EnableStaleWhileRevalidateForWindows',
	EnableConnectionErrorScreen = 'WSUI8881EnableConnectionErrorScreen',
	EnablePrefetchUserDetailsFromCache = 'WSUI8538EnablePrefetchUserDetailsFromCache',
	EnablePrefetchUserDetailsFromCacheForMac = 'WSUI8538EnablePrefetchUserDetailsFromCacheForMac',
	EnablePrefetchUserDetailsFromCacheForWindows = 'WSUI8538EnablePrefetchUserDetailsFromCacheForWindows',
	PromptUserToInstallBrowserExtension = 'WSUI8209PromptUserToInstallBrowserExtension',
	EnableSynchronousAppShell = 'WSUI8552EnableSynchronousAppShell',
	UseIsSubscriptionEnabledFlag = 'Wsp22502SetAllowFavouritesBugfix',
	SendICAWithEncoding = 'WSUI9234SendICAWithEncoding',
	AllowOnlyJsonIca = 'WSUI9333AllowOnlyJsonIca',
	EnableBrowserExtensionOnVanityDomains = 'WSUI9060nableBrowserExtensionOnVanityDomains',
	UseFixForDesktopAvailability = 'WSHELP417UseFixForDesktopAvailability',
	ActivityManagerRefactoring = 'WSUI9437ActivityManagerRefactoring',
	InitiateStoreInOfflineMode = 'WSUI9474BXTNInitiateStoreInOfflineMode',
	EnableCustomLinks = 'WSUI9293CustomLinkBanner',
	UseModernOfflineFlow = 'WSUI9501UseModernOfflineFlow',
	DaasVisionForceEnabled = 'WSUI9628ForceEnableNewUI',
	EnableGlobalAppConfigV2 = 'WSUI9541GlobalAppConfigv2',
	InitiateResourceManagerOnLoad = 'WSUI9437InitiateResourceManagerOnLoad',
	EnableOpenCitrixForLinux = 'WSUI9765EnableOpenCitrixForLinux',
	EnableOpenCitrixForChromeOS = 'WSUI9765EnableOpenCitrixForChromeOS',
	EnableOffWhiteBackground = 'WSUI9641EnableOffWhiteBackground',
}
