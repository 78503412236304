import { VersionInfo } from '@citrite/workspace-ui-platform';
import {
	faro as faroSingleton,
	initializeFaro as setupFaro,
	MetaAttributes,
} from '@grafana/faro-web-sdk';
import { WSUI_SESSION_ID } from 'Loggers/LoggingProvider';
import { Monitoring, MonitoringData, MoreInfo } from './monitoringTypes';

const faroAttributes: MetaAttributes = {};

const prodFaroAccountUrl =
	'https://faro-collector-prod-us-central-0.grafana.net/collect/8673d3528127b3982751acc5be3209ab';
const testFaroAccountUrl =
	'https://faro-collector-prod-us-central-0.grafana.net/collect/d84e9ae743e82ec4abd21f2640b8f67c';

export const faro: Monitoring = {
	initialize(appName: string, versionInfo: VersionInfo) {
		const url = versionInfo.isProduction() ? prodFaroAccountUrl : testFaroAccountUrl;
		setupFaro({
			url,
			app: {
				name: appName,
				version: versionInfo.revision,
				environment: versionInfo.environment,
			},
			session: {
				id: WSUI_SESSION_ID,
			},
		});
	},
	setSessionAttribute(name: string, value: string | number | boolean) {
		faroAttributes[name] = value.toString();
		faroSingleton.api.setSession({
			id: WSUI_SESSION_ID,
			attributes: faroAttributes,
		});
	},
	setCurrentRouteName(name: string) {
		this.setSessionAttribute('current-route', name);
	},
	addMeasure(name: string, start: number, end: number, moreInfo: MoreInfo = {}) {
		faroSingleton.api.pushMeasurement(
			{
				type: name,
				values: {
					duration: end - start,
				},
			},
			{
				context: convertToRecord(moreInfo),
			}
		);
	},
};

// this export is required for unit tests
// allow-unused-export
export function convertToRecord(details: MoreInfo): Record<string, string> {
	const result: Record<string, string> = {};

	for (const key in details) {
		if (details.hasOwnProperty(key)) {
			const value = details[key];

			if (typeof value === 'string') {
				result[key] = String(value);
			} else if (isMonitoringData(value)) {
				result[key] = value;
			} else {
				result[key] = '';
			}
		}
	}
	return result;
}

// this export is required for unit tests
// allow-unused-export
export function isMonitoringData(value: any): value is MonitoringData {
	return Object.values(MonitoringData).includes(value);
}
