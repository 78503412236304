import { AADAuthCodeType, AuthInfoWithCnf } from '@citrite/msal-browser';
import { Writable } from '@citrite/utility-types';
import { NativePlatform } from '@citrite/workspace-ui-platform';
import { logError } from 'remoteLogging';
import { nativeLogoutCallbacks, refreshApp, updateUserName } from 'Environment/callbacks';
import { Environment, environment, OnlineStatus } from 'Environment/Environment';
import { setAuthorizationCode, startAADTokenFlow } from 'Environment/msal/nativeMsalAuth';
import { detectNativePlatform } from 'Environment/setup/nativeDetection';
import { getEnvironmentSpecificStorage } from 'Environment/storageUtils';
import { setEnvironmentSpecificStorage } from 'javascript/sf/Storage';
import { logoutRoute } from 'Logout';
import { getHistory } from 'Router/BrowserHistory';
import { createForNative } from 'ssoProxyClient';
import { callAsyncNativeFunction, callSyncNativeFunction } from './callNativeFunction';
import { buildNativeEventBus } from './NativeEventBus/buildNativeEventBus';
import { nativeEnvironmentFunctions } from './nativeFunctions';
import { ProvidedNativeFunctions } from './providedNativeFunctions';
import { displayWorkspaceToStoreFrontFallbackPendoScreen } from './WorkspaceToStoreFrontFallbackPendoScreen';

/*
  Keep this export for Unit testing
*/
// allow-unused-export
export function nativeSupportsAADAuth(nativeCapabilities: NativeCapabilities) {
	const requiredFunctionsForAAD: Partial<keyof ProvidedNativeFunctions>[] = [
		'setAADError',
		'setAADResponse',
	];
	return requiredFunctionsForAAD.every(fname =>
		nativeCapabilities?.functions?.includes(fname)
	);
}

const onPremClientCapabilities = {
	version: '1.1',
	menuItems: ['about', 'refresh', 'preferences', 'logoff'],
	functionsForNative: ['ctxs_async_setUserName', 'ctxs_async_refresh'],
};

const cloudClientCapabilities = {
	version: '1.1',
	menuItems: [
		'about',
		'refresh',
		'preferences',
		'logoff',
		'accountSwitch',
		'accounts',
		'manageLocalApps',
	],
	functionsForNative: [
		'ctxs_async_setUserName',
		'ctxs_async_refresh',
		'ctxs_async_noteLogoff',
		'ctxs_async_requestLogoff',
		'ctxs_async_setCitrixCloudConnectedStatus',
		'ctxs_async_showWorkspaceToStoreFrontFallbackPendoScreen',
		'ctxs_async_getAADAccessToken',
		'ctxs_async_setAADData',
	],
};

export async function buildNativeEnvironment(env: Environment, global = window) {
	const clientCapabilities = IS_ON_PREM
		? onPremClientCapabilities
		: cloudClientCapabilities;

	const defaultMenuPreferences: MenuPreferences = {
		about: false,
		logoff: false,
		preferences: false,
		refresh: false,
		accountSwitch: false,
		accounts: false,
		manageLocalApps: false,
	};

	const nativeCapabilities = env.initializeSync
		? getCapabilitiesSync(clientCapabilities)
		: await getCapabilitiesAsync(clientCapabilities);

	env.nativeCapabilities = nativeCapabilities;

	if (env.nativePlatform !== NativePlatform.Simulated) {
		env.createSSOProxyClient = createForNative;
	}

	nativeCapabilities.functions.forEach(functionName => {
		env[functionName] = nativeEnvironmentFunctions[functionName];
	});
	await setEnvironmentSpecificStorage(
		getEnvironmentSpecificStorage(nativeCapabilities.functions)
	);
	const onlineStatus = await env.getOnlineStatus();
	(env as Writable<Environment>).citrixCloudConnected =
		onlineStatus === OnlineStatus.Online;
	env.store = await getStoreDetails();
	if (env.citrixCloudConnected) {
		try {
			await env.goOnline();
		} catch {}
	}

	env.useNativeUrlsForResourceIcons =
		nativeCapabilities.functions.includes('ajax') &&
		(nativeCapabilities.functions.includes('sync_getFileUri') ||
			nativeCapabilities.functions.includes('getFileUri'));
	env.isNativeDropdownNeeded = isNativeDropdownNeeded(nativeCapabilities);
	env.menuPreferences = nativeCapabilities.menuPreferences || defaultMenuPreferences;

	env.supportsLegacySettings =
		nativeCapabilities.functions.includes('settingsMenu') ||
		nativeCapabilities.functions.includes('settingsMenu2');
	env.supportsNewSettings =
		nativeCapabilities.functions.includes('showPreferences') &&
		env.menuPreferences.preferences;
	env.supportsLocalApps =
		nativeCapabilities.functions.includes('getLocalApps') &&
		nativeCapabilities.functions.includes('launchLocalApp') &&
		(nativeCapabilities.localApps?.enableDiscovery ??
			env.menuPreferences.manageLocalApps);
	env.supportsLocalAppsWhitelist =
		env.supportsLocalApps &&
		nativeCapabilities.functions.includes('getLocalAppWhitelist');
	env.supportsUserManagedLocalApps =
		env.supportsLocalApps && (nativeCapabilities.localApps?.enableUserManagement ?? true);
	env.supportsCitrixWorkspaceBrowserApp = nativeCapabilities.functions.includes(
		'launchCitrixWorkspaceBrowserApp'
	);
	env.supportsActivityManager =
		nativeCapabilities.functions.includes('fetchActivityList') &&
		(nativeCapabilities.functions.includes('dispatchEventToNative2') ||
			nativeCapabilities.functions.includes('dispatchEventToNative'));
	env.supportsShortcuts = nativeCapabilities.functions.includes('createShortcuts');

	const isIOS = detectNativePlatform()?.nativePlatform === NativePlatform.iOS;
	if (nativeCapabilities.functions.includes('attemptLogoff') && (!IS_ON_PREM || isIOS)) {
		env.nativeLogoffMethod = 'attemptLogoff';
	} else if (nativeCapabilities.functions.includes('logOff')) {
		env.nativeLogoffMethod = 'logOff';
	}
	env.showStoreFrontToWorkspaceMigrationGuideInfo =
		shouldShowStoreFrontToWorkspaceMigrationGuideInfo(nativeCapabilities);

	env.cwaBuildVersion = nativeCapabilities.platform.buildVersion;
	env.isGoOnlineSilentlySupported =
		nativeCapabilities.functions.includes('goOnlineSilently');
	env.supportsInteractiveAuthOnly =
		nativeCapabilities.supportsInteractiveAuthOnly === true;

	env.supportsAADAuth = nativeSupportsAADAuth(nativeCapabilities);

	const functionsForNative = global as any;

	functionsForNative.ctxs_async_setUserName = (username: string) => {
		updateUserName(username);
	};

	functionsForNative.ctxs_async_refresh = () => {
		refreshApp(true);
	};

	functionsForNative.ctxs_async_showWorkspaceToStoreFrontFallbackPendoScreen = () => {
		displayWorkspaceToStoreFrontFallbackPendoScreen();
	};

	functionsForNative.ctxs_async_noteLogoff = () => {
		nativeLogoutCallbacks.forEach(callback => {
			try {
				callback();
			} catch (e) {
				logError(e);
			}
		});
	};

	functionsForNative.ctxs_async_requestLogoff = (
		jsonPayload: string,
		callbackID: string
	) => {
		const payload: { reason: 'inactivity' | 'nativeLogoff' } = JSON.parse(jsonPayload);
		getHistory().push({
			pathname: logoutRoute.getUrl(),
			search: `?isNativeLogoutRequest=true&userHint=${payload.reason}&callbackId=${callbackID}`,
		});
	};

	functionsForNative.ctxs_async_setCitrixCloudConnectedStatus = (jsonOptions: string) => {
		const options: { online: boolean } = JSON.parse(jsonOptions);
		environment.setCitrixCloudConnectedStatus(!!options.online);
	};

	functionsForNative.ctxs_async_getAADAccessToken = (jsonPayload: string) => {
		const authInfo: AuthInfoWithCnf = JSON.parse(jsonPayload);
		startAADTokenFlow(authInfo);
	};

	functionsForNative.ctxs_async_setAADData = (jsonPayload: string) => {
		const aadAuthCode: AADAuthCodeType = JSON.parse(jsonPayload);
		setAuthorizationCode(aadAuthCode);
	};

	buildNativeEventBus(env, global);
}

export interface NativeCapabilities {
	apiversion: string;
	clientSettings: {
		clearAppListOnLogoff: boolean;
		clientAddress: string;
		clientInfoForSubscriptionHost: string;
		clientInfoForSubscriptionUser: string;
		clientName: string;
		disableCacheOfResourceList: boolean;
		crashReportingDisabled: boolean;
		enableServiceWorker: boolean;
	};
	/** @deprecated This references the legacy, pre-Shield manual disk cache offline mode supported by Receivers */
	enableOffline: boolean;
	localApps?: {
		enableUserManagement: boolean;
		enableDiscovery: boolean;
	};
	featureFlags?: {
		[flagname: string]: boolean;
	};
	features: any;
	functions: (keyof ProvidedNativeFunctions)[];
	menuPreferences: MenuPreferences;
	platform: {
		appculture: string;
		appkey: string;
		appname: string;
		appversion: string;
		id: string;
		buildVersion?: string;

		devicetype?: string;
		screensize?: string;
		packagebundleid?: string;
		osversion?: string;
		appreleasetype?: string;
		deviceId?: string;
	};
	preferredLanguages: string[];
	showStoreFrontToWorkspaceMigrationGuideInfo?: boolean;
	supportsInteractiveAuthOnly?: boolean;
	loadMonitoringStats?: {
		[monitoringKey: string]: string;
	};
}

export interface MenuPreferences {
	about: boolean;
	logoff: boolean;
	preferences: boolean;
	refresh: boolean;
	accounts?: boolean;
	accountSwitch?: boolean;
	manageLocalApps?: boolean;
}

function getCapabilitiesSync(clientCapabilities: object) {
	const result = callSyncNativeFunction('sync_getCapabilities', bridge =>
		bridge.sync_getCapabilities(JSON.stringify(clientCapabilities))
	);

	return JSON.parse(result) as NativeCapabilities;
}

function getCapabilitiesAsync(clientCapabilities: object) {
	return callAsyncNativeFunction(
		'getCapabilities',
		(bridge, callbackId) =>
			bridge.getCapabilities(JSON.stringify(clientCapabilities), callbackId),
		json => JSON.parse(json) as NativeCapabilities
	);
}

export interface StoreDetails {
	id: string;
	baseUri: string;
}

function getStoreDetails() {
	return callAsyncNativeFunction(
		'getStoreDetails',
		(bridge, callbackId) => bridge.getStoreDetails(callbackId),
		(id: string, baseUri: string): StoreDetails => ({
			id,
			baseUri,
		})
	);
}

function isNativeDropdownNeeded(nativeCapabilities: NativeCapabilities) {
	return !nativeCapabilities.menuPreferences;
}

function shouldShowStoreFrontToWorkspaceMigrationGuideInfo(
	nativeCapabilities: NativeCapabilities
) {
	return (
		typeof nativeCapabilities.showStoreFrontToWorkspaceMigrationGuideInfo === 'boolean' &&
		nativeCapabilities.showStoreFrontToWorkspaceMigrationGuideInfo
	);
}

/**
 * We respect 3rd party crash reporting policy (Sentry\New Relic etc)
 * from native only if the following two conditions are true:
 *
 * 1) WSUI is running in native CWA Windows
 * 2) Admin has explicitly disabled this value using GPO setting on native client.
 */
// allow-unused-export : We will be calling this in a subsequent PR
export function crashReportingDisabledByNative(env: Environment): boolean {
	return (
		env?.nativePlatform === NativePlatform.Windows &&
		env?.nativeCapabilities?.clientSettings?.crashReportingDisabled === true
	);
}
