import { post } from '@citrite/http';
import { trackAnalyticsEvent } from 'analytics';
import { DropDownActionItem } from 'App/Activity/DropDownUtils';
import {
	ActionStatus,
	checkAnalyticsEvent,
	contentTypeJson,
	MachineOperationResponse,
	ResponseData,
} from 'App/Activity/Network/ActionsUtil';
import { ContinuationOperationRequestData } from 'App/Activity/Network/ContinuationOperationData';
import { MachineOperationRequestData } from 'App/Activity/Network/MachineOperationData';

export const powerManagementOperation = (dropDownActionItem: DropDownActionItem) => {
	const event = checkAnalyticsEvent(dropDownActionItem.item);
	trackAnalyticsEvent(event);
	const body: string = JSON.stringify(
		new MachineOperationRequestData(dropDownActionItem.machineData?.machineId)
	);
	return post<MachineOperationResponse>(
		dropDownActionItem.endpoint,
		body,
		contentTypeJson
	);
};

export const checkPowerManagementStatus = async (
	operationUrl: string,
	response: ResponseData
) => {
	const body: string = JSON.stringify(
		new ContinuationOperationRequestData(response.continuationId)
	);
	let operationCompleted = false;
	const operationResponse = await post<MachineOperationResponse>(
		operationUrl,
		body,
		contentTypeJson
	);
	if (operationResponse.actionStatus === ActionStatus.COMPLETED) {
		operationCompleted = true;
	}
	return operationCompleted;
};
