import { trackAnalyticsEvent } from 'analytics';
import { DropDownActionItem } from 'App/Activity/DropDownUtils';
import { ResponseData } from 'App/Activity/Network/ActionsUtil';
import { environment } from 'Environment';
import { activityMangerCASReporter } from 'Workspace/TelemetryEvents/activityManager/createActivityManagerCASReporter';

export const resume = (dropDownActionItem: DropDownActionItem): Promise<ResponseData> => {
	const eventName = {
		id: 'activities',
		type: 'action/miniui/running/resource/resume',
		payload: { machineId: dropDownActionItem.machineData?.machineId },
	};
	environment.sendEventToNative(eventName);
	trackAnalyticsEvent(activityMangerCASReporter.getActivityManagerResumeEvent(false));
	return Promise.resolve({});
};
