import { t } from '@citrite/translate';
import { ActivityCardItem } from 'App/Activity/ActivityCard/ActivityCard';
import { ActivityManagerContext } from 'App/Activity/ActivityManagerContext';
import {
	createSupportedOperations,
	DropDownActionItem,
} from 'App/Activity/DropDownUtils';
import {
	getLocalOperation,
	getLocalOperationName,
	LocalOperationType,
} from 'App/Activity/Local/OperationUtils';
import { PowerState, SessionSupport } from 'App/Activity/Network/ActionsUtil';
import { reportError, sortByTimestamp } from 'App/Activity/Utility';
import { environment } from 'Environment';
import { getCurrentPlatform } from 'Environment/launchResource/device';
import { Resource } from 'Workspace/ResourceProvider/resourceTypes';
import { activityMangerCASReporter } from 'Workspace/TelemetryEvents/activityManager/createActivityManagerCASReporter';

export enum ResourceType {
	VIRTUAL_DESKTOP = 'virtual_desktop',
	VIRTUAL_APP = 'virtual_app',
	UNKNOWN = 'unknown',
}

export interface ActivityLoadingState {
	isLocalResourceLoaded: boolean;
	isRemoteResourceLoaded: boolean;
}

export enum ResourceState {
	TERMINATING = 'terminating',
	DISCONNECTING = 'disconnecting',
	LOGGING_OUT = 'logging out',
	ACTIVE = 'active',
	CONNECTED = 'connected',
	DISCONNECTED = 'disconnected',
	RESTARTING = 'restarting',
	SHUTTING_DOWN = 'shutting down',
	POWERING_OFF = 'powering off',
	HIBERNATING = 'hibernating',
	RESUMING = 'resuming',
	UNKNOWN = 'unknown',
}

export interface ActivityResource {
	description?: string;
	id: string;
	name: string;
	type: string;
	isAppContainer?: boolean;
	parentId?: string;
	startTimeStamp?: string;
	supportedOperations: string[];
	state?: string;
	machineData?: MachineData;
}

export class ActivityResourceSanitized {
	public description: string;
	public sessionId: string;
	public name: string;
	public type: ResourceType;
	public isAppContainer: boolean;
	public parentId: string;
	public startTimeStamp: string;
	public supportedOperations: DropDownActionItem[];
	public state: ResourceState;
	public deviceId: string;
	public deviceType: string;
	public resourceId: string;
	public machineData?: MachineData;
	public resourceIcon: string;
	public isFetchIconAvailable: boolean;
}

export interface MachineData {
	machineId: string;
	machineName: string;
	powerState: PowerState;
	registrationState: string;
	sessionSupport: SessionSupport;
	shutDownSupported: boolean;
	suspendSupported: boolean;
	turnOffSupported: boolean;
}

let stateMapping: Record<string, ResourceState> = undefined;

// allow-unused-export
export const getResourceState = (state: string): ResourceState => {
	if (!stateMapping) {
		stateMapping = Object.fromEntries(
			Object.values(ResourceState).map(resourceState => [
				resourceState,
				resourceState as ResourceState,
			])
		);
	}

	return stateMapping[state] || ResourceState.UNKNOWN;
};

export interface RemoveResourceEvent {
	sessionid: string[];
}

const filterResource = (
	sessions: Map<ActivityCardItem, ActivityCardItem[]>,
	filterFunction: (
		key: ActivityCardItem,
		values?: ActivityCardItem[]
	) => ActivityCardItem[]
): ActivityCardItem[] => {
	const filteredResources: ActivityCardItem[] = [];
	sessions?.forEach((values, key) => {
		const activityResource = filterFunction(key, values);
		if (activityResource?.length > 0) {
			Array.prototype.push.apply(filteredResources, activityResource);
		}
	});
	return filteredResources;
};

const filterSingleResource = (
	sessions: Map<ActivityCardItem, ActivityCardItem[]>,
	filterFunction: (
		key: ActivityCardItem,
		values?: ActivityCardItem[]
	) => ActivityCardItem | null
): ActivityCardItem | null => {
	let filteredResource: ActivityCardItem = null;
	sessions?.forEach((values, key) => {
		if (filteredResource === null) {
			filteredResource = filterFunction(key, values);
		}
	});
	return filteredResource;
};

const filterAnyResource = (
	resourceId: string,
	sessions: Map<ActivityCardItem, ActivityCardItem[]>
): ActivityCardItem[] => {
	return filterResource(
		sessions,
		(key: ActivityCardItem, values: ActivityCardItem[]): ActivityCardItem[] => {
			const filteredResourceList: ActivityCardItem[] = [];
			if (key.activityResource.sessionId === resourceId) {
				filteredResourceList.push(key);
			} else {
				values.forEach(value => {
					if (value.activityResource.sessionId === resourceId) {
						filteredResourceList.push(value);
					}
				});
			}
			return filteredResourceList;
		}
	);
};

export const filterResourcesFromContext = (
	resourceId: string,
	activityManagerContext: ActivityManagerContext
): ActivityCardItem => {
	const localResources: ActivityCardItem[] = filterAnyResource(
		resourceId,
		activityManagerContext.localRunningSessions
	);
	const remoteResources: ActivityCardItem[] = filterAnyResource(
		resourceId,
		activityManagerContext.remoteRunningSessions
	);
	return localResources.length > 0
		? localResources[0]
		: remoteResources.length > 0
		? remoteResources[0]
		: null;
};

const updateResourceUsingId = (
	id: string,
	sessionsMap: Map<ActivityCardItem, ActivityCardItem[]>,
	filter: (
		sessions: Map<ActivityCardItem, ActivityCardItem[]>,
		id: string,
		key: ActivityCardItem,
		values: ActivityCardItem[]
	) => void
) => {
	sessionsMap?.forEach((values, key) => {
		filter(sessionsMap, id, key, values);
	});
};

const removeResourceUsingId = (
	sessions: Map<ActivityCardItem, ActivityCardItem[]>,
	id: string,
	key: ActivityCardItem,
	values: ActivityCardItem[]
) => {
	if (key.activityResource.sessionId === id) {
		sessions.delete(key);
	} else {
		values.forEach((value, index) => {
			if (value.activityResource.sessionId === id) {
				values.splice(index, 1);
			}
		});
	}
};

export const deleteResourceUsingId = (
	id: string,
	sessions: Map<ActivityCardItem, ActivityCardItem[]>
) => {
	sessions.forEach((applications, key) => {
		if (key.activityResource.sessionId === id) {
			sessions.delete(key);
		} else {
			applications.forEach((value, index) => {
				if (value.activityResource.sessionId === id) {
					applications.splice(index, 1);
					if (applications.length === 0) {
						sessions.delete(key);
					}
				}
			});
		}
	});
};

export const filterDesktops = (
	sessions: Map<ActivityCardItem, ActivityCardItem[]>
): ActivityCardItem[] => {
	return filterResource(sessions, (key: ActivityCardItem) =>
		!key.activityResource.isAppContainer ? [key] : []
	).sort(sortByTimestamp);
};

export const filterSessions = (
	sessions: Map<ActivityCardItem, ActivityCardItem[]>
): ActivityCardItem[] => {
	return filterResource(sessions, (key: ActivityCardItem) =>
		key.activityResource.isAppContainer ? [key] : []
	).sort(sortByTimestamp);
};

export const filterApps = (
	parentId: string,
	sessions: Map<ActivityCardItem, ActivityCardItem[]>
): ActivityCardItem[] => {
	return filterResource(sessions, (key: ActivityCardItem, values: ActivityCardItem[]) =>
		key.activityResource.sessionId === parentId && key.activityResource.isAppContainer
			? values
			: []
	).sort(sortByTimestamp);
};

export const filterAnyResourceWithResourceId = (
	resourceId: string,
	sessions: Map<ActivityCardItem, ActivityCardItem[]>
): ActivityCardItem => {
	const filteredResource = filterSingleResource(
		sessions,
		(key: ActivityCardItem, values: ActivityCardItem[]): ActivityCardItem => {
			let result: ActivityCardItem = null;
			if (key.activityResource.resourceId === resourceId) {
				result = key;
			} else {
				values.forEach(value => {
					if (value.activityResource.resourceId === resourceId) {
						result = value;
					}
				});
			}
			return result;
		}
	);
	return filteredResource;
};

export const getResourceFromResourceContext = (id: string, resources: Resource[]) => {
	return resources.find(resource => resource.id === id);
};

export const getResourceStateDisplayName = (state: ResourceState): string => {
	let displayName;
	if (state === ResourceState.DISCONNECTING) {
		displayName = t('Workspace:activity_manager.disconnecting');
	} else if (state === ResourceState.LOGGING_OUT) {
		displayName = t('Workspace:activity_manager.logging_out');
	} else if (state === ResourceState.TERMINATING) {
		displayName = t('Workspace:activity_manager.terminating');
	} else if (state === ResourceState.RESTARTING) {
		displayName = t('Workspace:activity_manager.restarting');
	} else if (state === ResourceState.POWERING_OFF) {
		displayName = t('Workspace:activity_manager.powering_off');
	} else if (state === ResourceState.SHUTTING_DOWN) {
		displayName = t('Workspace:activity_manager.shutting_down');
	} else if (state === ResourceState.HIBERNATING) {
		displayName = t('Workspace:activity_manager.hibernating');
	} else if (state === ResourceState.RESUMING) {
		displayName = t('Workspace:activity_manager.resuming');
	}
	return displayName;
};

export const shouldShowResourceState = (
	activityResourceState: ResourceState
): boolean => {
	return (
		activityResourceState === ResourceState.LOGGING_OUT ||
		activityResourceState === ResourceState.DISCONNECTING ||
		activityResourceState === ResourceState.TERMINATING ||
		activityResourceState === ResourceState.POWERING_OFF ||
		activityResourceState === ResourceState.SHUTTING_DOWN ||
		activityResourceState === ResourceState.RESTARTING ||
		activityResourceState === ResourceState.HIBERNATING ||
		activityResourceState === ResourceState.RESUMING
	);
};

export const updateResourceState = (
	resourceId: string,
	updatedState: ResourceState,
	sessions: Map<ActivityCardItem, ActivityCardItem[]>
): Map<ActivityCardItem, ActivityCardItem[]> => {
	const clonedMap: Map<ActivityCardItem, ActivityCardItem[]> = new Map(sessions);
	const activityCardItem: ActivityCardItem = filterAnyResourceWithResourceId(
		resourceId,
		sessions
	);
	if (activityCardItem) {
		activityCardItem.activityResource.state = updatedState;
	}
	return clonedMap;
};

export const resetLists = (context: ActivityManagerContext, resourceId: string) => {
	const clonedMap = (oldMap: Map<ActivityCardItem, ActivityCardItem[]>) => {
		const clonedMapLocalSessions: Map<ActivityCardItem, ActivityCardItem[]> = new Map(
			oldMap
		);
		updateResourceUsingId(resourceId, clonedMapLocalSessions, removeResourceUsingId);
		return clonedMapLocalSessions;
	};
	context.setLocalRunningSessions(clonedMap);
	context.setRemoteRunningSessions(clonedMap);
};

export const filterSessionsWithResourceId = (
	sessionMap: Map<ActivityCardItem, ActivityCardItem[]>,
	resourceId: string
) => {
	const filteredSessions = new Map();
	for (const [desktop, applications] of sessionMap) {
		if (desktop.activityResource.resourceId === resourceId) {
			filteredSessions.set(desktop, applications);
			break;
		}
	}
	return filteredSessions;
};

export const getResourceStateFromOperation = (opName: string): ResourceState => {
	let resourceState: ResourceState = ResourceState.UNKNOWN;
	if (opName === LocalOperationType.DISCONNECT) {
		resourceState = ResourceState.DISCONNECTING;
	} else if (opName === LocalOperationType.LOGOFF) {
		resourceState = ResourceState.LOGGING_OUT;
	} else if (opName === LocalOperationType.TERMINATE) {
		resourceState = ResourceState.TERMINATING;
	} else if (opName === LocalOperationType.POWEROFF) {
		resourceState = ResourceState.POWERING_OFF;
	} else if (opName === LocalOperationType.SHUTDOWN) {
		resourceState = ResourceState.SHUTTING_DOWN;
	} else if (opName === LocalOperationType.HIBERNATE) {
		resourceState = ResourceState.HIBERNATING;
	} else if (opName === LocalOperationType.RESUME) {
		resourceState = ResourceState.RESUMING;
	} else {
		resourceState = ResourceState.UNKNOWN;
	}
	return resourceState;
};

export const checkAnalyticsEvent = (operationType: string) => {
	let event = null;
	if (operationType === LocalOperationType.POWEROFF) {
		event = activityMangerCASReporter.getActivityManagerPoweroffEvent(false);
	} else if (operationType === LocalOperationType.SHUTDOWN) {
		event = activityMangerCASReporter.getActivityManagerShutdownEvent(false);
	} else {
		event = activityMangerCASReporter.getActivityManagerHibernateEvent(false);
	}
	return event;
};

export const sanitizeActivityResource = (
	activityResource: ActivityResource
): ActivityResourceSanitized => {
	const activityResourceSanitized = new ActivityResourceSanitized();
	activityResourceSanitized.description = activityResource.description || '';
	activityResourceSanitized.sessionId = activityResource.id;
	activityResourceSanitized.resourceId = activityResource.id;
	activityResourceSanitized.isAppContainer = activityResource.isAppContainer || false;
	activityResourceSanitized.name = activityResource.name;
	activityResourceSanitized.parentId = activityResource.parentId || '';
	activityResourceSanitized.startTimeStamp = activityResource.startTimeStamp || '';
	activityResourceSanitized.machineData = activityResource.machineData;
	if (activityResource.type === 'virtual_desktop') {
		activityResourceSanitized.type = ResourceType.VIRTUAL_DESKTOP;
	} else if (activityResource.type === 'virtual_app') {
		activityResourceSanitized.type = ResourceType.VIRTUAL_APP;
	} else {
		activityResourceSanitized.type = ResourceType.UNKNOWN;
		reportError('Activity Resource Sanitizaton Failed', 'Resource Type Unknown');
		return null;
	}
	activityResourceSanitized.deviceId = environment.nativeCapabilities.platform.deviceId;
	activityResourceSanitized.deviceType = getCurrentPlatform().toString();
	activityResourceSanitized.supportedOperations = createSupportedOperations(
		activityResource.id,
		activityResourceSanitized.resourceId,
		activityResource.machineData,
		activityResource.supportedOperations,
		getLocalOperationName,
		getLocalOperation,
		activityResourceSanitized.isAppContainer
	);
	activityResourceSanitized.state = getResourceState(activityResource.state);
	activityResourceSanitized.resourceIcon = '';
	activityResourceSanitized.isFetchIconAvailable =
		!!activityResource.supportedOperations.includes('fetchicon');
	return activityResourceSanitized;
};
