import { ActivityCardItem } from 'App/Activity/ActivityCard/ActivityCard';
import {
	ActivityLoadingState,
	ActivityResource,
	ActivityResourceSanitized,
	deleteResourceUsingId,
	ResourceType,
	sanitizeActivityResource,
} from 'App/Activity/CommonResourceUtils';
import { EventType } from 'Components/EventBus';
import { environment } from 'Environment';
import { isNativeWidget } from 'Environment/launchResource/device';

const updateResources = (
	sessions: Map<ActivityCardItem, ActivityCardItem[]>,
	resources: ActivityCardItem[],
	filterFunction: (
		values: ActivityCardItem[],
		key: ActivityCardItem,
		resource: ActivityCardItem
	) => void
) => {
	sessions.forEach((values, key) => {
		resources.forEach(resource => {
			filterFunction(values, key, resource);
		});
	});
};

export const isAddActivitiesEvent = (eventType: string): boolean => {
	return eventType === EventType.ACTIVITY_ADD_RESOURCES;
};

export const isRemoveActivitiesEvent = (eventType: string): boolean => {
	return eventType === EventType.ACTIVITY_REMOVE_RESOURCES;
};

export const subscribeToEventBus = () => {
	const subscribeEvent = {
		id: 'activity',
		type: 'action/subscribe',
		payload: {
			subscription: 'action/miniui/running/subscribe',
		},
	};
	environment.dispatchEventToNative2(subscribeEvent);
};

export const unsubscribeFromEventBus = () => {
	const unsubscribeEvent = {
		id: 'activities',
		type: 'action/unsubscribe',
		payload: {
			unsubscription: 'action/miniui/running/unsubscribe',
		},
	};
	environment.dispatchEventToNative2(unsubscribeEvent);
};

export const createSessionsMap = (
	activityCardItems: ActivityCardItem[]
): Map<ActivityCardItem, ActivityCardItem[]> => {
	const sessionsMap: Map<ActivityCardItem, ActivityCardItem[]> = new Map<
		ActivityCardItem,
		ActivityCardItem[]
	>();

	//Add all the virtual desktops/sessions first, so that children virtual apps can be added as values.
	activityCardItems.forEach((activityCardItem: ActivityCardItem) => {
		if (activityCardItem.activityResource.type === ResourceType.VIRTUAL_DESKTOP) {
			sessionsMap.set(activityCardItem, []);
		}
	});

	//Add all the virtual apps.
	activityCardItems.forEach((activityCardItem: ActivityCardItem) => {
		if (activityCardItem.activityResource.type === ResourceType.VIRTUAL_APP) {
			//Parent key should already exist and should be a container type, else we will discard the virtual app as invalid.
			sessionsMap.forEach((values, key) => {
				if (
					key.activityResource.sessionId === activityCardItem.activityResource.parentId &&
					key.activityResource.isAppContainer
				) {
					values.push(activityCardItem);
				}
			});
		}
	});
	return sessionsMap;
};

export const addSessions = (
	activityCardItems: ActivityCardItem[],
	sessionsMap: Map<ActivityCardItem, ActivityCardItem[]>
) => {
	const desktopsList: ActivityCardItem[] = [];
	const appsList: ActivityCardItem[] = [];
	activityCardItems.forEach(activityCardItem => {
		if (activityCardItem.activityResource.type === ResourceType.VIRTUAL_DESKTOP) {
			desktopsList.push(activityCardItem);
		} else if (activityCardItem.activityResource.type === ResourceType.VIRTUAL_APP) {
			appsList.push(activityCardItem);
		}
	});
	const clonedMap: Map<ActivityCardItem, ActivityCardItem[]> = new Map(sessionsMap);
	desktopsList.forEach(desktop => {
		clonedMap.set(desktop, []);
	});
	updateResources(clonedMap, appsList, (values, key, resource) => {
		if (
			resource.activityResource.parentId === key.activityResource.sessionId &&
			key.activityResource.isAppContainer
		) {
			values.push(resource);
		}
	});
	return clonedMap;
};

export const removeSessions = (
	sessionIds: string[],
	sessionsMap: Map<ActivityCardItem, ActivityCardItem[]>
) => {
	const clonedMap: Map<ActivityCardItem, ActivityCardItem[]> = new Map(sessionsMap);
	sessionIds.forEach(sessionId => {
		deleteResourceUsingId(sessionId, clonedMap);
	});
	return clonedMap;
};

export const parseResourcesList = (
	activityResources: ActivityResource[]
): ActivityCardItem[] => {
	const activityCards: ActivityCardItem[] = [];
	activityResources?.forEach((activityResource: ActivityResource) => {
		const activityResourceSanitizedItem: ActivityResourceSanitized =
			sanitizeActivityResource(activityResource);
		if (activityResourceSanitizedItem) {
			activityCards.push({
				activityResource: activityResourceSanitizedItem,
				dropDownActionItems: activityResourceSanitizedItem.supportedOperations,
			});
		}
	});
	return activityCards;
};

export const isEventSupportedByActivityManager = (eventType: string): boolean => {
	return isAddActivitiesEvent(eventType) || isRemoveActivitiesEvent(eventType);
};

export const shouldShowTitle = (isSmallFormFactor: boolean): boolean => {
	let showTitle = false;
	if (!(isNativeWidget() || isSmallFormFactor)) {
		showTitle = true;
	}
	return showTitle;
};

export const shouldShowLoader = (
	activityLoadingState: ActivityLoadingState,
	localSessionsSize: number,
	remoteSessionsSize: number,
	hibernatedSessionsSize: number
): boolean => {
	let showLoader = true;
	if (
		(activityLoadingState.isLocalResourceLoaded &&
			activityLoadingState.isRemoteResourceLoaded) ||
		localSessionsSize > 0 ||
		remoteSessionsSize > 0 ||
		hibernatedSessionsSize > 0
	) {
		showLoader = false;
	}
	return showLoader;
};
