import * as React from 'react';
import { hasFeatureCanary } from '@citrite/workspace-ui-platform';
import { useConfigurationContext } from 'Configuration/useConfigurationContext';
import { FeatureFlag } from 'Environment/FeatureFlag';
import { useNotifications } from 'userNotifications';
import { NewMessagesIcon } from './NewMessagesIcon';

export function UserNotificationBadge() {
	const notificationsContext = useNotifications();
	if (!notificationsContext.hasNewFeedCards) {
		return null;
	}
	return <NewMessagesIcon pulse />;
}

export const UserNotificationBadgeOffIfFeedInNav = () => {
	const { workspaceConfiguration } = useConfigurationContext();
	return hasFeatureCanary(workspaceConfiguration, FeatureFlag.FeedInLeftNav) ? null : (
		<UserNotificationBadge />
	);
};
