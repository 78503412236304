import { WorkspaceConfiguration } from '@citrite/workspace-ui-platform';
import { ActivityCardItem } from 'App/Activity/ActivityCard/ActivityCard';
import { createSessionsMap } from 'App/Activity/ActivityManager/ActivityManagerUtils';
import {
	ActivityResourceSanitized,
	ResourceState,
	ResourceType,
} from 'App/Activity/CommonResourceUtils';
import {
	createSupportedOperations,
	DropDownActionItem,
} from 'App/Activity/DropDownUtils';
import {
	getActionEndpoint,
	getRemoteOperation,
	getRemoteOperationName,
	PowerState,
	supportedOperations,
} from 'App/Activity/Network/ActionsUtil';
import {
	Application,
	Session,
	SessionsListResponse,
	UserSessionType,
} from 'App/Activity/Network/SessionList';
import { reportError } from 'App/Activity/Utility';

//allow-unused-export
export const sanitizeSession = (session: Session): ActivityResourceSanitized => {
	if (
		session.userSessionType === UserSessionType.APPLICATION &&
		session.applications?.length === 0
	) {
		reportError('Activity Resource Sanitizaton Failed', 'No Applications');
		return null;
	}
	const isContainer = !(
		session.userSessionType === UserSessionType.DESKTOP ||
		session.applications?.length === 0
	);
	const sanitizedSession = new ActivityResourceSanitized();
	sanitizedSession.description = '';
	sanitizedSession.isAppContainer = isContainer;
	sanitizedSession.sessionId = session.sessionId;
	let resourceId = session.sessionId;
	let resourceName = session.machineData?.machineName;
	if (session.userSessionType === UserSessionType.DESKTOP) {
		const applicationName = session.applications[0]?.name;
		const applicationId = session.applications[0]?.id;
		if (applicationName && applicationName.length > 0) {
			resourceName = applicationName;
		}
		if (applicationId && applicationId.length > 0) {
			resourceId = applicationId;
		}
		sanitizedSession.resourceIcon = session.applications[0]?.iconUrl;
		sanitizedSession.isFetchIconAvailable = false;
	}
	sanitizedSession.name = resourceName;
	sanitizedSession.resourceId = resourceId;
	sanitizedSession.parentId = '';
	sanitizedSession.startTimeStamp = session.sessionStartTime;
	sanitizedSession.type = ResourceType.VIRTUAL_DESKTOP;
	sanitizedSession.deviceId = session.deviceId;
	sanitizedSession.deviceType = session.deviceType;
	sanitizedSession.machineData = { ...session.machineData };
	sanitizedSession.supportedOperations = createSupportedOperations(
		session.sessionId,
		resourceId,
		session.machineData,
		supportedOperations,
		getRemoteOperationName,
		getRemoteOperation,
		!isContainer
	);
	sanitizedSession.state = getResourceState(session.connectionState);
	return sanitizedSession;
};

//allow-unused-export
export const sanitizeApplication = (
	application: Application,
	parentSession: Session
): ActivityResourceSanitized => {
	const sanitizedApplication = new ActivityResourceSanitized();
	sanitizedApplication.description = '';
	sanitizedApplication.sessionId = application.id;
	sanitizedApplication.resourceId = application.id;
	sanitizedApplication.isAppContainer = false;
	sanitizedApplication.name = application.name;
	sanitizedApplication.parentId = parentSession.sessionId;
	sanitizedApplication.startTimeStamp = '';
	sanitizedApplication.type = ResourceType.VIRTUAL_APP;
	sanitizedApplication.supportedOperations = [];
	sanitizedApplication.machineData = { ...parentSession.machineData };
	sanitizedApplication.resourceIcon = application.iconUrl;
	sanitizedApplication.isFetchIconAvailable = false;
	return sanitizedApplication;
};

//allow-unused-export
export const sanitizeSessionsListResponse = (
	response: SessionsListResponse,
	filterResourceList: (resource: Session) => void
): ActivityResourceSanitized[] => {
	const sanitizedResourceList: ActivityResourceSanitized[] = [];
	response.sessions
		.filter(filterResourceList)
		.filter(session =>
			isSupportedConnectionState(getResourceState(session.connectionState))
		)
		.forEach(session => {
			addSessionToList(sanitizedResourceList, session);
			if (session.userSessionType !== UserSessionType.DESKTOP) {
				session?.applications?.forEach(application => {
					addApplicationToList(sanitizedResourceList, application, session);
				});
			}
		});
	return sanitizedResourceList;
};

const addSessionToList = (
	sanitizedResourceList: ActivityResourceSanitized[],
	session: Session
) => {
	const sanitizedSession = sanitizeSession(session);
	if (sanitizedSession) {
		sanitizedResourceList.push(sanitizedSession);
	}
};

const addApplicationToList = (
	sanitizedResourceList: ActivityResourceSanitized[],
	application: Application,
	parentSession: Session
) => {
	const sanitizedApplication = sanitizeApplication(application, parentSession);
	if (sanitizedApplication) {
		sanitizedResourceList.push(sanitizedApplication);
	}
};

const getResourceState = (connectionState: string) => {
	let resourceState = ResourceState.UNKNOWN;
	if (connectionState === 'active') {
		resourceState = ResourceState.ACTIVE;
	} else if (connectionState === 'connected') {
		resourceState = ResourceState.CONNECTED;
	} else if (connectionState === 'disconnected') {
		resourceState = ResourceState.DISCONNECTED;
	}
	return resourceState;
};

//allow-unused-export
export const getResourceList = (
	resources: SessionsListResponse,
	filterResourceList: (resource: Session) => void
): ActivityCardItem[] => {
	const activityCardItems: ActivityCardItem[] = [];
	sanitizeSessionsListResponse(resources, filterResourceList).forEach(
		sanitizedResourceItem => {
			activityCardItems.push({
				activityResource: sanitizedResourceItem,
				dropDownActionItems: sanitizedResourceItem.supportedOperations,
			});
		}
	);
	return activityCardItems;
};

const isSupportedConnectionState = (resourceState: ResourceState): boolean => {
	return (
		resourceState === ResourceState.ACTIVE ||
		resourceState === ResourceState.CONNECTED ||
		resourceState === ResourceState.DISCONNECTED
	);
};

const isDisconnectedSession = (session: Session) => {
	return session.connectionState === ResourceState.DISCONNECTED;
};

const filterSessionsWithDeviceId = (deviceId: string) => {
	return (session: Session) => session.deviceId === deviceId;
};
const filterSessionsWithoutDeviceId = (deviceId: string) => {
	return (session: Session) => session.deviceId !== deviceId;
};

const filterConnectedAndMatchingDeviceIdSessions = (deviceId: string) => {
	return (session: Session) =>
		filterSessionsWithDeviceId(deviceId)(session) && !isDisconnectedSession(session);
};

const filterDisconnectedOrUnMatchingDeviceIdSessions = (deviceId: string) => {
	return (session: Session) =>
		(filterSessionsWithoutDeviceId(deviceId)(session) ||
			isDisconnectedSession(session)) &&
		session.machineData?.powerState !== PowerState.SUSPENDED;
};

const filterHibernatedSessions = () => {
	return (session: Session) => session.machineData?.powerState === PowerState.SUSPENDED;
};

export const filterSections = (
	resources: SessionsListResponse,
	workspaceConfig: WorkspaceConfiguration,
	deviceId: string
) => {
	const localSession = getFilteredSessionListResponseWithEnpoints(
		resources,
		filterConnectedAndMatchingDeviceIdSessions(deviceId),
		workspaceConfig
	);

	const remoteSession = getFilteredSessionListResponseWithEnpoints(
		resources,
		filterDisconnectedOrUnMatchingDeviceIdSessions(deviceId),
		workspaceConfig
	);

	const hibernateSession = getFilteredSessionListResponseWithEnpoints(
		resources,
		filterHibernatedSessions(),
		workspaceConfig
	);

	return { localSession, remoteSession, hibernateSession };
};

export const setResourcesToSession = (
	resources: ActivityCardItem[],
	session: React.Dispatch<React.SetStateAction<Map<ActivityCardItem, ActivityCardItem[]>>>
) => {
	session(createSessionsMap(resources));
};

const addEndpointDetails = (
	activityCardItems: ActivityCardItem[],
	workspaceConfiguration: WorkspaceConfiguration
): ActivityCardItem[] => {
	activityCardItems?.forEach(activityCardItem => {
		activityCardItem.dropDownActionItems?.forEach(dropDownActionItem => {
			dropDownActionItem.endpoint = getActionEndpoint(
				dropDownActionItem.item,
				workspaceConfiguration
			);
		});
	});
	return activityCardItems;
};
const getFilteredSessionListResponseWithEnpoints = (
	remoteSessionListResponse: SessionsListResponse,
	filter: (session: Session) => void,
	workspaceConfig: WorkspaceConfiguration
): ActivityCardItem[] => {
	return addEndpointDetails(
		getResourceList(remoteSessionListResponse, filter),
		workspaceConfig
	);
};

export const getRemoteOptionsUpdatedLocalSessionList = (
	localList: ActivityCardItem[],
	remoteList: ActivityCardItem[]
): ActivityCardItem[] => {
	const remoteDesktopList = remoteList.filter(
		session => session.activityResource.type === ResourceType.VIRTUAL_DESKTOP
	);

	//No endpoint modifications are being done for virtual App as per the design.
	const appList = localList.filter(
		session => session.activityResource.type === ResourceType.VIRTUAL_APP
	);

	const desktopsList: ActivityCardItem[] = [];
	localList.forEach((localCardItem: ActivityCardItem) => {
		if (localCardItem.activityResource.type === ResourceType.VIRTUAL_DESKTOP) {
			let localOptionsList = localCardItem.dropDownActionItems;
			remoteDesktopList.forEach((remoteCardItem: ActivityCardItem) => {
				if (
					remoteCardItem.activityResource.resourceId ===
					localCardItem.activityResource.resourceId
				) {
					localOptionsList = localOptionsList.concat(
						filterUniqueItemsFromList1(
							remoteCardItem.dropDownActionItems,
							localCardItem.dropDownActionItems
						)
					);
				}
			});
			desktopsList.push({
				activityResource: localCardItem.activityResource,
				dropDownActionItems: localOptionsList,
			});
		}
	});
	return appList.concat(desktopsList);
};

const filterUniqueItemsFromList1 = (
	list1: DropDownActionItem[],
	list2: DropDownActionItem[]
) => list1.filter(item1 => !list2.some(item2 => item1.item === item2.item));
