import * as React from 'react';
import {
	DividerPane,
	EmptyGetStartedIcon,
	layout,
	palette,
} from '@citrite/web-ui-component';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Title } from 'App/Header/Title';
import { useFormFactor } from 'App/Header/useFormFactor';
import { Container } from 'App/Screen/Container';
import { useClientHeight } from 'Components/AppLayout/useClientHeight';
import { FeatureFlag } from 'Environment/FeatureFlag';
import {
	isChromeAppOrChromeOS,
	isLinux,
	isMobileOrTabletDevice,
} from 'Environment/launchResource/device/currentPlatform';
import { useFeatureCanary } from 'utils/useFeatureCanary';
import { MandateFailure } from 'Workspace/MandateNative/MandateFailure';
import { MandateSuccess } from 'Workspace/MandateNative/MandateSuccess';
import { openCitrixApp } from 'Workspace/SchemeCallHandler/schemeCallHandler';
import { CopyStoreLink } from './CopyStoreLink';

const COUNTDOWN_INTERVAL_MS = 1000;
const DEFAULT_BG_COLOR = palette.teal100;
const TitleContainer = styled.div`
	position: absolute;
	top: 24px;
	left: 40px;
`;
const BrandingContainer = styled.div<{ backgroundColor: string }>`
	width: 100%;
	height: 100%;
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: ${props => props.backgroundColor};
`;

const StyledContainer = styled.div<{ clientHeight: string }>`
	margin: 0 ${layout.mediumLargeSpace};
	display: flex;
	flex-direction: column;
	justify-content: center;
	${props =>
		props.clientHeight &&
		css`
			min-height: calc(
				${props.clientHeight} - ${layout.mediumLargeSpace} - ${layout.mediumLargeSpace} -
					80px
			);
			/* client height - padding of container(24px) x 2 - height of Powered by Citrix (60px) */
		`}
`;

export interface PlatformData {
	platform: string;
	platformDisplayName: string;
	isMobileOrTablet: boolean;
}
export interface DownloadDetails {
	canDownload: boolean;
	downloadLink?: string;
}

export interface MandateNativeScreenProps {
	downloadDetails: DownloadDetails;
	backgroundColor?: string;
	receiverConfigurationDownloadUrl?: string;
}

export function MandateNativeScreen(props: MandateNativeScreenProps) {
	const { isSmallFormFactor } = useFormFactor();
	const isMobileOrTablet = isMobileOrTabletDevice();
	const clientHeight = useClientHeight();

	const [counter, setCounter] = React.useState(5);
	const [showFailure, setShowFailure] = React.useState(false);

	const isOpenCitrixEnabledForLinux = useFeatureCanary(
		FeatureFlag.EnableOpenCitrixForLinux
	);
	const isOpenCitrixEnabledForChromeOS = useFeatureCanary(
		FeatureFlag.EnableOpenCitrixForChromeOS
	);

	const shouldShowOpenCitrixButton = () => {
		if (!isLinux() && !isChromeAppOrChromeOS()) {
			return true;
		}

		return (
			(isLinux() && isOpenCitrixEnabledForLinux) ||
			(isChromeAppOrChromeOS() && isOpenCitrixEnabledForChromeOS)
		);
	};

	const shouldAutoLaunchProtocolHandler = () =>
		!isMobileOrTablet && shouldShowOpenCitrixButton();

	React.useEffect(() => {
		let intervalId: NodeJS.Timeout;

		const handleCountdownOver = () => {
			clearInterval(intervalId);
			setShowFailure(true);
		};

		if (shouldAutoLaunchProtocolHandler()) {
			openCitrixApp(props.receiverConfigurationDownloadUrl);
			intervalId = setInterval(() => {
				setCounter(prevCounter => {
					if (prevCounter === 1) {
						handleCountdownOver();
						return 0;
					}
					return prevCounter - 1;
				});
			}, COUNTDOWN_INTERVAL_MS);
		} else {
			handleCountdownOver();
			setCounter(0);
		}
		return () => clearInterval(intervalId);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<DividerPane
			smallFormFactor={isSmallFormFactor}
			leftPaneContent={
				<BrandingContainer
					backgroundColor={
						props.backgroundColor && props.backgroundColor !== DEFAULT_BG_COLOR
							? palette.grey100
							: DEFAULT_BG_COLOR
					}
				>
					<TitleContainer>
						<Title />
					</TitleContainer>
					<EmptyGetStartedIcon />
				</BrandingContainer>
			}
			rightPaneContent={
				<Container>
					<StyledContainer clientHeight={clientHeight}>
						<MandateSuccess
							{...props}
							counter={counter}
							showOpenCitrixButton={shouldShowOpenCitrixButton()}
							receiverConfigurationDownloadUrl={props.receiverConfigurationDownloadUrl}
						/>
						{showFailure && (
							<>
								<CopyStoreLink />
								<MandateFailure {...props} />
							</>
						)}
					</StyledContainer>
				</Container>
			}
		/>
	);
}
