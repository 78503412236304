import { hasFeatureCanary, WorkspaceConfiguration } from '@citrite/workspace-ui-platform';
import { FeatureFlag } from 'Environment/FeatureFlag';
import { clientType, getClientType } from 'Environment/getClientType';
import { shouldSupportAppProtectionOnPrem } from 'Workspace/AppContext/onpremAppProtectionUtils';

export function clientHasAppProtectionCapability(
	workspaceConfiguration: WorkspaceConfiguration
): boolean {
	//Our feature flag mechanism takes care of the onprem specific flag as well.
	if (!hasFeatureCanary(workspaceConfiguration, FeatureFlag.BrowserAppProtection)) {
		return false;
	}

	if (IS_ON_PREM) {
		return shouldSupportAppProtectionOnPrem(workspaceConfiguration);
	}

	return shouldSupportAppProtectionOnCloud();
}

function shouldSupportAppProtectionOnCloud() {
	return getClientType() === clientType.browserextension;
}
