import * as React from 'react';
import { t } from '@citrite/translate';
import { layout, notifyInfo } from '@citrite/web-ui-component';
import styled from '@emotion/styled';
import { useFormFactor } from 'App/Header/useFormFactor';
import { getAppleBadge } from 'App/Settings/Tools/Tool/getAppleBadge';
import { platformSpecificClientDisplayNames } from 'Components/Detection/nativeClientName';
import { getGuideLink, getMobileAppLogo } from 'Components/MandateNativeAppSupport/utils';
import { platform } from 'Environment/launchResource/constants';
import {
	getCurrentPlatform,
	isMobileOrTabletDevice,
} from 'Environment/launchResource/device/currentPlatform';
import { DownloadDetails } from './MandateNativeScreen';

const StyledSpan = styled.span<{ smallFormFactor: boolean }>`
	margin-bottom: ${layout.mediumSpace};
	align-items: center;
	display: block;
	${props =>
		props.smallFormFactor &&
		`
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
		justify-content: center;
		text-align: center;
		margin: ${layout.smallSpace} 0;
	`}
`;

const StyledAnchor = styled.a<{ smallFormFactor: boolean }>`
	margin-left: ${layout.tinySpace};
	color: #05758a;
	${props =>
		props.smallFormFactor &&
		`
		margin: ${layout.mediumSpace} 0;
	`}
`;

const StyledContainer = styled.div<{ smallFormFactor: boolean }>`
	padding-top: ${layout.smallSpace};
	${props =>
		props.smallFormFactor &&
		`
		padding-top: 0;
	`}
`;

const getDownloadMessage = (currentPlatform: platform) => {
	switch (currentPlatform) {
		case platform.windows:
		case platform.mac:
			return t('Workspace:mandate_native_app_screen.download_citrix_for_platform', {
				platform: platformSpecificClientDisplayNames.find(
					device => device.platform === currentPlatform
				)?.name,
			});
	}

	return t('Workspace:mandate_native_app_screen.download_citrix');
};

const notifyToClickOpenCitrix = () => {
	notifyInfo(t('Workspace:mandate_native_app_screen.on_download_notification'));
};

export interface MandateFailureProps {
	downloadDetails: DownloadDetails;
}

export function MandateFailure(props: MandateFailureProps) {
	const { isSmallFormFactor } = useFormFactor();
	const { downloadDetails } = props;
	const downloadLinkDescription = isMobileOrTabletDevice()
		? getMobileAppLogo()
		: getDownloadMessage(getCurrentPlatform());

	return (
		<StyledContainer smallFormFactor={isSmallFormFactor}>
			{downloadDetails.canDownload && (
				<>
					<StyledSpan smallFormFactor={isSmallFormFactor}>
						{t('Workspace:mandate_native_app_screen.dont_have_app')}
						<StyledAnchor
							href={downloadDetails.downloadLink}
							smallFormFactor={isSmallFormFactor}
							onClick={notifyToClickOpenCitrix}
							target="_blank"
							data-testid="download-citrix-href"
						>
							{downloadLinkDescription}
						</StyledAnchor>
					</StyledSpan>
					{getCurrentPlatform() === platform.mac && (
						<StyledSpan smallFormFactor={isSmallFormFactor}>
							{t('Workspace:mandate_native_app_screen.using_ipad')}
							<StyledAnchor
								href={downloadDetails.downloadLink}
								smallFormFactor={isSmallFormFactor}
								onClick={notifyToClickOpenCitrix}
								target="_blank"
								data-testid="download-citrix-ipad-href"
							>
								{getAppleBadge()}
							</StyledAnchor>
						</StyledSpan>
					)}
				</>
			)}
			<StyledSpan smallFormFactor={isSmallFormFactor}>
				{t('Workspace:mandate_native_app_screen.issues_installing')}
				<StyledAnchor
					href={getGuideLink()}
					target="_blank"
					smallFormFactor={isSmallFormFactor}
				>
					{t('Workspace:mandate_native_app_screen.read_user_guide')}
				</StyledAnchor>
			</StyledSpan>
			<StyledSpan smallFormFactor={isSmallFormFactor}>
				{t('Workspace:mandate_native_app_screen.contact_admin')}
			</StyledSpan>
		</StyledContainer>
	);
}
